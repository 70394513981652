import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const OrderManagementCards = () => {
  const [dispatchedCount, setDispatchedCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [returnsCount, setReturnsCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [confirmedCount, setConfirmedCount] = useState(0);

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  useEffect(() => {
    const fetchOrderStatusCounts = async () => {
      try {
        const response = await fetch(`${baseURL}/OrdersCount`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const result = await response.json();

          if (result.responseCode === 200) {
            const {
              totalOrders,
              confirmed,
              dispatched,
              delivered,
              return: returnCount,
            } = result.data;
            setTotalCount(totalOrders);
            setConfirmedCount(confirmed);
            setDispatchedCount(dispatched);
            setDeliveredCount(delivered);
            setReturnsCount(returnCount);
          } else {
            console.error(result.message);
          }
        } else if (response.status === 400) {
          const result = await response.json();
          console.error(result.message);
        } else {
          console.error(response.status);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchOrderStatusCounts();
  }, []);


  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex flex-wrap gap-5 w-full">
        <div
          style={{
            boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.25)",
          }}
          className="bg-[#D1FFD1] rounded-md  w-[22%] h-24 min-w-[140px] text-center"
        >
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-left font-semibold text-xl tracking-widest text-green-600 ">
              Total Orders
            </span>
            {/* <BsCardChecklist className="text-lg text-[#009A00]" /> */}
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left ">
            {totalCount}
          </div>
        </div>
        <div
          style={{
            boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.25)",
          }}
          className="bg-[#CDE1FF] rounded-md  w-[22%] h-24 min-w-[140px] text-center"
        >
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-left font-semibold text-xl tracking-widest text-blue-600">
              Dispatched
            </span>
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left ">
            {dispatchedCount}
          </div>
        </div>

        <div
          style={{
            boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.25)",
          }}
          className="bg-[#FFE7EA] rounded-md  w-[22%] h-24 min-w-[140px] text-center"
        >
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-left font-semibold text-xl tracking-widest text-red-600">
              Delivered
            </span>
            {/* <MdDeliveryDining className="text-lg text-[#009A00]" /> */}
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left">
            {deliveredCount}
          </div>
        </div>

        <div
          style={{
            boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.25)",
          }}
          className="bg-yellow-100 rounded-md w-[22%] h-24 min-w-[140px] text-center"
        >
          <div className="flex justify-between items-center p-2 mb-2">
            <span className="text-left font-semibold text-xl tracking-widest text-yellow-600">
              Returns
            </span>
          </div>
          <div className="w-4/5 mx-auto text-2xl font-bold text-left">
            {returnsCount}
          </div>
        </div>
      </div>

      <div className="self-start">
        <RangePicker
          className="w-full"
          format="YYYY-MM-DD"
        />
      </div>
    </div>
  );
};

export default OrderManagementCards;
