import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, message } from "antd";
import { AiOutlineStock } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import {
  MdCategory,
  MdOutlineInventory2,
  MdPayment,
  MdHelpOutline,
  MdOutlineDashboardCustomize,
  MdOutlineSell,
} from "react-icons/md";
import { RiCheckboxMultipleBlankFill } from "react-icons/ri";
import { SlControlEnd } from "react-icons/sl";
import { CiStar } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { RiCustomerService2Fill, RiEBikeLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { TbBoxMultiple } from "react-icons/tb";
import { BsBox2, BsPeople, BsWindowSidebar } from "react-icons/bs";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { updatingSelectedPujas } from "../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import AxiosComponent from "../../util/axiosInstance";
import useLogout from "../../util/authUtils";
const { Sider } = Layout;

const SidebarSeller = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [sideBarData, setSideBarData] = useState({});
  const path = location.pathname;
  const selectedKeys = path.substr(1);
  const logout = useLogout();

  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const profileDetails = useSelector((state) => state.userProfile);
  console.log(profileDetails);
  const handleMenuItemSelect = (menuItemKey) => {
    console.log(menuItemKey);
    dispatch(updatingSelectedPujas(menuItemKey));
  };

  const selectedPuja = useSelector((state) => state.selectedPujas);
  console.log(selectedPuja);

  const fetchSidabarApi = async () => {
    const Endpoint = "sellerSideBarAPi";
    try {
      setLoading(true);
      const userData = {
        roleId: profileDetails?.role_Id,
      };
      const response = await axiosInstance.post(Endpoint, userData);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        console.log("Side bar api fetched successful:", data);
        if (data.responseCode === 200) {
          console.log(data.message);
          setSideBarData(data?.data);
        } else if (data.responseCode === 400) {
          console.log(data);
          console.log(data.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        } else {
          const errorData = await response.json();
          console.error("Error posting data:", errorData);
          throw { error: errorData, loading: false };
        }
      }
    } catch (error) {
      console.log("Side bar api fetched failed:", error);
      message.error("Something went wrong please try later");
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(sideBarData);

  useEffect(() => {
    fetchSidabarApi();
  }, []);

  const iconMap = {
    RxDashboard: RxDashboard,
    MdCategory: MdCategory,
    MdOutlineInventory2: MdOutlineInventory2,
    TbBoxMultiple: TbBoxMultiple,
    SlControlEnd: SlControlEnd,
    BsWindowSidebar: BsWindowSidebar,
    BsBox2: BsBox2,
    MdOutlineDashboardCustomize: MdOutlineDashboardCustomize,
    CiStar: CiStar,
    MdPayment: MdPayment,
    SlControlEnd: SlControlEnd,
    BsPeople: BsPeople,
    MdOutlineSell: MdOutlineSell,
    RiEBikeLine: RiEBikeLine,
    RiCustomerService2Fill: RiCustomerService2Fill,
    IoSettingsOutline: IoSettingsOutline,
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={`h-screen fixed top-0 left-0 shadow-lg !bg-white transition-all duration-300 ${
        collapsed ? "!w-20" : "!w-72"
      }`}>
      {/* Logo and Toggle */}
      <div className='flex items-center justify-between p-4 bg-white'>
        {!collapsed && (
          <div
            className={`text-[#009A00] transition-all duration-300 fill-current`}>
            <img
              src='/Assests/Images/Mr_Sasta-Logo.png'
              alt='Logo'
              className='transition-all duration-300'
            />
          </div>
        )}
        <div
          className={`${
            collapsed ? "flex justify-center items-center w-full" : ""
          } !text-[#009A00] cursor-pointer text-xl`}
          onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      </div>

      {/* Menu Items */}
      {/* <Menu
        theme='light'
        mode='inline'
        selectedKeys={[selectedKeys]}
        className='text-base !bg-white !text-black'>
        <Menu.Item
          key='dashboard'
          icon={<RxDashboard />}
          className='!bg-white !text-black'
          onClick={() => handleMenuItemSelect("Dashboard")}>
          <Link to='/dashboard'>Dashboard</Link>
        </Menu.Item>
        <Menu.Item
          key='inventory'
          icon={<MdOutlineInventory2 />}
          onClick={() => handleMenuItemSelect("Inventory")}>
          <Link to='/inventory'>Inventory</Link>
        </Menu.Item>
        <Menu.Item
          key='categories'
          icon={<TbBoxMultiple />}
          onClick={() => handleMenuItemSelect("Categories")}>
          <Link to='/categories'>Categories</Link>
        </Menu.Item>
        <Menu.Item
          key='products'
          icon={<BsWindowSidebar />}
          onClick={() => handleMenuItemSelect("Products")}>
          <Link to='/products'>Products</Link>
        </Menu.Item>
        <Menu.Item
          key='order-management'
          icon={<BsBox2 />}
          onClick={() => handleMenuItemSelect("Orders")}>
          <Link to='/order-management'>Orders</Link>
        </Menu.Item>
        <Menu.Item
          key='customers'
          icon={<MdOutlineDashboardCustomize />}
          onClick={() => handleMenuItemSelect("Customers")}>
          <Link to='/customers'>Customers</Link>
        </Menu.Item>
        <Menu.Item
          key='reviews'
          icon={<CiStar />}
          onClick={() => handleMenuItemSelect("Reviews")}>
          <Link to='/reviews'>Reviews</Link>
        </Menu.Item>
        <Menu.Item
          key='payment'
          icon={<MdPayment />}
          onClick={() => handleMenuItemSelect("Payment")}>
          <Link to='/payment'>Payment</Link>
        </Menu.Item>
        <Menu.Item
          key='roles'
          icon={<SlControlEnd />}
          onClick={() => handleMenuItemSelect("Roles")}>
          <Link to='/roles'>Roles</Link>
        </Menu.Item>
        <Menu.Item
          key='employees'
          icon={<BsPeople />}
          onClick={() => handleMenuItemSelect("Employes")}>
          <Link to='/employees'>Employes</Link>
        </Menu.Item>
        <Menu.Item
          key='sellers'
          icon={<MdOutlineSell />}
          onClick={() => handleMenuItemSelect("Sellers")}>
          <Link to='/sellers'>Sellers</Link>
        </Menu.Item>
        <Menu.Item
          key='riders'
          icon={<RiEBikeLine />}
          onClick={() => handleMenuItemSelect("Riders")}>
          <Link to='/riders'>Riders</Link>
        </Menu.Item>
        <Menu.Item
          key='help-center'
          icon={<RiCustomerService2Fill />}
          onClick={() => handleMenuItemSelect("Help Center")}>
          <Link to='/help-center'>Help Center</Link>
        </Menu.Item>

        <Menu.Item
          key='settings'
          icon={<IoSettingsOutline />}
          onClick={() => handleMenuItemSelect("Settings")}>
          <Link to='/settings'>Settings</Link>
        </Menu.Item>
      </Menu> */}

      {sideBarData?.roleName === "Admin" ? (
        <Menu
          theme='light'
          mode='inline'
          selectedKeys={[selectedKeys]}
          className='text-base !bg-white !text-black'>
          <Menu.Item
            key='dashboard'
            icon={<RxDashboard />}
            className='!bg-white !text-black'
            onClick={() => handleMenuItemSelect("Dashboard")}>
            <Link to='/Seller-Dashboard'>Dashboard</Link>
          </Menu.Item>
          {/* <Menu.Item
            key='inventory'
            icon={<MdOutlineInventory2 />}
            onClick={() => handleMenuItemSelect("Inventory")}>
            <Link to='/inventory'>Inventory</Link>
          </Menu.Item> */}
          <Menu.Item
            key='Seller-Categories'
            icon={<TbBoxMultiple />}
            onClick={() => handleMenuItemSelect("Categories")}>
            <Link to='/Seller-Categories'>Categories</Link>
          </Menu.Item>
          <Menu.Item
            key='sub-categories'
            icon={<RiCheckboxMultipleBlankFill />}
            onClick={() => handleMenuItemSelect("SubCategories")}>
            <Link to='/Seller-sub-category'>SubCategories</Link>
          </Menu.Item>
          <Menu.Item
            key='products'
            icon={<BsWindowSidebar />}
            onClick={() => handleMenuItemSelect("Products")}>
            <Link to='/products'>Products</Link>
          </Menu.Item>
          <Menu.Item
            key='order-management'
            icon={<BsBox2 />}
            onClick={() => handleMenuItemSelect("Orders")}>
            <Link to='/Seller-Orders'>Orders</Link>
          </Menu.Item>
          <Menu.Item
            key='Seller-Stock-management'
            icon={<AiOutlineStock />}
            onClick={() => handleMenuItemSelect("Seller-Stock-management")}>
            <Link to='/SellerStock-management'>Stock Management</Link>
          </Menu.Item>
          {/* <Menu.Item
            key='customers'
            icon={<MdOutlineDashboardCustomize />}
            onClick={() => handleMenuItemSelect("Customers")}>
            <Link to='/Seller-Customers'>Customers</Link>
          </Menu.Item> */}
          <Menu.Item
            key='reviews'
            icon={<CiStar />}
            onClick={() => handleMenuItemSelect("Reviews")}>
            <Link to='/Seller-Reviews'>Reviews</Link>
          </Menu.Item>
          {/* <Menu.Item
            key='payment'
            icon={<MdPayment />}
            onClick={() => handleMenuItemSelect("Payment")}>
            <Link to='/Seller-Payment'>Payment</Link>
          </Menu.Item> */}
          <Menu.Item
            key='roles'
            icon={<SlControlEnd />}
            onClick={() => handleMenuItemSelect("Roles")}>
            <Link to='/Seller-Roles'>Roles</Link>
          </Menu.Item>
          <Menu.Item
            key='employees'
            icon={<BsPeople />}
            onClick={() => handleMenuItemSelect("Employes")}>
            <Link to='/Seller-Employes'>Employes</Link>
          </Menu.Item>
          {/* <Menu.Item
            key='sellers'
            icon={<MdOutlineSell />}
            onClick={() => handleMenuItemSelect("Sellers")}>
            <Link to='/sellers'>Sellers</Link>
          </Menu.Item> */}
          <Menu.Item
            key='riders'
            icon={<RiEBikeLine />}
            onClick={() => handleMenuItemSelect("Riders")}>
            <Link to='/Seller-Rider'>Riders</Link>
          </Menu.Item>
          {/* <Menu.Item
            key='help-center'
            icon={<RiCustomerService2Fill />}
            onClick={() => handleMenuItemSelect("Help Center")}>
            <Link to='/help-center'>Help Center</Link>
          </Menu.Item> */}

          <Menu.Item
            key='settings'
            icon={<IoSettingsOutline />}
            onClick={() => handleMenuItemSelect("Settings")}>
            <Link to='/settings'>Settings</Link>
          </Menu.Item>
        </Menu>
      ) : (
        <Menu
          theme='light'
          mode='inline'
          selectedKeys={[selectedKeys]}
          className='text-base !bg-white !text-black'>
          {sideBarData?.features?.map((feature) => (
            <Menu.Item
              key={feature.endPoint}
              icon={
                iconMap[feature.icon]
                  ? React.createElement(iconMap[feature.icon])
                  : null
              }
              className='!bg-white !text-black'
              onClick={() => handleMenuItemSelect(feature.featureName)}>
              <Link to={`/${feature.endPoint}`}>{feature.featureName}</Link>
            </Menu.Item>
          ))}
        </Menu>
      )}
    </Sider>
  );
};

export default SidebarSeller;
