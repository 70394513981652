// RidersCard.jsx
import React, { useState } from "react";
import { Input, Button, Modal } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import AddRider from "./AddRider";
import * as XLSX from "xlsx";

const RidersCard = ({
  riders,
  filter,
  setFilter,
  fetchRiders,
  activeCount,
  inactiveCount,
  totalCount,
  searchInput,
  setSearchInput,
}) => {
  const [showAddRiderModal, setShowAddRiderModal] = useState(false);

  const handleFilterClick = (filterType) => {
    setFilter(filterType);
  };

  const handleRiderUpdate = () => {
    fetchRiders();
    setShowAddRiderModal(false);
  };

  const close = () => {
    setShowAddRiderModal(false);
  };

  const onExportRidersCSV = () => {
    const formattedData = riders.map((rider, index) => ({
      "S.No": index + 1,
      Name: rider.riderName || "--",
      Email: rider.email || "--",
      Phone: rider.phone || "--",
      "Driving License": rider.drivingLicense || "--",
      "Aadhaar Number": rider.aadhaarNumber || "--",
      Address: rider.address || "--",
      Status: rider.status || "--",
      "Created At": new Date(rider.createdAt).toLocaleDateString() || "--",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Riders");

    // Set column widths for better readability
    worksheet["!cols"] = [
      { wch: 5 }, // S.No
      { wch: 20 }, // Name
      { wch: 25 }, // Email
      { wch: 15 }, // Phone
      { wch: 20 }, // Driving License
      { wch: 20 }, // Aadhaar Number
      { wch: 30 }, // Address
      { wch: 10 }, // Status
      { wch: 15 }, // Created At
    ];

    XLSX.writeFile(workbook, "RidersData.csv");
  };

  return (
    <div className="bg-white p-4 flex justify-between items-center border-b">
      <div className="flex items-center space-x-4">
        <div className="relative">
          <Input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            suffix={<SearchOutlined className="text-gray-400" />}
            placeholder="Search..."
            className="pl-4 pr-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#009A00]"
          />
        </div>
        <div className="flex space-x-2">
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "All"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("All")}
          >
            ALL ({totalCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Active"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("Active")}
          >
            ACTIVE ({activeCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Inactive"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-[#6B7280] border-[#E5E7EB]"
            }`}
            onClick={() => handleFilterClick("Inactive")}
          >
            INACTIVE ({inactiveCount})
          </button>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="flex space-x-4 items-center">
          <button
            className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100"
            onClick={onExportRidersCSV}
          >
            <MdOutlinePictureAsPdf size={15} className="mr-1" />
            Export to csv
          </button>
        </div>
        {/* <div className="flex justify-end">
          <Button
            className="bg-[#009A00] text-white flex items-center !px-6 !py-5 rounded hover:bg-[#008000] transition-all"
            onClick={() => setShowAddRiderModal(true)}
          >
            <PlusOutlined className="mr-2" />
            Add Rider
          </Button>
        </div> */}
      </div>
      <Modal
        visible={showAddRiderModal}
        onCancel={() => setShowAddRiderModal(false)}
        centered
        footer={null}
        className="custom-modal h-[80%] overflow-auto rounded"
      >
        <AddRider
          close={close}
          fetchRiders={fetchRiders}
          fetchData={handleRiderUpdate}
        />
      </Modal>
    </div>
  );
};

export default RidersCard;
