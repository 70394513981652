import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import RolesCard from "./RolesCard";
import RolesList from "./RolesList";

const SellerRoleMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // State to store list of features
  const [filter, setFilter] = useState("All");
  const [activeCount, setActiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance } = AxiosComponent();

  // Fetch Roles Data
  const fetchRoles = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axiosInstance.get(`${baseURL}/listOfSellerRoles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = response.data;

      if (response.status === 200) {
        setRolesData(result.data);
        const activeRoles = result.data.filter(
          (role) => role.status === "active"
        );
        const inactiveRoles = result.data.filter(
          (role) => role.status === "inactive"
        );

        setTotalCount(result.data.length);
        setActiveCount(activeRoles.length);
        setInactiveCount(inactiveRoles.length);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Features Data
  const fetchFeatures = async () => {
    try {
      const response = await axiosInstance.get(`${baseURL}/ListOfSellerFeatures`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setFeaturesData(response.data.data);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchFeatures(); 
  }, []);

  const getFilteredRoles = () => {
    let filtered = rolesData;

    // Apply status filter
    if (filter === "Active") {
      filtered = filtered.filter((role) => role.status === "active");
    } else if (filter === "Inactive") {
      filtered = filtered.filter((role) => role.status === "inactive");
    }

    // Apply search filter
    if (searchInput.trim() !== "") {
      const lowercasedSearch = searchInput.toLowerCase();
      filtered = filtered.filter((role) =>
        role.roleName.toLowerCase().includes(lowercasedSearch)
      );
    }

    return filtered;
  };
  const updateRolesData = (updatedRole) => {
    const updatedData = rolesData.map((role) =>
      role._id === updatedRole._id
        ? { ...role, status: updatedRole.status }
        : role
    );
    setRolesData(updatedData);
    setActiveCount(
      updatedData.filter((role) => role.status === "active").length
    );
    setInactiveCount(
      updatedData.filter((role) => role.status === "inactive").length
    );
    setTotalCount(updatedData.length);
  };

  return (
    <Spin spinning={loading}>
      <div>
        <RolesCard
          totalCount={totalCount}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          filter={filter}
          setFilter={setFilter}
          featuresData={featuresData}
          rolesData={getFilteredRoles()}
          fetchRoles={fetchRoles}
          searchInput={searchInput} // Pass searchInput
          setSearchInput={setSearchInput}
        />
        <RolesList
          rolesData={getFilteredRoles()}
          updateRolesData={updateRolesData}
          featuresData={featuresData}
          fetchRoles={fetchRoles}
        />
      </div>
    </Spin>
  );
};

export default SellerRoleMain;
