import React, { useEffect, useState } from "react";
import { Form, Input, Spin, message } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import { handleProfileImage } from "../../../containers/fileupload/fileupload";

const EditRider = ({ Rider, close, fetchData, riders }) => {
  const [form] = Form.useForm();
  const [riderImage, setRiderImage] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance } = AxiosComponent();

  useEffect(() => {
    if (Rider) {
      form.setFieldsValue(Rider);
      setRiderImage(Rider.profilePic);
    }
  }, [Rider, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(
        "/editRider",
        {
          riderId: Rider._id,
          ...values,
          profilePic: riderImage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        message.success(response.data.message);
        fetchData();
        close();
      } else if (response.status === 400) {
        message.error(response.data.message );
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.message);
      } else {
        message.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    // Check file type
    if (!["image/png", "image/jpeg"].includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }
  
    // Change the size limit to 500KB (500 * 1024 bytes)
    if (file.size > 500 * 1024) {
      message.error("Image size should be less than 500KB.");
      return;
    }
  
    setLoading(true);
    try {
      const imageUrl = await handleProfileImage(event);
      setRiderImage(imageUrl);
      message.success("Image uploaded successfully.");
    } catch (error) {
      message.error("Failed to upload image.");
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Spin spinning={loading}>
      <div className="EditRider-container">
        <div className="EditRider-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="edit-profile-form"
          >
            <Form.Item
              name="riderName"
              label="Rider Name"
              rules={[
                { required: true, message: "Please enter the rider's name" },
              ]}
              className="mt-0 mb-4"
            >
              <Input type="text" placeholder="Enter Rider Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please enter the email" }]}
              className="mt-0 mb-4"
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                { required: true, message: "Please enter the phone number" },
              ]}
              className="mt-0 mb-4"
            >
              <Input
                type="text"
                placeholder="Enter Phone number"
                maxLength={10}
              />
            </Form.Item>
            <Form.Item
              name="drivingLicense"
              label="Driving License"
              rules={[
                {
                  required: true,
                  message: "Please enter the driving license number",
                },
              ]}
              className="mt-0 mb-4"
            >
              <Input type="text" placeholder="Enter Driving License number" />
            </Form.Item>
            <Form.Item
              name="aadhaarNumber"
              label="Aadhaar Number"
              rules={[
                { required: true, message: "Please enter Aadhaar number" },
              ]}
              className="mt-0 mb-4"
            >
              <Input
                type="text"
                placeholder="Enter Aadhaar Number"
                maxLength={12}
              />
            </Form.Item>
            <Form.Item name="address" label="Address" className="mt-0 mb-4">
              <Input type="text" placeholder="Enter Address" />
            </Form.Item>

            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              {riderImage ? (
                <div className="FileChange">
                  <div className="image-section">
                    <img
                      src={riderImage}
                      alt="Rider"
                      className="image-preview"
                      style={{
                        width: "20%",
                        height: "auto",
                        maxWidth: "150px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <label htmlFor="fileInput" className="primary-text pointer">
                    Change Image
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div className="FileUpload">
                  <div className="flex justify-center">
                    <img
                      src="/Assests/Images/image-gallery.png"
                      alt="Rider"
                      className="file-upload"
                      style={{
                        width: "20%",
                        height: "auto",
                        maxWidth: "150px",
                      }}
                    />
                  </div>
                  <p>
                    Add Profile image or{" "}
                    <label
                      htmlFor="fileInput"
                      className="text-[#009A00] font-medium cursor-pointer"
                    >
                      browse
                    </label>
                  </p>
                  <small className="secondary-text">Maximum size: 5MB</small>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </div>

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="edit-Rider-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2 cursor-pointer"
                  onClick={close}
                >
                  <p>Cancel</p>
                </div>
                <button
                  type="submit"
                  className="edit-Rider-submit-button !bg-[#009A00] !hover:bg-[#008000] text-white py-2 rounded-md font-medium p-4"
                >
                  <p>Update Rider</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default EditRider;
