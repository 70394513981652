import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Modal, Button, message, Select } from "antd";
import AxiosComponent from "../../util/axiosInstance";
import useLogout from "../../util/authUtils";

const AddSeller = ({ close, fetchSellerData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [roles, setRoles] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const { axiosInstance } = AxiosComponent();
  const logout = useLogout();

  useEffect(() => {
    form.resetFields();
  }, [close, form]);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  // const fetchRoles = async () => {
  //   try {
  //     const response = await axiosInstance.get(`${baseURL}/listOfSellerRoles`);
  //     if (response.data.responseCode === 200) {
  //       setRoles(response.data.data);
  //     } else if (response.data.responseCode === 400) {
  //       setError(response.data.message );
  //     } else {
  //       setError(response.data.message );
  //     }
  //   } catch (err) {
  //     setError(err.response?.data?.message);
  //   }
  // };

  const onFinish = async (values) => {
    setLoading(true);
    setError("");

    try {
      const response = await axiosInstance.post(`${baseURL}/addSeller`, values);

      if (response.data.responseCode === 200) {
        message.success(response.data.message);
        setSuccessModal(true);
        close();
        fetchSellerData();
        form.resetFields();
      } else if (response.data.responseCode === 400) {
        setError(response.data.message);
        message.error(response.data.message);
      } else if (response.data.responseCode === 401) {
        message.error(response.data?.message);
        logout();
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    close();
  };

  return (
    <Spin spinning={loading}>
      <div className="AddSeller-container">
        <Form
          form={form}
          name="add_seller"
          layout="vertical"
          onFinish={onFinish}
          className="edit-profile-form"
        >
          <Form.Item
            name="name"
            label="Seller Name"
            rules={[
              {
                required: true,
                message: "Please enter seller name",
              },
              {
                max: 30,
                message: "seller name cannot exceed 30 characters.",
              },
              {
                pattern: /^[A-Za-z0-9 ]+$/,
                message:
                  "Seller name can only contain letters, numbers, and spaces",
              },
              {
                validator: (_, value) => {
                  if (value && value.trim().startsWith(" ")) {
                    return Promise.reject(
                      new Error("Seller name cannot start with a space")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter Seller Name"
              maxLength={30}
              onInput={(e) => {
                let value = e.target.value;
                value = value.replace(/^\s+/, "");
                value = value.slice(0, 30);
                value = value.replace(/[^A-Za-z0-9 ]/g, "");
                value = value.replace(/[^A-Za-z ]/g, "");
                e.target.value = value;
              }}
            />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter phone number",
              },
              {
                pattern: /^[6-9]\d{9}$/,
                message: "please enter a valid phone number",
              },
              {
                max: 10,
                message: "Phone number cannot exceed 10 digits",
              },
              {
                validator: (_, value) => {
                  // If the value exists and contains non-numeric characters
                  if (value && /\D/.test(value)) {
                    return Promise.reject(
                      new Error("Phone number can only contain numbers")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter Phone Number"
              maxLength={10}
              onKeyPress={(event) => {
                const { key } = event;
                if (!/^\d$/.test(key)) {
                  event.preventDefault();
                  message.error("Only numbers are allowed in Phone Number.");
                }
              }}
              onPaste={(event) => {
                const pastedData = event.clipboardData.getData("Text");
                if (!/^\d+$/.test(pastedData)) {
                  event.preventDefault();
                  message.error("Only numbers are allowed in Phone Number.");
                }
              }}
              onInput={(e) => {
                let value = e.target.value;
                value = value.replace(/\D/g, "");
                e.target.value = value;
              }}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            // rules={[
            //   { required: true, message: "Please enter email" },
            //   { type: "email", message: "Please enter a valid email" },
            // ]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Please enter password" },
              {
                min: 6,
                message: "Password must be at least 6 characters",
              },
            ]}
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>

          {/* <Form.Item
            name="role_Id"
            label="Role ID"
            rules={[
              { required: true, message: "Please enter Role ID" },
              {
                pattern: /^[a-fA-F0-9]{24}$/,
                message: "Role ID must be a valid 24-character hex string",
              },
            ]}
          >
            <Input placeholder="Enter Role ID" />
          </Form.Item> */}
          {/* <Form.Item
            name="role_Id"
            label="Role"
            rules={[{ required: true, message: "Please select a role" }]}
          >
            <Select placeholder="Select a Role">
              {roles.map((role) => (
                <Select.Option key={role._id} value={role._id}>
                  {role.roleName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="gst"
            label="GST Number"
            rules={[
              {
                required: false,
                pattern: /^[0-9]{2}[A-Z0-9]{10}[A-Z0-9]{3}$/,
                message: "GST Number must follow the correct format",
              },
            ]}
          >
            <Input placeholder="Enter GST Number" maxLength={15} />
          </Form.Item>

          <Form.Item
            name="pan"
            label="PAN Number"
            rules={[
              {
                required: false,
                pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                message: "PAN Number must follow the correct format",
              },
            ]}
          >
            <Input placeholder="Enter PAN Number" maxLength={10} />
          </Form.Item>

          <Form.Item
            name="shopName"
            label="Shop Name"
            rules={[
              { required: true, message: "Please enter shop name" },
              {
                pattern: /^[A-Za-z0-9\s]+$/,
                message: "Shop name can include letters, numbers, and spaces",
              },
            ]}
          >
            <Input placeholder="Enter Shop Name" maxLength={100} />
          </Form.Item>

          {/* {error && (
            <div className="text-red-500 mb-4">
              <strong>Error:</strong> {error}
            </div>
          )} */}

          <Form.Item>
            <div className="flex justify-between">
              <Button
                type="default"
                onClick={onCancel}
                className="text-[#009A00] border border-[#009A00] rounded-md p-2"
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="bg-[#009A00] hover:bg-[#008000] text-white rounded-md p-2"
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>

        {/* Success Modal */}
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className="custom-modal"
        >
          <div className="Seller-success-container flex flex-col items-center gap-4">
            <div className="flex justify-center">
              <img
                src="/Assests/Images/success_image.png"
                alt="Success"
                className="w-24"
              />
            </div>
            <div>
              <h5 className="font-medium text-center">
                Seller Added <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default AddSeller;
