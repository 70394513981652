import React, { useState } from "react";
import { Pagination, message, Avatar, Modal, Select } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import Stockmanagementhistory from "./Stockmanagementhistory";
import AddStock from "./AddStock";

const Stockmanagementlist = ({
  paginatedData,
  fetchStockProductDetails,
  totalSKUs,
  pageSize,
  currentPage,
  onPageChange,
}) => {
  const { axiosInstance } = AxiosComponent();
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [modalProductDetails, setModalProductDetails] = useState(null);

  const handleOpenAddStockModal = (productId, skuId, productName, quantity) => {
    setModalProductDetails({ productId, skuId, productName, quantity });
    setIsModalOpen(true);
  };
  const { Option } = Select;

  const [selectedSku, setSelectedSku] = useState({});

  const handleSkuChange = (productId, sku) => {
    setSelectedSku((prev) => ({
      ...prev,
      [productId]: sku,
    }));
  };

  const fetchSKUHistory = async (skuId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${baseURL}/getSKUSellerHistory`,
        { skuId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.responseCode === 200) {
        setHistoryData(response.data.data);
        setIsHistoryModalVisible(true);
      } else if (response.data.responseCode === 400) {
        message.error();
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="w-full bg-white p-4">
        <table className="w-full border-b border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="border-b p-4 text-center">S.No</th>
              <th className="border-b p-4 text-center">Product Name</th>
              <th className="border-b p-4 text-center">SKU</th>
              <th className="border-b p-4 text-center">Fair Price</th>
              <th className="border-b p-4 text-center">Instant Price</th>
              <th className="border-b p-4 text-center">Quantity</th>
              <th className="border-b p-4 text-center">Date</th>
              <th className="border-b p-4 text-center">Stock Status</th>
              <th className="border-b p-4 text-center">Action</th>
              <th className="border-b p-4 text-center">History</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((sku, index) => (
              <tr key={sku._id} className="border-b">
                <td className="border-b p-4 text-center">
                  {(currentPage - 1) * pageSize + index + 1}
                </td>
                <td className="border-b p-4 text-center flex items-center gap-3">
                  <Avatar
                    size={35}
                    className="bg-gray-200 text-black capitalize"
                  >
                    {sku.productName[0] || "--"}
                  </Avatar>
                  {sku.productName}
                </td>
                <td className="border-b p-4 text-center">{sku.quantity}</td>
                <td className="border-b p-4 text-center">{sku.fairPrice}</td>
                <td className="border-b p-4 text-center">{sku.instantPrice}</td>
                <td className="border-b p-4 text-center">
                  {sku.totalQuantityOfSKU}
                </td>
                <td className="border-b p-4 text-center">
                  {sku.lastUpdatedDate}
                </td>
                <td
                  className={`border-b p-4 text-center font-semibold ${
                    sku.stockStatus === "In stock"
                      ? "text-green-600"
                      : sku.stockStatus === "Out of stock"
                      ? "text-red-600"
                      : "text-blue-600"
                  }`}
                >
                  <span
                    className={`px-2 py-1 rounded-lg ${
                      sku.stockStatus === "In stock"
                        ? "bg-green-300"
                        : sku.stockStatus === "Out of stock"
                        ? "bg-red-300"
                        : "bg-blue-300"
                    }`}
                  >
                    {sku.stockStatus}
                  </span>
                </td>
                <td className="border-b p-4 text-center">
                  <button
                    onClick={() =>
                      handleOpenAddStockModal(
                        sku.productId,
                        sku._id,
                        sku.productName,
                        sku.quantity
                      )
                    }
                    className="bg-[#009A00] text-white py-1 px-4 rounded-md"
                  >
                    Add Stock
                  </button>
                </td>
                <td className="border-b p-4 text-center">
                  <button
                    className="bg-white text-[#009A00] py-1 px-4 rounded-md font-medium"
                    onClick={() => fetchSKUHistory(sku._id)}
                  >
                    History
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          className="flex justify-end items-center py-4"
          current={currentPage}
          pageSize={pageSize}
          simple
          total={totalSKUs}
          onChange={onPageChange}
        />
      </div>
      <Modal
        visible={isHistoryModalVisible}
        onCancel={() => setIsHistoryModalVisible(false)}
        footer={null}
        centered
        fixed
        width={700}
        title="Stock Management History"
      >
        <Stockmanagementhistory data={historyData} loading={loading} />
      </Modal>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        className="rounded-2xl"
        title="Add Stock"
        footer={null}
        centered
        fixed
      >
        {modalProductDetails && (
          <AddStock
            fetchStockProductDetails={fetchStockProductDetails}
            productName={modalProductDetails.productName}
            quantity={modalProductDetails.quantity}
            productId={modalProductDetails.productId}
            skuId={modalProductDetails.skuId}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default Stockmanagementlist;
