// RidersList.jsx
import React, { useState, useEffect } from "react";
import { Avatar, Switch, Pagination, message, Modal } from "antd";
import { axiosInstance } from "../../../util/axiosInstance";
import { MdOutlineEdit } from "react-icons/md";
import EditRider from "./EditRider";

const RidersList = ({ riders, toggleRiderStatus, fetchRiders }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showRiderModal, setShowRiderModal] = useState(false);
  const [selectedRider, setSelectedRider] = useState(null);
  const pageSize = 10;

  const handleEditClick = (rider) => {
    setSelectedRider(rider);
    setShowRiderModal(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleModalClose = () => {
    setShowRiderModal(false);
    setSelectedRider(null);
  };

  const handleRiderUpdate = () => {
    fetchRiders();
    setShowRiderModal(false);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = riders?.slice(startIndex, startIndex + pageSize);

  return (
    <div className="p-4 bg-white">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr className="border-t">
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-left">Name</th>
            <th className="border-b p-4 text-center">E-mail</th>
            <th className="border-b p-4 text-center">Phone</th>
            <th className="border-b p-4 text-center">Driving License</th>
            <th className="border-b p-4 text-center">Aadhaar Number</th>
            <th className="border-b p-4 text-center">Address</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Created At</th>
            {/* <th className="border-b p-4 text-center">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((rider, index) => (
            <tr key={rider._id}>
              <td className="border-b p-4 text-center">
                {startIndex + index + 1}
              </td>
              <td className="border-b p-4 items-center text-center justify-center">
                <span className="flex gap-3 text-center items-center ">
                  <Avatar
                    src={rider.profilePic || rider.riderName[0]}
                    size={35}
                    className="mr-2 bg-gray-200 text-black capitalize"
                  >
                    {rider.riderName ? rider.riderName[0] : ""}
                  </Avatar>
                  {rider.riderName || "--"}
                </span>
              </td>
              <td className="border-b p-4">{rider.email || "--"}</td>
              <td className="border-b p-4 text-center">
                {rider.phone || "--"}
              </td>
              <td className="border-b p-4 text-center">
                {rider.drivingLicense || "--"}
              </td>
              <td className="border-b p-4 text-center">
                {rider.aadhaarNumber || "--"}
              </td>
              <td className="border-b p-4">{rider.address || "--"}</td>
              <td className="border-b p-4 text-center">
                <Switch
                  checked={rider.status.toLowerCase() === "active"}
                  onChange={() => toggleRiderStatus(rider)}
                  style={{
                    backgroundColor:
                      rider.status.toLowerCase() === "active"
                        ? "#009A00"
                        : "#d9d9d9",
                  }}
                />
              </td>
              <td className="border-b p-4 text-center">
                {rider.createdAt || "--"}
              </td>
              {/* <td className="border-b p-4 text-center">
                <MdOutlineEdit
                  onClick={() => handleEditClick(rider)}
                  className="text-[#009A00] cursor-pointer"
                  size={18}
                />
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="Pagination-container mt-3 justify-end flex">
        <Pagination
          simple
          current={currentPage}
          pageSize={pageSize}
          total={riders?.length}
          onChange={handlePageChange}
        />
      </div>

      {selectedRider && (
        <Modal
          visible={showRiderModal}
          onCancel={handleModalClose}
          title="Edit Rider"
          footer={null}
          centered
        >
          <EditRider
            Rider={selectedRider}
            close={handleModalClose}
            fetchData={handleRiderUpdate}
            riders={riders}
          />
        </Modal>
      )}
    </div>
  );
};

export default RidersList;
