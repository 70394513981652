import React, { useState , useEffect  } from "react";
import { Avatar, Switch, message, Pagination, Modal } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import EditCustomer from "./EditCustomer";

const CustomerList = ({ customers, setCustomers, fetchCustomerData }) => {
  const [loading, setLoading] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [deactiveSuccess, setDeactiveSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [selectedCustomer, setSelectedCustomer] = useState(null); // Added state
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false); // Added state

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const toggleStatus = async (customer) => {
    const Endpoint = "toggleCustomerStatus";
    const status = customer?.status;
    try {
      setLoading(true);
      const userData = {
        customerId: customer?._id,
      };
  
      const response = await fetch(`${baseURL}/${Endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
        },
        body: JSON.stringify(userData),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.responseCode === 200) {
          message.success(data.message);
  
          const updatedCustomers = customers.map((c) => {
            if (c._id === customer._id) {
              return {
                ...c,
                status: status === "active" ? "inactive" : "active",
              };
            }
            return c;
          });
          setCustomers(updatedCustomers);
  
          
          setActiveSuccess(false);
          setDeactiveSuccess(false);
          if (status === "active") {
            setDeactiveSuccess(true);
          } else if (status === "inactive") {
            setActiveSuccess(true);
          }
  
          fetchCustomerData(); 
        } else if (data.responseCode === 400) {
         
          message.error( data.message);
        } else {
          message.error(data.message);
        }
      } else if (response.status === 400) {
       
        const errorData = await response.json();
        message.error( errorData.message);
      } else {
        const errorData = await response.json();
        message.error(errorData.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    setCurrentPage(1);
  }, [customers]);


  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = customers?.slice(startIndex, endIndex);

  const editCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowEditCustomerModal(true);
  };

  const closeEditModal = () => {
    setShowEditCustomerModal(false);
    setSelectedCustomer(null);
  };

  return (
    <div className="p-4 bg-white">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr className="border-t">
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-left">Name of the Customer</th>
            <th className="border-b p-4 text-center">Customer ID</th>
            <th className="border-b p-4 text-center">Contact</th>
            <th className="border-b p-4 text-center">E-mail</th>
            <th className="border-b p-4 text-center">Booking History</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((customer, index) => (
            <tr key={customer._id}>
              <td className="border-b p-4">{startIndex + index + 1}</td>
              <td className="border-b p-4 flex items-center gap-3">
                {customer.image ? (
                  <Avatar src={customer.image} size={35} shape="circle" />
                ) : (
                  <Avatar
                    size={35}
                    shape="circle"
                    className="mr-2 bg-gray-200 text-black capitalize"
                  >
                    {customer.userName ? customer.userName[0] : ""}
                  </Avatar>
                )}
                {customer.userName || "--"}
              </td>
              <td className="border-b p-4">{customer.CoustmerID|| "--"}</td>
              <td className="border-b p-4">{customer.phone || "--"}</td>
              <td className="border-b p-4">{customer.email || "--"}</td>
              <td className="border-b p-4">
                <button className="text-[#009A00] px-4 py-1">View</button>
              </td>
              <td className="border-b p-4">
                <Switch
                  checked={customer?.status === "active"}
                  onChange={() => toggleStatus(customer)}
                  className="customerlistBody_antSwitch"
                  style={{
                    backgroundColor:
                      customer?.status === "active" ? "#009A00" : "#d9d9d9",
                  }}
                />
              </td>
              <td className="border-b p-4 text-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    onClick={() => editCustomer(customer)}
                    className="text-[#009A00] cursor-pointer"
                    size={18}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {paginatedData?.length === 0 && (
        <div className="no-data-message mt-3">
          <p>No data found</p>
        </div>
      )}

      <div className="Pagination-container mt-3 justify-end flex">
        {customers?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={customers?.length}
            showSizeChanger
            onShowSizeChange={handleChangePage}
            onChange={handleChangePage}
          />
        )}
      </div>

      <Modal
        visible={showEditCustomerModal}
        onCancel={closeEditModal}
        title="Edit Customer"
        footer={null}
        centered
        destroyOnClose
      >
        {selectedCustomer && (
          <EditCustomer
            selectedCustomer={selectedCustomer}
            close={closeEditModal}
            fetchCustomerData={fetchCustomerData}
          />
        )}
      </Modal>
    </div>
  );
};

export default CustomerList;
