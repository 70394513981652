import React, { useEffect, useState } from "react";
import { Input, Pagination, Select, Modal, Spin, message } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import AxiosComponent from "../../util/axiosInstance";


const { Option } = Select;

const HelpCenterList = ({ helpRequests, fetchHelpRequests }) => {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState(helpRequests);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [statusChange, setStatusChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const { axiosInstance } = AxiosComponent();
  const token = localStorage.getItem("Mr_Sasta");

  useEffect(() => {
    const filtered = helpRequests.filter(
      (request) =>
        request.name.toLowerCase().includes(searchText.toLowerCase()) ||
        request.email.toLowerCase().includes(searchText.toLowerCase()) ||
        request.problem.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchText, helpRequests]);

  const handleStatusChange = (status, request) => {
    setNewStatus(status);
    setSelectedRequest(request);
    setStatusChange(true);
  };

  const statusUpdate = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/updateHelpRequestStatus",
        {
          helpRequestId: selectedRequest._id,
          status: newStatus,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.responseCode === 200) {
        message.success(response.data.message);
        fetchHelpRequests();
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
      setStatusChange(false);
    }
  };

  const handleChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredData.slice(startIndex, endIndex);

 

  return (
    <div className="p-4">
      <div className="mb-4 w-full md:w-72">
        <Input
          placeholder="Search by name, email, or problem"
          value={searchText}
          suffix={<SearchOutlined className="text-gray-400" />}
          onChange={(e) => setSearchText(e.target.value)}
          className="border border-gray-300 rounded-md px-4 py-2"
        />
      </div>

      <table className="w-full bg-white">
        <thead className="border-t">
          <tr>
            <th className="p-4 text-left font-semibold">S.No</th>
            <th className="p-4 text-left font-semibold">Seller Name</th>
            <th className="p-4 text-left font-semibold">Customer Name</th>
            <th className="p-4 text-center font-semibold">Email</th>
            <th className="p-4 text-left font-semibold">Phone Number</th>
            <th className="p-4 text-center font-semibold">Issue</th>
            <th className="p-4 text-center font-semibold">Status</th>
            <th className="p-4 text-left font-semibold">Issue Images</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((request, index) => (
            <tr key={request._id} className="border-t">
              <td className="p-4">
                {(currentPage - 1) * pageSize + index + 1}
              </td>
              <td className="p-4">{request.sellerName || "--"}</td>
              <td className="p-4">{request.customerName || "--"}</td>
              <td className="p-4">{request.email}</td>
              <td className="p-4">{request.phone}</td>
              <td className="p-4">{request.problem}</td>
              <td className="p-4">
                <Select
                  defaultValue={request.status}
                  onChange={(status) => handleStatusChange(status, request)}
                  className="w-full rounded-lg font-semibold"
                  style={{ color: "#009A00" }}
                >
                  <Option value="Inprogress" className='text-blue-300 font-semibold'>In Progress</Option>
                  <Option value="Under Review">Under Review</Option>
                  <Option value="Resolved">Resolved</Option>
                  <Option value="Refund">Refund</Option>
                  <Option value="Not Resolved">Not Resolved</Option>
                </Select>
              </td>

              <td className="p-4 text-center">
                <EyeOutlined
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                    color: "#009A00",
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-end mt-4">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredData.length}
          onChange={handleChangePage}
          simple
          showSizeChanger
          onShowSizeChange={handleChangePage}
        />
      </div>

      <Modal
        visible={statusChange}
        onCancel={() => setStatusChange(false)}
        centered
        footer={null}
        className="custom-modal"
      >
        <Spin spinning={loading}>
          <div className="flex flex-col items-center p-6 bg-white rounded-lg">
            <h5 className="text-lg font-semibold text-center mb-4">
              Are you sure you want to change the status to "{newStatus}"?
            </h5>
            <div className="flex justify-between w-full mt-4">
              <button
                className="flex-1 mx-2 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-200"
                onClick={() => setStatusChange(false)}
              >
                No
              </button>
              <button
                className="flex-1 mx-2 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                onClick={statusUpdate}
              >
                Yes
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default HelpCenterList;
