// SellerList.jsx
import React, { useState  , useEffect} from "react";
import { Avatar, Switch, Pagination, Modal, message } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import EditSeller from "./EditSeller";

const SellerList = ({ sellerData, toggleSellerStatus, fetchSellerData ,filter ,  searchInput }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditSellerModal, setShowEditSellerModal] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [switchLoading, setSwitchLoading] = useState({}); 
  const pageSize = 10;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = sellerData?.slice(startIndex, startIndex + pageSize);

  const editSeller = (seller) => {
    setSelectedSeller(seller);
    setShowEditSellerModal(true);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [filter, searchInput,]);

 
  const handleToggleStatus = async (seller) => {
    const sellerKey = seller._id || seller.sellerId;
    setSwitchLoading((prev) => ({ ...prev, [sellerKey]: true }));
    try {
      await toggleSellerStatus(seller);
      fetchSellerData(); 
    } catch (error) {
    } finally {
      setSwitchLoading((prev) => ({ ...prev, [sellerKey]: false }));
    }
  };

  const closeEditSellerModal = () => {
    setShowEditSellerModal(false);
    setSelectedSeller(null);
  };

  const handleSellerUpdate = () => {
    fetchSellerData();
    setShowEditSellerModal(false);
  };

  return (
    <div className="p-4 bg-white">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr className="border-t">
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-left min-w-[150px] max-w-[200px]">
              Seller Name
            </th>
            <th className="border-b p-4 text-center">Phone Number</th>
            <th className="border-b p-4 text-left min-w-[200px]">Address</th>
            <th className="border-b p-4 text-center">No. of Orders</th>
            <th className="border-b p-4 text-center">Avg. Rating</th>
            <th className="border-b p-4 text-center">Total Earnings</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((seller, index) => {
            const sellerKey = seller._id || seller.sellerId;
            return (
              <tr key={sellerKey}>
                <td className="border-b p-4 text-center">
                  {startIndex + index + 1}
                </td>
                <td className="border-b p-4 flex items-center gap-3">
                  <Avatar
                    size={35}
                    shape="circle"
                    className="bg-gray-200 text-black capitalize"
                    
                  >
                    {seller.sellerName[0] || "--"}
                  </Avatar>
                  {seller.sellerName || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {seller.phone || "--"}
                </td>
                <td className="border-b p-4 text-left">
                  {seller.address || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {seller.numberOfOrders || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {seller.averageRating || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {seller.totalEarnings || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  <Switch
                    checked={seller.status.toLowerCase() === "active"}
                    onChange={() => handleToggleStatus(seller)}
                    // loading={switchLoading[sellerKey]} 
                    style={{
                      backgroundColor:
                        seller.status.toLowerCase() === "active"
                          ? "#009A00"
                          : "#d9d9d9",
                    }}
                  />
                </td>
                <td className="border-b p-4 text-center">
                  <MdOutlineEdit
                    onClick={() => editSeller(seller)}
                    className="text-[#009A00] cursor-pointer"
                    size={18}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {paginatedData.length === 0 && (
        <div className="no-data-message mt-3">
          <p>No data found</p>
        </div>
      )}
      {sellerData.length > 0 && (
        <div className="Pagination-container mt-3 justify-end flex">
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={sellerData?.length}
            showSizeChanger
            onShowSizeChange={handleChangePage}
            onChange={handleChangePage}
          />
        </div>
      )}
      <Modal
        visible={showEditSellerModal}
        centered
        onCancel={closeEditSellerModal}
        title="Edit Seller"
        footer={null}
        className="custom-modal h-[80%] overflow-auto rounded"
      >
        <EditSeller
          Seller={selectedSeller}
          fetchSellerData={fetchSellerData}
          close={closeEditSellerModal}
        />
      </Modal>
    </div>
  );
};

export default SellerList;
