import React from "react";
import { GoArrowLeft } from "react-icons/go";
import { Avatar } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

const PaymentHprofile = ({ payment }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sellerName, sellerId } = location.state || {};

  return (
    <div className="mt-0 flex items-center gap-10 border-b p-3">
      <div
        className="text-black"
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <GoArrowLeft size={26} />
      </div>
      <div className="flex items-center gap-4">
        <Avatar
          size={45}
          shape="circle"
          className="mr-2 bg-gray-200 text-black capitalize"
        >
          {sellerName ? sellerName[0] : "S"}
        </Avatar>
        <div>
          <span className="text-lg font-normal">
            {sellerName || "Seller Name"}
          </span>
          <br />
          <span className="text-sm text-black font-normal text-left flex">
            ID: {sellerId || "--"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentHprofile;
