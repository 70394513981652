import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { Avatar } from "antd";

// const data = [
//   { name: "Cereals", value: 30 },
//   { name: "Rice", value: 28 },
//   { name: "Flours", value: 18 },
//   { name: "Masalas", value: 9 },
//   { name: "Powders", value: 1 },
// ];

const colors = ["#34D399", "#3B82F6", "#FBBF24", "#F87171", "#10B981"];

const StockManagementChart = ({
  productData,
  stockStatusData,
  topSellingData,
}) => {

  useEffect(() => {
    console.log("Top Selling Data:", topSellingData);
  }, [topSellingData]);
  return (
    <div className="flex justify-between gap-4 bg-white p-6">
      <div className="w-1/3 bg-white shadow-lg rounded-lg p-4 h-56">
        <h3 className="font-medium text-lg mb-4 text-left ">Product Details</h3>
        <div className="gap-2 flex flex-col">
          <div className="flex justify-between mb-2 text-lg">
            <p className="text-red-500">Out of stock products</p>
            <span className="text-red-500">{productData?.outOfStockCount}</span>
          </div>
          <div className="flex justify-between mb-2 text-base">
            <p>Total Categories</p>
            <span>{productData?.categoriesCount}</span>
          </div>
          <div className="flex justify-between text-base">
            <p>Total Products</p>
            <span>{productData?.productsCount}</span>
          </div>
        </div>
      </div>

      {/* Top Selling Categories/Products Section */}
      <div className="w-1/3 bg-white shadow-lg rounded-lg p-4 h-56">
        <h3 className="font-medium text-lg mb-4 text-left">
          Top Selling Categories/Products
        </h3>
        <div className="flex items-center gap-14">
          <ResponsiveContainer width={171} height={160}>
            <PieChart>
              <Pie
                data={topSellingData}
                cx="50%"
                cy="50%"
                outerRadius={60}
                dataKey="value"
                label={({ value }) => `${value}%`} 
              >
                {topSellingData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className="ml-4">
            {topSellingData?.map((item, index) => (
              <div key={index} className="flex items-center mb-2 gap-2">
                <span
                  className="w-3 h-3 mr-2 rounded-full"
                  style={{ backgroundColor: colors[index % colors.length] }}
                ></span>
                <span>
                  {item.categoryName}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Emergency Require Items Section */}
      <div className="w-1/3 bg-white shadow-lg rounded-lg p-4 h-56 ">
        <h3 className="font-medium text-lg mb-4 text-left">
          Emergency Require Items
        </h3>
        <div className="flex flex-col gap-3 overflow-y-auto h-36">
          {stockStatusData?.map((item, index) => (
            <div key={index} className="flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <Avatar
                  shape="square"
                  size={40}
                  style={{ backgroundColor: "#009A00" }}
                >
                  {item.productName.charAt(0)}
                </Avatar>
                <span className="ml-2 flex-1">{item.productName}</span>
              </div>
              <span className="text-red-500">{item.stockStatus}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StockManagementChart;
