import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import PaymentList from "./PaymentList";
import Paymentcard from "./Paymentcard";
import AxiosComponent from "../../../util/axiosInstance";

const SellerPaymentMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sellerPaymentData, setSellerPaymentData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredPaymentData, setFilteredPaymentData] = useState([]);
  const { axiosInstance } = AxiosComponent();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const fetchSellerPaymentData = async () => {
    const sellerpaymentEndpoint = "/getSingleSellerPayment";
    try {
      setLoading(true);
      const response = await axiosInstance.get(sellerpaymentEndpoint);
  
      const data = response.data;
      if (response.status === 200 && data.responseCode === 200) {
        setSellerPaymentData(data?.data);
        setFilteredPaymentData(data?.data);
      } else if (response.status === 400) {
        message.error(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const filteredData = sellerPaymentData.filter(
      (payment) =>
        payment.sellerName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        payment.sellerId
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
    );
    setFilteredPaymentData(filteredData);
  }, [searchInput, sellerPaymentData]);

  useEffect(() => {
    fetchSellerPaymentData();
  }, []);

  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <Paymentcard
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        <div>
          <PaymentList paymentData={filteredPaymentData} />
        </div>
      </div>
    </Spin>
  );
};

export default SellerPaymentMain;
