// RolesList.jsx
import React, { useState, useEffect } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { Switch, Pagination, message, Modal } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import EditRole from "./EditRole";

const RolesList = ({ rolesData, updateRolesData, featuresData ,fetchRoles }) => {
  const [paginatedData, setPaginatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { axiosInstance } = AxiosComponent();
  
  // Modal state
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const pageSize = 10;

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedData(rolesData?.slice(startIndex, endIndex));
  }, [currentPage, rolesData]);

  const toggleStatus = async (role) => {
    const roleId = role._id;
    setLoading(true);
    setError("");

    try {
      const response = await axiosInstance.post(
        `${baseURL}/roleSellerStatus`,
        { roleId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = response.data;

      if (response.status === 200) {
        message.success(result.message);
        const updatedRole = { ...role, status: role.status === "active" ? "inactive" : "active" };
        updateRolesData(updatedRole);
      } else {
        throw new Error(result.message);
      }
    } catch (err) {
      setError(err.message);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  // Function to open the edit modal
  const openEditModal = (role) => {
    setSelectedRole(role);
    setIsEditModalVisible(true);
  };

  // Function to close the edit modal
  const closeEditModal = () => {
    setSelectedRole(null);
    setIsEditModalVisible(false);
  };

  return (
    <div className="p-4 bg-white">
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-center">Name</th>
            <th className="border-b p-4 text-center">Date Created</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((role, index) => (
            <tr key={role._id} className="border-b">
              <td className="px-4 py-2">{(currentPage - 1) * pageSize + index + 1}</td>
              <td className="px-4 py-2 capitalize">{role?.roleName || "--"}</td>
              <td className="px-4 py-2">{role?.date || " - "}</td>
              <td className="px-4 py-2">
                <Switch
                  checked={role?.status === "active"}
                  onChange={() => toggleStatus(role)}
                  style={{
                    backgroundColor: role?.status === "active" ? "#009A00" : "#d9d9d9",
                  }}
                  loading={loading}
                />
              </td>
              <td className="px-4 py-2 text-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    className="text-[#009A00] cursor-pointer"
                    size={18}
                    onClick={() => openEditModal(role)} 
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {paginatedData?.length === 0 && (
        <div className="mt-3 text-center text-gray-500">
          <p>No data found</p>
        </div>
      )}

      <div className="mt-3 flex justify-end">
        <Pagination
          simple
          current={currentPage}
          pageSize={pageSize}
          total={rolesData?.length}
          onChange={handleChangePage}
        />
      </div>

      {/* Edit Role Modal */}
      <Modal
        title="Edit Role"
        open={isEditModalVisible}
        onCancel={closeEditModal}
        footer={null}
        centered // We'll handle the footer inside EditRole
        // destroyOnClose
      >
        {selectedRole && (
          <EditRole
            close={closeEditModal}
            roleData={selectedRole}
            featuresData={featuresData}
            fetchRoles={fetchRoles}
            updateRolesData={updateRolesData}
          />
        )}
      </Modal>
    </div>
  );
};

export default RolesList;
