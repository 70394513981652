import { Form, Input, Modal, Select, Spin, message ,DatePicker } from "antd";
import React, { useState } from "react";
import { handleProfileImage } from "../../../containers/fileupload/fileupload";
import AxiosComponent from "../../../util/axiosInstance";
import moment from "moment";

const AddCustomer = ({ fetchCustomerData, close }) => {
  const [form] = Form.useForm();
  const [customerImage, setCustomerImage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance } = AxiosComponent();

  const validatePhoneNumber = (_, value) => {
    if (value && value.replace(/\D/g, "").length !== 10) {
      return Promise.reject("Phone number must be 10 digits");
    }
    return Promise.resolve();
  };
  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];
    
    // Check file type
    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }

    // Check file size is less than 500KB
    if (file.size > 500 * 1024) {
      message.error("Image size is greater than 500KB. The maximum limit is 500KB.");
      return;
    }

    handleProfileImage(event)
      .then((imageUrl) => {
        setCustomerImage(imageUrl);
      })
      .catch((error) => {
        message.error(error.message);
      });
};


const onFinish = async (values) => {
  try {
    setLoading(true);
    const location = {
      type: "Point",
      coordinates: [78.4867, 17.385], 
    };
    const response = await axiosInstance.post(
      `${baseURL}/addCustmer`,
      {
        phone: values.phoneNumber,
        userName: values.username,
        email: values.email,
        dob: values.dob,
        image: customerImage,
        location,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.responseCode === 200) {
      message.success(response.data.message);
      setSuccessModal(true);
      form.resetFields();
      fetchCustomerData();
      close();
    } else if (response.data.responseCode === 400) {
      message.error( response.data.message);
    } else {
      message.error(response.data.message);
    }
  } catch (error) {
    console.error("Error:", error);
    if (error?.response?.status === 400) {
      message.error( error?.response?.data?.message);
    } else {
      message.error(error?.response?.data?.message);
    }
  } finally {
    setLoading(false);
  }
};

  return (
    <Spin spinning={loading}>
      <div className="AddCustomer-container">
        <div className="AddCustomer-content">
          <Form
            form={form}
            name="add_customer_form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="Customer Name"
              rules={[
                {
                  required: true,
                  message: "Please enter customer name",
                },
              ]}
            >
              <Input type="text" placeholder="Enter Customer Name" />
            </Form.Item>
            <Form.Item
              name="dob"
              label="Date of Birth"
             
            >
              <DatePicker
                type="date"
                style={{ width: "100%" }}
                placeholder="Enter Date of Birth"
                disabledDate={(current) => {
                  return current && current >= moment().startOf("day");
                }}
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                { validator: validatePhoneNumber },
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <Input type="text" placeholder="Enter Phone Number" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>

            {/* File Upload Section */}
            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              {customerImage ? (
                <div className="FileChange">
                  <div className="image-section  flex justify-center">
                    <img src={customerImage} alt="Customer"  className="w-20 h-20"/>
                  </div>
                  <label htmlFor="fileInput" className="primary-text pointer text-blue-600">
                    Change Image
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleAadharImage}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div className="FileUpload ">
                  <div className="flex justify-center">
                    <img
                      src="/Assests/Images/image-gallery.png"
                      alt="category"
                      className="file-upload"
                    />
                  </div>
                  <p>
                    Add Profile image or{" "}
                    <label
                      htmlFor="fileInput"
                      className="text-[#009A00] font-medium cursor-pointer"
                    >
                      browse
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleAadharImage}
                      style={{display:"none" }}
                    />
                  </p>
                  <small className="secondary-text">Maximum size: 500KB</small>
                </div>
              )}
            </div>

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="add-customer-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2"
                  onClick={close}
                >
                  <p>Cancel</p>
                </div>
                <button
                  htmlType="submit"
                  className="add-customer-submit-button !bg-[#009A00] text-white py-2 rounded-md font-medium p-4"
                >
                  <p>Submit</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        visible={successModal}
        onCancel={() => setSuccessModal(false)}
        centered
        footer={null}
        className="custom-modal"
      >
        <div className="customer-success-container">
          <div className="customer-success-content">
            <div className="flex justify-center">
              <img src="Assests/Images/success_image.png" alt="success" />
            </div>
            <h5 className="font-medium justify-center">
              Customer Added <br /> Successfully
            </h5>
          </div>
        </div>
      </Modal>
    </Spin>
  );
};

export default AddCustomer;
