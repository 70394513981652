import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Col, Row, message } from "antd";
import AxiosComponent from "../../../util/axiosInstance";

const AddRole = ({
  close,
  setAddRoleReviewModal,
  featuresData,
  setCheckedActivities,
  checkedActivities,
  roleName,
  setRoleName,
  fetchRoles,
}) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance } = AxiosComponent();
  const [form] = Form.useForm();

  const onChange = (checkedValues) => {
    setCheckedActivities(checkedValues);
  };
  useEffect(() => {
    form.resetFields();
  }, [ form]);

  const onFinish = async (values) => {
    const rolePayload = {
      roleName: values.role,
      features: checkedActivities.map((feature) => ({
        featureId: feature._id,
      })),
      source: "Mr_Sasta",
    };

    try {
      const response = await axiosInstance.post(
        `${baseURL}/addSellerRole`,
        rolePayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.responseCode === 200) {
        message.success(response.data.message);
        setRoleName("");
        setCheckedActivities([]);
        fetchRoles();
        close();
        form.resetFields();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Failed to create role");
    }
  };
 
  return (
    <div className="Add-role-container">
      <div className="Add-role-content">
        <Form
          form={form}
          name="add_role"
          className="add-role-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="role"
            label="Role Name"
            initialValue={roleName}
            rules={[
              {
                required: true,
                message: "Please enter role name",
              },
            ]}
          >
            <Input
              type="text"
              value={roleName} // Controlled by roleName state
              onChange={(e) => setRoleName(e.target.value)}
            />
          </Form.Item>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={checkedActivities}
            onChange={onChange}
          >
            <Row style={{ marginBottom: "10px" }}>
              {featuresData?.length > 0
                ? featuresData.map((item) => (
                    <Col
                      span={8}
                      style={{ marginBottom: "12px" }}
                      key={item._id}
                    >
                      <Checkbox value={item}>{item?.featureName}</Checkbox>
                    </Col>
                  ))
                : ""}
            </Row>
          </Checkbox.Group>
          <Form.Item>
            <div className="add-role-submitn flex justify-between">
              <div
                className="add-role-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2 cursor-pointer"
                onClick={close}
              >
                <p>Cancel</p>
              </div>
              {checkedActivities?.length > 0 ? (
                <button
                  type="primary"
                  htmlType="submit"
                  className="add-role-submit-button !bg-[#009A00] !hover:bg-[#008000] text-white py-2 rounded-md font-medium p-4"
                >
                  <p>Submit</p>
                </button>
              ) : (
                <div className="add-role-submit-btn !bg-[#009A00] !hover:bg-[#008000] text-white py-2 rounded-md font-medium p-4">
                  <p>Submit</p>
                </div>
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddRole;
