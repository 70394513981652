import React, { useState } from "react";
import { Avatar, Pagination, Switch, message } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import AxiosComponent from "../../../util/axiosInstance";

const SellerSubCategoryList = ({ categories, setCategories, setUpdateCategory }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const { axiosInstance } = AxiosComponent();

  const toggleStatus = async (category) => {
    const endpoint = "toggleSubCategoryStatus";
    const status = category?.status;
  
    try {
      setLoading(true);
      const requestData = { id: category?.subCategoryId };
  
      const response = await axiosInstance.post(
        `${baseURL}/${endpoint}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
          },
        }
      );
  
      const data = response.data;
  
      if (data.responseCode === 200) {
        message.success(data.message);
        const updatedCategories = categories.map((c) => {
          if (c.subCategoryId  === category?.subCategoryId) {
            return {
              ...c,
              status: status === "active" ? "inactive" : "active",
            };
          }
          return c;
        });
  
        setCategories(updatedCategories);
      } else if (data.responseCode === 400) {
        message.error(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleChangePage = (page) => setCurrentPage(page);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = categories?.slice(startIndex, endIndex);

  return (
    <div className="p-1 bg-white overflow-auto">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr>
            <th className="border-b p-2">S.No</th>
            <th className="border-b p-2">Category</th>
            <th className="border-b p-2">Description</th>
            <th className="border-b p-2">No of Products</th>
            <th className="border-b p-2">Status</th>
            <th className="border-b p-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData?.length > 0 ? (
            paginatedData.map((item, index) => (
              <tr key={item.subCategoryId}>
                <td className="border-b p-2">{startIndex + index + 1}</td>
                <td className="border-b p-2 ">
                  <div className="flex items-center justify-start max-w-96">
                    <Avatar
                      size={35}
                      shape="circle"
                      src={item.image}
                      className="mr-2 bg-gray-200 text-black capitalize"
                    >
                      {item?.name[0]}
                    </Avatar>
                    {item.name}
                  </div>
                </td>
                <td className="border-b p-2">{item.description || "--"}</td>
                <td className="border-b p-2">{item.productCount || "--"}</td>
                <td className="border-b p-2">
                  <Switch
                    checked={item.status === "active"}
                    onChange={() => toggleStatus(item)}
                    className={`${
                      item.status === "active"
                        ? "!bg-[#009A00]"
                        : "!bg-gray-300"
                    }`}
                  />
                </td>
                <td className="border-b p-2 text-center">
                  <MdOutlineEdit
                    onClick={() => setUpdateCategory(item)}
                    className="text-[#009A00] cursor-pointer"
                    size={18}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center py-4">
                No subcategories found 
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {categories?.length > 0 && (
        <div className="pagination-container mt-3 flex justify-end">
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={categories.length}
            onChange={handleChangePage}
          />
        </div>
      )}
    </div>
  );
};

export default SellerSubCategoryList;
