import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Select,
  Form,
  Button,
  message,
  Space,
  Upload,
  Row,
  Col,
} from "antd";
import AxiosComponent from "../../util/axiosInstance";
import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { type } from "@testing-library/user-event/dist/type";
import { handleProfileImage } from "../../containers/fileupload/fileupload";
import useLogout from "../../util/authUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { useDropzone } from "react-dropzone";
import { handleFileUpload } from "../../containers/fileupload/fileUploadNew";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GrClear } from "react-icons/gr";

const { TextArea } = Input;

const AddProduct = (
  {
    // fetchProducts,
    // setLoading,
    // setError,
    // skuEditData,
    // setSkuEditData,
    // addSkuData,
    // setAddSkuData,
    // setProductId,
    // productId,
  }
) => {
  const [form] = Form.useForm();
  const token = localStorage.getItem("Mr_Sasta");
  const [packetsCount, setPacketsCount] = useState("");
  const [kgValue, setKgValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [quantityValue, setQuantityValue] = useState("");
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [quantityType, setQuantityType] = useState("grm");
  const [priceValue, setPriceValue] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [maxLimit, setMaxLimit] = useState("");
  const [skuId, setSkuId] = useState("");
  const [isPacketsInputDisabled, setPacketsInputDisabled] = useState(false);
  const [isKgInputDisabled, setKgInputDisabled] = useState(false);
  const [fairFlatDisabled, setFairFlatDisabled] = useState(false);
  const [fairPercentDisabled, setFairPercentDisabled] = useState(false);
  const [fairFlatDiscount, setFairFlatDiscount] = useState("");
  const [fairPercentDiscount, setFairPercentDiscount] = useState("");
  const [instantFlatDisabled, setInstantFlatDisabled] = useState(false);
  const [instantPercentDisabled, setInstantPercentDisabled] = useState(false);
  const [instantFlatDiscount, setInstantFlatDiscount] = useState("");
  const [instantPercentDiscount, setInstantPercentDiscount] = useState("");
  const [productName, setProductName] = useState("");
  const [visibleInputs, setVisibleInputs] = useState(true);
  const [imageFile, setImageFile] = useState({});
  const [taxPercent, setTaxPercent] = useState(null);
  const [addSkuProductId, setAddSkuProductId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const skuListRef = useRef(skuList);
  const [file, setFile] = useState(null);
  const logout = useLogout();
  const navigate = useNavigate();

  const location = useLocation();
  const productId1 = location?.state?.productId || "";
  const skuEditData1 = location?.state?.skuEditData || {};
  const addSkuData1 = location?.state?.addSkuData || [];
  const skuData = location?.state?.skuData || [];
  console.log(skuData);

  const [productId, setProductId] = useState(productId1 || "");
  const [skuEditData, setSkuEditData] = useState(skuEditData1 || {});
  const [addSkuData, setAddSkuData] = useState(addSkuData1 || []);
  console.log(productId);

  console.log(skuEditData);

  useEffect(() => {
    skuListRef.current = skuList;
    console.log("Calling useEffect for skuList");
  }, [skuList]);

  console.log(skuListRef);

  const options = [
    {
      value: "grm",
      label: "Gram",
    },
    {
      value: "kg",
      label: "Kg",
    },
    {
      value: "lit",
      label: "Litre",
    },
    {
      value: "ml",
      label: "Milli Litre",
    },
  ];

  const handleSelectChange = (value) => {
    console.log(value);
    setQuantityType(value);
  };

  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesEndpoint = "getAlladminCategories";
      try {
        setLoading(true);
        const response = await axiosInstance.get(categoriesEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        if (data.responseCode === 200) {
          setCategories(data.categories);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, [token]);

  const fetchSubCategories = async (selectedSubCategory) => {
    try {
      const subcategoriesEndpoint = "/getAdminSubCategories";
      const response = await axiosInstance.post(
        subcategoriesEndpoint,
        { categoryId: selectedSubCategory },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.responseCode === 200) {
        // Access subcategories from response.data.data
        setSubcategories(response.data.data || []);
      } else if (response.data.responseCode === 400) {
        setError("Invalid request. Please try again.");
        setSubcategories([]); // Ensure subcategories is reset to an empty array
      }
    } catch (err) {
      setError(err.message);
      setSubcategories([]); // Ensure subcategories is reset to an empty array on error
    }
  };

  const handleCategorySelect = async (value) => {
    setSelectedCategory(value);
    form.setFieldsValue({ category: value });
    setSubcategories([]); // Reset subcategories when a new category is selected
    setSelectedSubCategory("");
    form.resetFields(["subcategory"]);

    if (value) {
      try {
        const subcategoriesEndpoint = "/getAdminSubCategories";
        const response = await axiosInstance.post(
          subcategoriesEndpoint,
          { categoryId: value },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseCode === 200) {
          // Access subcategories from response.data.data
          setSubcategories(response.data.data || []);
        } else if (response.data.responseCode === 400) {
          setError("Invalid request. Please try again.");
          setSubcategories([]); // Ensure subcategories is reset to an empty array
        }
      } catch (err) {
        setError(err.message);
        setSubcategories([]); // Ensure subcategories is reset to an empty array on error
      }
    }
  };

  const handleSubCategorySelect = async (value) => {
    if (value) {
      setSelectedSubCategory(value);
      form.setFieldsValue({ subcategory: value });
    } else {
      setSelectedSubCategory("");
      form.resetFields(["subcategory"]);
    }
  };

  console.log(skuEditData);
  useEffect(() => {
    if (Object.keys(skuEditData).length > 0) {
      form.resetFields();
      setSkuList([]);
      setCategoryImage("");
      setPacketsCount("");
      setPriceValue("");
      setQuantityType("grm");
      setQuantityValue("");
      setAddSkuData([]);
      setProductId("");
      // setPacketsCount(skuEditData?.packets);
      setQuantityType(skuEditData?.unit);
      setQuantityValue(skuEditData?.quantityValue);
      setPriceValue(skuEditData?.instantPrice);
      setBasePrice(skuEditData?.fairPrice);
      fetchProductById(skuEditData?.productId);
      setSkuId(skuEditData?._id);
      setFairFlatDiscount(skuEditData?.discount?.fairPriceDiscount?.flat || 0);
      setMaxLimit(skuEditData?.maxLimitPurchase || "");
      setKgValue(skuEditData?.totalQuantity);
      setFairPercentDiscount(
        skuEditData?.discount?.fairPriceDiscount?.percentage || 0
      );
      setInstantFlatDiscount(
        skuEditData?.discount?.instantPriceDiscount?.flat || 0
      );
      setInstantPercentDiscount(
        skuEditData?.discount?.instantPriceDiscount?.percentage || 0
      );
      setProductName(skuEditData?.productName);
      setTaxPercent(skuEditData?.gstpercentage?.fairPriceGST || 0);
    }
  }, [skuEditData]);

  useEffect(() => {
    if (addSkuData?.length > 0) {
      console.log(addSkuData);
      form.resetFields();
      setSkuList([]);
      setCategoryImage("");
      // setFile(null);
      setPacketsCount("");
      setPriceValue("");
      setQuantityType("grm");
      setQuantityValue("");
      setSkuEditData({});
      setSkuId("");
      setVisibleInputs(true);
      setProductId("");
      const id = addSkuData?.length > 0 && addSkuData[0]?.productId;
      const list = addSkuData?.length > 0 && addSkuData;
      setAddSkuProductId(id);
      setSkuList(list);
      fetchProductById(id);
      setProductName(addSkuData?.length > 0 && addSkuData[0]?.productName);
    }
  }, [addSkuData]);

  useEffect(() => {
    if (productId) {
      form.resetFields();
      setSkuList([]);
      // setCategoryImage("");
      // setFile(null);
      setPacketsCount("");
      setPriceValue("");
      setQuantityType("grm");
      setQuantityValue("");
      // setAddSkuData([]);
      // setSkuEditData({});
      setSkuId("");
      setVisibleInputs(true);
      fetchProductById(productId);
    }
  }, [productId]);

  const fetchProductById = async (id) => {
    console.log(id);
    const Endpoint = "getAdminProductById";
    try {
      setLoading(true);
      const userData = {
        productId: id,
      };
      const response = await axiosInstance.post(Endpoint, userData);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        if (data.responseCode === 200) {
          console.log(data);
          // fetchSubCategories(data?.product?.categoryId);
          form.setFieldsValue({
            category: data?.product?.categoryId,
            description: data?.product?.description,
            name: data?.product?.productName,
            subcategory: data?.product?.subCategoryName,
          });
          setSelectedCategory(data?.product?.categoryId);
          setSelectedSubCategory(data?.product?.subCategoryId);
          setCategoryImage(
            data?.product?.productImage ? data?.product?.productImage : ""
          );
          setSkuList(data?.skus);
        } else if (data.responseCode === 400) {
          console.log(data);
          console.log(data.message);
        } else if (data.responseCode === 401) {
          message.error(data?.message);
          logout();
        }
      } else {
        const errorData = await response.json();
        console.error("Error posting data:", errorData);
        throw { error: errorData, loading: false };
      }
    } catch (error) {
      console.log("Product By Id fetched failed:", error);
      message.error(error?.error?.error?.message);
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(productId);

  const onFinish = async (values) => {
    console.log(values);
    if (productId) {
      try {
        const Endpoint = "/editAdminProduct";
        setLoading(true);
        const userData = {
          productId: productId,
          productName: values.name,
          subCategoryId: selectedSubCategory,
          productImage: categoryImage,
          description: values.description,
          categoryId: values.category,
        };
        if (categoryImage) {
          userData.productImage = categoryImage;
        }
        console.log(userData);

        const response = await axiosInstance.put(Endpoint, userData);
        if (response.status === 200) {
          const data = response.data;
          console.log("Product Updated successful:", data);
          if (data?.responseCode === 200) {
            console.log(data.message);
            message.success(data?.message);
            form.resetFields();
            setCategoryImage("");
            // fetchProducts();
            navigate("/products");
            setProductId("");
            setSkuList([]);
            setSelectedCategory("");
            setSelectedSubCategory("");
          } else if (data?.responseCode === 400) {
            message.error(data?.message);
            console.log(data?.message);
          } else if (data.responseCode === 401) {
            message.error(data?.message);
            logout();
          }
        }
      } catch (error) {
        console.log("Product Updated failed:", error);
        message.error(error?.error?.error?.message);
        setLoading(false);
        setError(error);
      } finally {
        setLoading(false);
      }
    } else if (Object.keys(skuEditData).length > 0) {
      console.log(
        maxLimit,
        quantityValue,
        quantityType,
        priceValue,
        basePrice,
        kgValue
      );

      if (
        maxLimit &&
        quantityValue &&
        priceValue &&
        basePrice &&
        kgValue &&
        // fairFlatDiscount &&
        // fairPercentDiscount &&
        // productName &&
        quantityType
      ) {
        const maxLimitPurchase = parseFloat(maxLimit);
        const instantPrice = parseFloat(priceValue);
        const fairPrice = parseFloat(basePrice);
        const totalQuantity = parseFloat(kgValue);
        const fairFlat = parseFloat(fairFlatDiscount || 0);
        const fairPercentage = parseFloat(fairPercentDiscount || 0);
        const instantFlat = parseFloat(instantFlatDiscount || 0);
        const instantPercentage = parseFloat(instantPercentDiscount || 0);
        const quantity = parseInt(quantityValue, 10);
        try {
          const Endpoint = "editAdminSKU";
          setLoading(true);
          const userData = {
            skuId: skuId,
            productName: productName,
            // unit: quantityType,
            // quantityValue: quantity,
            fairPrice: fairPrice,
            instantPrice: instantPrice,
            // totalQuantity: totalQuantity,
            maxLimitPurchase: maxLimitPurchase,
            discount: {
              fairPriceDiscount: {
                flat: fairFlat || 0,
                percentage: fairPercentage || 0,
              },
              instantPriceDiscount: {
                flat: instantFlat || 0,
                percentage: instantPercentage || 0,
              },
            },
            gstpercentage: {
              fairPriceGST: taxPercent || 0,
              instantPriceGST: taxPercent || 0,
            },
          };
          console.log(userData);

          const response = await axiosInstance.put(Endpoint, userData);
          console.log("Sku Updated successful:", response);
          if (response.status === 200) {
            const data = response.data;
            console.log(data);
            if (data?.responseCode === 200) {
              console.log(data.message);
              message.success(data?.message);
              form.resetFields();
              // fetchProducts();
              navigate("/products");
              setSkuEditData({});
              setProductId("");
              setQuantityValue("");
              setPriceValue("");
              setBasePrice("");
              setKgValue("");
              // setSkuProductId("");
              setPacketsCount("");
              setQuantityType("grm");
              setCategoryImage("");
            } else if (data?.responseCode === 400) {
              message.warning(data?.message);
              console.log(data?.message);
            } else if (data.responseCode === 401) {
              message.error(data?.message);
              logout();
            }
          }
        } catch (error) {
          console.log("Sku Updated failed:", error);
          message.error(error?.error?.error?.message);
          setLoading(false);
          setError(error);
        } finally {
          setLoading(false);
        }
      } else {
        message.warning("Please enter the Price and packets inputs");
      }
    } else if (addSkuData?.length > 0) {
      if (
        maxLimit &&
        quantityValue &&
        priceValue &&
        basePrice &&
        kgValue &&
        quantityType
      ) {
        const maxLimitPurchase = parseFloat(maxLimit);
        const instantPrice = parseFloat(priceValue);
        const fairPrice = parseFloat(basePrice);
        const totalQuantity = parseFloat(kgValue);
        const fairFlat = parseFloat(fairFlatDiscount || 0);
        const fairPercentage = parseFloat(fairPercentDiscount || 0);
        const instantFlat = parseFloat(instantFlatDiscount || 0);
        const instantPercentage = parseFloat(instantPercentDiscount || 0);
        const quantity = parseInt(quantityValue, 10);
        const newSku = {
          // productName: productName,
          unit: quantityType,
          quantityValue: quantity,
          instantPrice,
          fairPrice,
          discount: {
            fairPriceDiscount: {
              flat: fairFlat || 0,
              percentage: fairPercentage || 0,
            },
            instantPriceDiscount: {
              flat: instantFlat || 0,
              percentage: instantPercentage || 0,
            },
          },
          gstpercentage: {
            fairPriceGST: taxPercent || 0,
            instantPriceGST: taxPercent || 0,
          },
          totalQuantity,
          maxLimitPurchase,
        };
        console.log(newSku);
        setSkuList([...skuList, newSku]);
        skuListRef.current = [...skuListRef.current, newSku];
        setQuantityValue("");
        setPriceValue("");
        setPacketsCount("");
        setBasePrice("");
        setQuantityType("grm");
        setKgValue("");
        setKgInputDisabled(false);
        setPacketsInputDisabled(false);
        setFairFlatDisabled(false);
        setFairPercentDisabled(false);
        setFairPercentDiscount("");
        setFairFlatDiscount("");
        setInstantFlatDisabled(false);
        setInstantPercentDisabled(false);
        setInstantPercentDiscount("");
        setInstantFlatDiscount("");
        setTaxPercent(null);
        setMaxLimit("");
      }
      console.log(skuList);
      if (skuListRef?.current?.length > 0) {
        console.log(skuListRef?.current);
        const filteredSkus = skuListRef?.current?.filter(
          (sku) => !sku.hasOwnProperty("productId")
        );
        console.log(filteredSkus);
        try {
          const Endpoint = "addAdminSku";
          setLoading(true);
          const userData = {
            productId: addSkuProductId,
            // productName: productName,
            skus: filteredSkus,
          };
          const response = await axiosInstance.post(Endpoint, userData);
          console.log("Product Sku  Added successful:", response);
          if (response.status === 200) {
            const data = response.data;
            if (data.responseCode === 200) {
              console.log(data.message);
              message.success(data?.message);
              form.resetFields();
              setSkuList([]);
              skuListRef.current = [];
              setAddSkuData([]);
              // setSkuProductId("");
              setAddSkuProductId("");
              setVisibleInputs(true);
              setCategoryImage("");
              // fetchProducts();
              navigate("/products");
            } else if (data.responseCode === 400) {
              console.log(data);
              message.error(data.message);
            } else if (data.responseCode === 401) {
              message.error(data?.message);
              logout();
            }
          }
        } catch (error) {
          console.log("Product Sku Added failed:", error);
          message.error(error?.error?.error?.message);
          setLoading(false);
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      if (
        maxLimit &&
        quantityValue &&
        priceValue &&
        basePrice &&
        kgValue &&
        // fairFlatDiscount &&
        // fairPercentDiscount &&
        productName &&
        quantityType
      ) {
        const maxLimitPurchase = parseFloat(maxLimit);
        const instantPrice = parseFloat(priceValue);
        const fairPrice = parseFloat(basePrice);
        const totalQuantity = parseFloat(kgValue);
        const fairFlat = parseFloat(fairFlatDiscount || 0);
        const fairPercentage = parseFloat(fairPercentDiscount || 0);
        const instantFlat = parseFloat(instantFlatDiscount || 0);
        const instantPercentage = parseFloat(instantPercentDiscount || 0);
        const quantity = parseInt(quantityValue, 10);
        const newSku = {
          productName: productName,
          unit: quantityType,
          quantityValue: quantity,
          instantPrice,
          fairPrice,
          discount: {
            fairPriceDiscount: {
              flat: fairFlat || 0,
              percentage: fairPercentage || 0,
            },
            instantPriceDiscount: {
              flat: instantFlat || 0,
              percentage: instantPercentage || 0,
            },
          },
          gstpercentage: {
            fairPriceGST: taxPercent || 0,
            instantPriceGST: taxPercent || 0,
          },
          totalQuantity,
          maxLimitPurchase,
        };
        console.log(newSku);
        setSkuList([...skuList, newSku]);
        skuListRef.current = [...skuListRef.current, newSku];
        setQuantityValue("");
        setPriceValue("");
        setPacketsCount("");
        setBasePrice("");
        setQuantityType("grm");
        setKgValue("");
        setKgInputDisabled(false);
        setPacketsInputDisabled(false);
        setFairFlatDisabled(false);
        setFairPercentDisabled(false);
        setFairPercentDiscount("");
        setFairFlatDiscount("");
        setInstantFlatDisabled(false);
        setInstantPercentDisabled(false);
        setInstantPercentDiscount("");
        setInstantFlatDiscount("");
        setTaxPercent(null);
        setMaxLimit("");
        setVisibleInputs(!visibleInputs);
      }
      if (skuListRef.current?.length > 0) {
        const userData = {
          productName: values.name,
          description: values.description,
          categoryId: values.category,
          productStream: "online",
          subCategoryId: values.subcategory,
          skus: skuListRef.current,
        };
        if (categoryImage) {
          userData.productImage = categoryImage;
        }
        const Endpoint = "addAdminsProduct";
        setLoading(true);
        try {
          const response = await axiosInstance.post(Endpoint, userData);
          console.log(response);
          if (response.status === 200) {
            const data = response.data;
            console.log(data);
            if (data?.responseCode === 200) {
              message.success(data.message);
              // fetchProducts();
              navigate("/products");
              form.resetFields();
              setCategoryImage(null);
              setSkuList([]);
              skuListRef.current = [];
              setVisibleInputs(true);
            } else if (data?.responseCode === 400) {
              message.error(data.message);
            }
          } else {
            const errorData = await response.data;
            console.error("Error posting data:", errorData);
            throw { error: errorData, loading: false };
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles, rejectedFiles) => {
      // Manually filter file types to allow only .jpg, .png, and .jpeg
      const validExtensions = [".png", ".jpg", ".jpeg"];

      acceptedFiles.forEach(async (file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        // Validate file extension
        if (!validExtensions.includes(fileExtension)) {
          message.error(
            "Unsupported file type. Please upload only .jpg, .png, or .jpeg files."
          );
          return;
        }

        // Check file size (must be <= 5MB)
        // if (file.size > 5242880) {
        //   message.error("File size exceeds the maximum limit of 5MB.");
        //   return;
        // }

        // If file is valid, store the latest file and preview it
        const preview = URL.createObjectURL(file);
        setFile(
          Object.assign(file, {
            preview: preview,
          })
        );

        try {
          // Handle file upload and get the returned URL
          const uploadedFileUrl = await handleFileUpload(file);

          // Set the uploaded file URL as category image
          console.log(uploadedFileUrl);

          setCategoryImage(uploadedFileUrl);
        } catch (error) {
          message.error("File upload failed. Please try again.");
        }
      });
    },
    maxFiles: 1, // Allow only one file at a time
  });

  // const handleAadharImage = (event) => {
  //   const file = event.target.files[0];
  //   const allowedTypes = ["image/png", "image/jpeg"];

  //   if (!allowedTypes.includes(file.type)) {
  //     message.error("Only PNG and JPG images are allowed.");

  //     return;
  //   }

  //   if (file.size > 5 * 1024 * 1024) {
  //     message.error("Image is greater than 5MB. The maximum limit is 5MB.");
  //     return;
  //   }

  //   console.log(event, event.target.files);
  //   if (file) {
  //     const imageUrl = URL.createObjectURL(file);
  //     console.log(imageUrl);
  //     setImageFile(file);
  //     // setCategoryImage(imageUrl);
  //     handleProfileImage(event)
  //       .then((imageUrl) => {
  //         console.log(imageUrl);
  //         setCategoryImage(imageUrl);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         message.error(error);
  //         setLoading(false);
  //       });
  //   }
  // };

  const handleAddSku = () => {
    console.log(
      maxLimit,
      quantityValue,
      priceValue,
      basePrice,
      kgValue,
      fairFlatDiscount,
      quantityType
    );
    if (
      maxLimit &&
      quantityValue &&
      priceValue &&
      basePrice &&
      kgValue &&
      // fairFlatDiscount &&
      // fairPercentDiscount &&
      // productName &&
      quantityType
    ) {
      const maxLimitPurchase = parseFloat(maxLimit);
      const instantPrice = parseFloat(priceValue);
      const fairPrice = parseFloat(basePrice);
      const totalQuantity = parseFloat(kgValue);
      const fairFlat = parseFloat(fairFlatDiscount || 0);
      const fairPercentage = parseFloat(fairPercentDiscount || 0);
      const instantFlat = parseFloat(instantFlatDiscount || 0);
      const instantPercentage = parseFloat(instantPercentDiscount || 0);
      const quantity = parseInt(quantityValue, 10);
      const newSku = {
        // productName: productName,
        unit: quantityType,
        quantityValue: quantity,
        instantPrice,
        fairPrice,
        discount: {
          fairPriceDiscount: {
            flat: fairFlat || 0,
            percentage: fairPercentage || 0,
          },
          instantPriceDiscount: {
            flat: instantFlat || 0,
            percentage: instantPercentage || 0,
          },
        },
        gstpercentage: {
          fairPriceGST: taxPercent || 0,
          instantPriceGST: taxPercent || 0,
        },
        totalQuantity,
        maxLimitPurchase,
      };
      console.log(newSku);
      setSkuList([...skuList, newSku]);
      skuListRef.current = [...skuListRef.current, newSku];
      setQuantityValue("");
      setPriceValue("");
      setPacketsCount("");
      setBasePrice("");
      setQuantityType("grm");
      setKgValue("");
      setKgInputDisabled(false);
      setPacketsInputDisabled(false);
      setFairFlatDisabled(false);
      setFairPercentDisabled(false);
      setFairPercentDiscount("");
      setFairFlatDiscount("");
      setInstantFlatDisabled(false);
      setInstantPercentDisabled(false);
      setInstantPercentDiscount("");
      setInstantFlatDiscount("");
      setTaxPercent(null);
      setMaxLimit("");
      // setVisibleInputs(!visibleInputs);
    } else {
      // setVisibleInputs(!visibleInputs);
      message.error("Enter all Sku details");
    }
  };

  const handleNumericInput = (e) => {
    const charCode = e.charCode;
    const inputValue = e.target.value;
    if (
      (charCode >= 48 && charCode <= 57) ||
      (charCode === 46 && inputValue.indexOf(".") === -1)
    ) {
      return;
    } else {
      e.preventDefault();
      message.warning("Only numbers are allowed");
    }
  };

  const handleKgChange = (e) => {
    const value = e.target.value;
    setKgValue(value);
  };

  console.log(skuList);

  const handleFairFlatDiscountChange = (e) => {
    let value = e.target.value;
    if (parseFloat(value) > basePrice) {
      message.error(
        `Flat discount cannot exceed base price of Rs ${basePrice}`
      );
      value = basePrice.toString();
    }

    setFairFlatDiscount(value);
    setFairPercentDisabled(value !== "");

    if (value === "") {
      setFairPercentDiscount("");
      setFairPercentDisabled(false);
    } else {
      const flat = parseFloat(value);
      const calculatedPercent = ((flat / basePrice) * 100).toFixed(2);
      setFairPercentDiscount(calculatedPercent);
    }
  };

  const handleFairPercentDiscountChange = (e) => {
    let value = e.target.value;
    if (parseFloat(value) > 100) {
      message.error("Percentage discount cannot exceed 100%");
      value = "100";
    }

    setFairPercentDiscount(value);
    setFairFlatDisabled(value !== "");

    if (value === "") {
      setFairFlatDiscount("");
      setFairFlatDisabled(false);
    } else {
      const percent = parseFloat(value);
      const calculatedFlat = ((percent / 100) * basePrice).toFixed(2);
      setFairFlatDiscount(calculatedFlat);
    }
  };

  const handleInstantFlatDiscountChange = (e) => {
    let value = e.target.value;
    if (parseFloat(value) > priceValue) {
      message.error(
        `Flat discount cannot exceed base price of Rs ${priceValue}`
      );
      value = priceValue.toString();
    }

    setInstantFlatDiscount(value);
    setInstantPercentDisabled(value !== "");

    if (value === "") {
      setInstantPercentDiscount("");
      setInstantPercentDisabled(false);
    } else {
      const flat = parseFloat(value);
      const calculatedPercent = ((flat / priceValue) * 100).toFixed(2);
      setInstantPercentDiscount(calculatedPercent);
    }
  };

  const handleInstantPercentDiscountChange = (e) => {
    let value = e.target.value;
    if (parseFloat(value) > 100) {
      message.error("Percentage discount cannot exceed 100%");
      value = "100";
    }

    setInstantPercentDiscount(value);
    setInstantFlatDisabled(value !== "");

    if (value === "") {
      setInstantFlatDiscount("");
      setInstantFlatDisabled(false);
    } else {
      const percent = parseFloat(value);
      const calculatedFlat = ((percent / 100) * priceValue).toFixed(2);
      setInstantFlatDiscount(calculatedFlat);
    }
  };

  const handleClear = () => {
    form.resetFields();
    setSkuList([]);
    setCategoryImage("");
    setPacketsCount("");
    setPriceValue("");
    setQuantityType("grm");
    setQuantityValue("");
    setAddSkuData([]);
    setSkuEditData({});
    setBasePrice("");
    setPacketsInputDisabled(false);
    setKgValue("");
    setKgInputDisabled(false);
    setSkuId("");
    setVisibleInputs(true);
    setProductId("");
  };

  console.log(skuList);

  let productWarningShown = false; // Flag to track if the warning has been shown

  const validateProduct = (_, value) => {
    // Check if the product name starts with a letter
    if (value && !/^[a-zA-Z]/.test(value)) {
      if (!productWarningShown) {
        message.warning("Product must start with a letter");
        productWarningShown = true; // Set flag after showing the warning
      }
      return Promise.reject(new Error("Product must start with a letter"));
    }

    productWarningShown = false;

    if (value && !/^[a-zA-Z][a-zA-Z0-9\s]*$/.test(value)) {
      if (!productWarningShown) {
        message.warning(
          "Product must contain only letters, numbers, and spaces"
        );
        productWarningShown = true; // Set flag after showing the warning
      }
      return Promise.reject(
        new Error("Product must contain only letters, numbers, and spaces")
      );
    }

    // Reset the warning flag if input becomes valid
    productWarningShown = false;

    // Check if the length is less than 5 characters
    if (value && value.length < 2) {
      return Promise.reject(new Error("Product must be at least 2 characters"));
    }

    // Check if the length is more than 16 characters
    if (value && value.length > 16) {
      return Promise.reject(new Error("Product must be at most 16 characters"));
    }

    return Promise.resolve();
  };
  const handleProductChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 17);

    // Prevent product name from starting with a number or space
    if (/^[0-9\s]/.test(cleanedValue)) {
      form.setFieldsValue({ name: cleanedValue.slice(1) });
      setProductName(cleanedValue.slice(1));
    } else {
      form.setFieldsValue({ name: cleanedValue });
      setProductName(cleanedValue);
    }
  };

  return (
    <div className='w-full flex min-h-[40vh] max-h-[100vh] m-0 p-0'>
      <Form
        form={form}
        name='addProduct'
        onFinish={onFinish}
        layout='vertical'
        autoComplete='off'
        className='flex w-[96%] mx-auto add-product'>
        <div className='w-[38%]'>
          <div className='w-[96%] text-start '>
            <div className=' bg-white overflow-y-scroll scrollbar-hide'>
              <div className='flex items-center justify-between mb-1'>
                <button
                  className='border border-[#009A00] bg-white px-3 py-0.5 rounded text-[#009A00] cursor-pointer flex items-center gap-1'
                  onClick={() => navigate("/products")}>
                  <FaArrowLeftLong
                    style={{ fontSize: "14px", cursor: "pointer" }}
                  />
                  <p style={{ fontSize: "14px" }}>Back</p>
                </button>
                <button
                  class='border border-[#009A00] bg-white px-3 py-0.5 rounded text-[#009A00] cursor-pointer flex items-center gap-1'
                  onClick={handleClear}>
                  <GrClear style={{ fontSize: "14px", cursor: "pointer" }} />
                  <p style={{ fontSize: "14px" }}>Clear</p>
                </button>
              </div>
              <Row gutter={1}>
                <Col sm={12} xl={12}>
                  <Form.Item
                    name='category'
                    label='Select Category'
                    rules={[
                      {
                        required: true,
                        message: "Please select Category",
                      },
                    ]}>
                    <Select
                      placeholder='Select Category'
                      allowClear
                      showSearch
                      disabled={
                        Object.keys(skuEditData).length > 0 ||
                        addSkuData?.length > 0 ||
                        productId
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handleCategorySelect}
                      value={selectedCategory}>
                      {categories
                        ?.filter((item) => item.status === "active")
                        .map((item) => (
                          <Select.Option
                            key={item.categoryId}
                            value={item.categoryId}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={12} xl={12}>
                  <Form.Item
                    name='subcategory'
                    label='Select Sub Category'
                    rules={[
                      {
                        required: true,
                        message: "Please select SubCategory",
                      },
                    ]}>
                    <Select
                      placeholder='Select Sub Category'
                      allowClear
                      showSearch
                      disabled={
                        !selectedCategory ||
                        Object.keys(skuEditData).length > 0 ||
                        addSkuData?.length > 0 ||
                        productId
                      }
                      onChange={handleSubCategorySelect}
                      value={selectedSubCategory}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {subcategories.map((item) => (
                        <Select.Option key={item._id} value={item._id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name='name'
                label='Product Name'
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of the product!",
                  },
                  { validator: validateProduct },
                ]}>
                <Input
                  placeholder='Enter the Product Name'
                  // onChange={(e) => setProductName(e.target.value)}
                  onChange={handleProductChange}
                  type='text'
                  maxLength={32}
                  // onInput={(e) => {
                  //   // Prevent entering space at the start and remove numbers
                  //   e.target.value = e.target.value
                  //     .replace(/^\s+/, "")
                  //     .replace(/[0-9]/g, "");
                  // }}
                  disabled={
                    Object?.keys(skuEditData)?.length > 0 ||
                    addSkuData?.length > 0
                  }
                />
              </Form.Item>
              {productId ? (
                ""
              ) : (
                <>
                  {visibleInputs && (
                    <div className='p-2 border-2 border-dashed rounded'>
                      <div className='flex justify-between items-center mb-2'>
                        <span className='text-sm font-medium'>SKU's</span>
                        <div className='inline-block w-20 px-2 py-1 border border-gray-300 rounded text-center'>
                          Qty
                        </div>
                      </div>

                      <div className='flex space-x-2'>
                        <Space.Compact className='w-full mb-1'>
                          <Input
                            placeholder='Quantity'
                            value={quantityValue}
                            onKeyPress={handleNumericInput}
                            onChange={(e) => setQuantityValue(e.target.value)}
                            disabled={Object.keys(skuEditData)?.length > 0}
                          />
                          <Select
                            style={{ width: "140px" }}
                            defaultValue={quantityType}
                            options={options}
                            onChange={handleSelectChange}
                            disabled={Object.keys(skuEditData)?.length > 0}
                          />
                        </Space.Compact>
                      </div>
                      <Row gutter={6}>
                        <Col sm={12} xl={12}>
                          <Input
                            placeholder='Fair Price'
                            value={basePrice}
                            className='mb-1'
                            onKeyPress={handleNumericInput}
                            onChange={(e) => setBasePrice(e.target.value)}
                            suffix='Rs'
                          />
                        </Col>
                        <Col sm={12} xl={12}>
                          <Space.Compact className='w-full'>
                            <Input
                              placeholder='Flat Discount'
                              value={fairFlatDiscount}
                              onKeyPress={handleNumericInput}
                              suffix='Rs'
                              className='mb-1'
                              onChange={handleFairFlatDiscountChange}
                              disabled={fairFlatDisabled}
                            />
                            <Input
                              placeholder='Discount %'
                              value={fairPercentDiscount}
                              onKeyPress={handleNumericInput}
                              onChange={handleFairPercentDiscountChange}
                              suffix='%'
                              className='mb-1'
                              disabled={fairPercentDisabled}
                            />
                          </Space.Compact>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col sm={12} xl={12}>
                          <Input
                            placeholder='Instant Price'
                            value={priceValue}
                            onKeyPress={handleNumericInput}
                            onChange={(e) => setPriceValue(e.target.value)}
                            suffix='Rs'
                            className='mb-1'
                          />
                        </Col>
                        <Col sm={12} xl={12}>
                          <Space.Compact className='w-full'>
                            <Input
                              placeholder='Flat Discount'
                              value={instantFlatDiscount}
                              onKeyPress={handleNumericInput}
                              suffix='Rs'
                              className='mb-1'
                              onChange={handleInstantFlatDiscountChange}
                              disabled={instantFlatDisabled}
                            />
                            <Input
                              placeholder='Discount %'
                              value={instantPercentDiscount}
                              onKeyPress={handleNumericInput}
                              onChange={handleInstantPercentDiscountChange}
                              suffix='%'
                              className='mb-1'
                              disabled={instantPercentDisabled}
                            />
                          </Space.Compact>
                        </Col>
                      </Row>
                      <Input
                        placeholder='Enter Total Quantity'
                        value={kgValue}
                        onKeyPress={handleNumericInput}
                        onChange={handleKgChange}
                        className='mb-1'
                        suffix='Kgs'
                        disabled={
                          isKgInputDisabled ||
                          Object.keys(skuEditData).length > 0
                        }
                      />
                      <Row gutter={6}>
                        <Col sm={12} xl={12}>
                          <Select
                            value={taxPercent}
                            style={{ width: "100%" }}
                            className='mb-1'
                            size='medium'
                            placeholder='Select Tax %'
                            onChange={(value) => setTaxPercent(value)}>
                            <Option value={0}>0%</Option>
                            <Option value={5}>5%</Option>
                            <Option value={12}>12%</Option>
                            <Option value={18}>18%</Option>
                          </Select>
                        </Col>

                        <Col sm={12} xl={12}>
                          <Input
                            onKeyPress={handleNumericInput}
                            value={maxLimit}
                            placeholder='Max Limit Purchase'
                            suffix='Kgs'
                            onChange={(e) => setMaxLimit(e.target.value)}
                            className='mb-1'
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {Object.keys(skuEditData)?.length === 0 && (
                    <div className='flex items-center text-sm text-black-100 mb-2 justify-between mt-3'>
                      <p>You can add multiple SKU's</p>
                      <div
                        className='add-button border text-black-100 border-[#7c7b84] py-0.5 px-1 rounded-md cursor-pointer'
                        onClick={handleAddSku}>
                        <p>
                          <PlusOutlined /> Add SKU
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* )} */}
              <Form.Item
                name='description'
                label='Description'
                style={{ marginTop: productId ? "0px" : "10px" }}>
                <TextArea
                  placeholder='Add product description'
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  maxLength={175}
                  showCount
                  disabled={
                    Object.keys(skuEditData)?.length > 0 ||
                    addSkuData?.length > 0
                  }
                  className='mb-2'
                />
              </Form.Item>

              {(skuEditData && Object.keys(skuEditData).length > 0) ||
              addSkuData?.length > 0 ? (
                ""
              ) : (
                <div {...getRootProps()} className='upload-container mb-3'>
                  <input {...getInputProps()} />
                  {file && categoryImage ? (
                    <div className='uploaded-image-section'>
                      <div className='fileUpload-image-div'>
                        <img
                          src={file.preview || categoryImage}
                          alt={file.name}
                        />
                      </div>
                      <ImCross
                        className='text-md font-light cursor-pointer'
                        onClick={() => {
                          setFile(null);
                          setCategoryImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <CloudUploadOutlined className='cloud-icon' />
                  )}
                  <p class='upload-text'>
                    Browse/drag to {file && categoryImage ? "change" : "upload"}{" "}
                    File
                  </p>
                </div>
              )}

              {/* {categoryImage ? (
                <div className='border-2 border-dashed border-gray-300 p-4 rounded-md text-center flex flex-row justify-center items-center  gap-2 mb-3'>
                  <div className='image-section h-[50px] w-auto'>
                    <img
                      src={categoryImage}
                      alt='category'
                      className='h-full w-full object-contain'
                    />
                  </div>
                  <p>
                    <label
                      htmlFor='fileInput'
                      className='!text-[#009A00] cursor-pointer'>
                      Change Image
                    </label>
                    <div className='document-attach'>
                      <input
                        type='file'
                        id='fileInput'
                        onChange={handleAadharImage}
                        style={{ display: "none" }}
                      />
                    </div>
                  </p>
                </div>
              ) : (
                <div className='border-2 border-dotted border-gray-300 p-4 rounded-md text-center flex-row justify-center mb-3'>
                  <div className='flex flex-col items-center'>
                    <img
                      src='/Assests/Images/image-gallery.png'
                      alt='category'
                      className='file-upload'
                    />
                  </div>
                  <p>
                    upload your files{" "}
                    <label
                      htmlFor='fileInput'
                      className='!text-[#009A00] cursor-pointer font-semibold'>
                      here
                    </label>
                    <div className='document-attach'>
                      <input
                        type='file'
                        id='fileInput'
                        onChange={handleAadharImage}
                        style={{ display: "none" }}
                      />
                    </div>
                  </p>
                  <p className='secondary-text'>Maximum size: 500kb</p>
                </div>
              )} */}

              {skuList?.length > 0 ? (
                ""
              ) : (
                <Form.Item>
                  <div className='flex items-center justify-center w-[90%] mx-auto'>
                    <button
                      type='primary'
                      htmlType='submit'
                      className='w-full mx-auto !bg-[#009A00] text-white hover:bg-[#009A00] font-medium cursor-pointer py-1.5 rounded-md'>
                      {productId ? (
                        <p>Update Product</p>
                      ) : skuEditData &&
                        Object.keys(skuEditData)?.length > 0 ? (
                        <p>Update SKU</p>
                      ) : addSkuData && addSkuData?.length > 0 ? (
                        <p>Add SKU</p>
                      ) : (
                        <p>Add Product</p>
                      )}
                    </button>
                  </div>
                </Form.Item>
              )}
            </div>
          </div>
        </div>
        <div className='border-r-2 border-text-bg-gray-300 m-0 min-h-[80vh]'></div>
        <div className='w-[60%] mx-auto'>
          {skuList?.length > 0 && (
            <div className='w-full overflow-x-auto mx-auto '>
              <table className='border-collapse w-full border border-[#d7d9da] rounded-[4px] mx-auto'>
                <thead>
                  <tr>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      S.No
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      Quantity
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      F.Price
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      I.Price
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      F.Discount(%)
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      I.Discount(%)
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      Total Quantity(kg)
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      GST(%)
                    </th>
                    <th className='text-center p-2 border-b border-[#d7d9da] text-black text-sm'>
                      MaxLimit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {skuList?.map((sku, index) => (
                    <tr key={index} className='border-b border-[#d7d9da]'>
                      <td className='text-center p-2 text-black text-sm'>
                        {index + 1}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku.quantityValue} {sku.unit}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku.fairPrice || "-"}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku.instantPrice}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku?.discount?.fairPriceDiscount?.percentage || 0}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku?.discount?.instantPriceDiscount?.percentage || 0}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku?.totalQuantity}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku?.gstpercentage?.fairPriceGST}
                      </td>
                      <td className='text-center p-2 text-black text-sm'>
                        {sku?.maxLimitPurchase}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {skuList?.length > 0 ? (
            <Form.Item>
              <div className='flex items-center justify-center w-[50%] mx-auto mt-4'>
                <button
                  type='primary'
                  htmlType='submit'
                  className='w-full !bg-[#009A00] text-white hover:bg-[#009A00] font-medium cursor-pointer py-1.5 rounded-md'>
                  {productId ? (
                    <p>Update Product</p>
                  ) : skuEditData && Object.keys(skuEditData)?.length > 0 ? (
                    <p>Update SKU</p>
                  ) : addSkuData && addSkuData?.length > 0 ? (
                    <p>Add SKU</p>
                  ) : (
                    <p>Add Product</p>
                  )}
                </button>
              </div>
            </Form.Item>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};
// AddProduct
export default AddProduct;
