import React, { useState } from 'react';
import { Spin, Form, Button, message } from 'antd';
import TextField from '@mui/material/TextField'; // Adjust based on your setup

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Simulate an API request
      await new Promise((resolve) => setTimeout(resolve, 2000));
      message.success('Email sent successfully!');
    } catch (error) {
      message.error('Failed to send email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-2xl w-fit max-w-5xl flex h-[75vh]">
          <div className="w-1/2 hidden lg:block">
            <img
              src="/Assests/Images/Login-image.png"
              alt="Login visual"
              className="h-full w-full object-cover rounded-l-md"
            />
          </div>

          <div className="w-96 p-4 flex flex-col items-center justify-center">
            <h2 className="text-2xl font-bold text-center mb-2 text-[#009A00]">
              Welcome to Mr.Sasta
            </h2>
            <p className="text-center mb-4">Please Enter Your Email</p>
            <Form
              form={form}
              name="forgotPassword"
              onFinish={onFinish}
              className="space-y-4 !w-80 gap-5"
            >
              <div className="mb-6">
                <Form.Item
                  name="email"
                  className="mt-3 text-left gap-4"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email address",
                    },

                  ]}
                >
                  <TextField
                    fullWidth
                    label="Email Address"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        fontSize: "14px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  type="submit"
                  variant="contained"
                  className="w-full"
                  style={{
                    backgroundColor: "#009A00",
                    color: "white",
                  }}

                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ForgotPassword;
