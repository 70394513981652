import React, { useState } from "react";
import { Avatar, Pagination, Switch, message } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import AxiosComponent from "../../util/axiosInstance";

const CategoryList = ({ categories, setCategories, setUpdateCategory }) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const toggleStatus = async (category) => {
    const Endpoint = "toggleAdminCatgaories";
    const status = category?.status;
  
    try {
      setLoading(true);
  
      const requestData = {
        categoryId: category?.categoryId,
      };
  
      const response = await axiosInstance.put(
        `${baseURL}/${Endpoint}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
          },
        }
      );
  
      const { responseCode, message: responseMessage } = response.data;
  
      if (responseCode === 200) {
        message.success(responseMessage);
  
        const updatedCategories = categories.map((c) => {
          if (c.categoryId === category.categoryId) {
            return {
              ...c,
              status: status === "active" ? "inactive" : "active",
            };
          }
          return c;
        });
  
        setCategories(updatedCategories);
      } else if (responseCode === 400) {
        message.error(responseMessage);
      } else {
        message.error(responseMessage);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = categories?.slice(startIndex, endIndex);

  return (
    <div className="p-1 bg-white !overflow-auto">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr>
            <th className="border-b p-2">S.No</th>
            <th className="border-b p-2 ">Category</th>
            <th className="border-b p-2">Description</th>
            <th className="border-b p-2">No of SubCategories</th>
            <th className="border-b p-2">Status</th>
            <th className="border-b p-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((item, index) => (
            <tr key={item.categoryId}>
              <td className="border-b p-2 items-center">
                {startIndex + index + 1}
              </td>
              <td className="border-b p-2 ">
                <div className="flex items-center justify-start max-w-96">
                  <Avatar
                    size={35}
                    shape="circle"
                    src={item.catgoryImage}
                    className={`mr-2 capitalize ${
                      item.categoryImage ? '' : 'bg-gray-200 text-black'
                    }`}
                  >
                    {item.name[0]}
                  </Avatar>
                  {item.name}
                </div>
              </td>
              <td className="border-b p-2">{item.description || "--"}</td>
              <td className="border-b p-2">{item.subcategoryCount || "--"}</td>
              <td className="border-b p-2">
                <Switch
                  checked={item.status === "active"}
                  onChange={() => toggleStatus(item)}
                  className={`${
                    item.status === "active" ? "!bg-[#009A00]" : "!bg-gray-300"
                  }`}
                />
              </td>
              <td className="border-b p-2 text-center items-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    onClick={() => setUpdateCategory(item)}
                    className="text-[#009A00] cursor-pointer flex justify-center "
                    size={18}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {paginatedData?.length === 0 && (
        <div className="no-data-message mt-3">
          <p>No data found</p>
        </div>
      )}
      <div className="Pagination-container mt-3 justify-end flex">
        {categories?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={categories?.length}
            showSizeChanger
            onShowSizeChange={handleChangePage}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryList;
