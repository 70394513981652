import React, { useState } from 'react';
import { Modal, Button, Input, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const ReviewsList = ({ reviewData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalData, setModalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (title, data) => {
    setModalTitle(title);
    setModalData(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filteredData = reviewData?.filter(
    (review) =>
      review.sellerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getReviewsByRating = (reviews, rating) => {
    return reviews.filter((review) => review.rating === rating);
  };

  const StarRatingButton = ({ count, seller, reviews }) => {
    const filteredReviews = getReviewsByRating(reviews, count);

    return (
      <Button
        type="default"
        onClick={() =>
          showModal(`${count}-Star Reviews for ${seller}`, filteredReviews)
        }
      >
        View 
      </Button>
    );
  };

  const columns = [
    {
      title: 'Product',
      dataIndex: 'tagName',
      key: 'tagName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
  ];

  return (
    <div className="p-6">
      <div className=" mt-0 p-4">
        <div className="mb-4 flex justify-between items-center">
          <Input
            placeholder="Search Seller"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-52 pl-2 pr-2 py-2 border-gray-300 focus:ring-[#009A00]"
            suffix={<SearchOutlined className="text-gray-400" />}
            style={{ width: '300px' }}
          />
        </div>

        <table className="min-w-full bg-white overflow-hidden">
          <thead>
            <tr className="text-black border-b text-center">
              <th className="p-4">Sno</th>
              <th className="p-4">Seller Name</th>
              <th className="p-4">Description</th>
              <th className="p-4">Rating</th>
              <th className="p-4">Review</th>
              <th className="p-4">1 Star</th>
              <th className="p-4">2 Star</th>
              <th className="p-4">3 Star</th>
              <th className="p-4">4 Star</th>
              <th className="p-4">5 Star</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((review, index) => (
              <tr key={index} className="text-center border-b border-gray-300">
                <td className="p-4">{index + 1}</td>
                <td className="p-4">{review.sellerName}</td>
                <td className="p-4">{review.sellerDescription || '--'}</td>
                <td className="p-4">{review.averageRating || '--'}</td>
                <td className="p-4">
                  <Button
                    onClick={() =>
                      showModal(
                        `All Reviews for ${review.sellerName}`,
                        review.reviewDescriptions
                      )
                    }
                  >
                    View All
                  </Button>
                </td>
                <td className="p-4">
                  <StarRatingButton
                    count={1}
                    seller={review.sellerName}
                    reviews={review.reviewDescriptions}
                  />
                </td>
                <td className="p-4">
                  <StarRatingButton
                    count={2}
                    seller={review.sellerName}
                    reviews={review.reviewDescriptions}
                  />
                </td>
                <td className="p-4">
                  <StarRatingButton
                    count={3}
                    seller={review.sellerName}
                    reviews={review.reviewDescriptions}
                  />
                </td>
                <td className="p-4">
                  <StarRatingButton
                    count={4}
                    seller={review.sellerName}
                    reviews={review.reviewDescriptions}
                  />
                </td>
                <td className="p-4">
                  <StarRatingButton
                    count={5}
                    seller={review.sellerName}
                    reviews={review.reviewDescriptions}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          title={modalTitle}
          open={isModalVisible}
          onCancel={handleCancel}
          fixed
          centered
          footer={null}
        >
         <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table
              dataSource={modalData}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.tagName} 
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ReviewsList;
