import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Spin, message, DatePicker } from "antd";
import moment from "moment";

const EditCustomer = ({ selectedCustomer, close, fetchCustomerData }) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState(
    selectedCustomer.image || ""
  );
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  useEffect(() => {
    // Initialize form fields with selectedCustomer data
    form.setFieldsValue({
      userName: selectedCustomer.userName,
      email: selectedCustomer.email,
      phone: selectedCustomer.phone,
      dob: selectedCustomer.dob ? moment(selectedCustomer.dob) : null,
    });
    setCategoryImage(selectedCustomer.image || "");
  }, [selectedCustomer, form]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file size exceeds 500KB (500 * 1024 bytes)
      if (file.size > 500 * 1024) {
        message.error("Image size should be less than 500KB.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setCategoryImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onFinish = async (values) => {
    const { userName, email, phone, dob } = values;
    const customerId = selectedCustomer._id;
    const location = selectedCustomer.location || {
      type: "Point",
      coordinates: [0, 0],
    };

    const customerData = {
      customerId,
      userName,
      email,
      phone,
      dob: dob ? dob.format("DD-MM-YYYY") : null,
      image: categoryImage,
      location: location,
      referralCode: selectedCustomer.referralCode || "REFNEW12345",
    };

    try {
      setLoading(true);
      const response = await fetch(`${baseURL}/editCustomer`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(customerData),
      });

      const data = await response.json();
      if (data.responseCode === 200) {
        message.success(data.message);
        setSuccessModal(true);
        fetchCustomerData();
        close();
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error(error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="EditCustomer-container">
        <Form
          form={form}
          name="edit_Customer"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="userName"
            label="Customer Name"
            rules={[
              {
                required: true,
                message: "Please enter customer name",
              },
              {
                max: 30,
                message: "Customer name cannot exceed 30 characters.",
              },
              {
                pattern: /^[A-Za-z ]+$/,
                message:
                  "Customer name can only contain letters, numbers, and spaces",
              },
              {
                validator: (_, value) => {
                  if (value && value.trim().startsWith(" ")) {
                    return Promise.reject(
                      new Error("Customer name cannot start with a space")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter Customer Name"
              maxLength={30}
              onInput={(e) => {
                let value = e.target.value;

                // Prevent leading spaces
                value = value.replace(/^\s+/, "");

                // Limit length to 30 characters
                value = value.slice(0, 30);

                // Remove special characters
                value = value.replace(/[^A-Za-z ]/g, "");

                e.target.value = value;
              }}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter phone number",
              },
              {
                pattern: /^[6-9]\d{9}$/,
                message:
                  "Phone number must start with 6, 7, 8, or 9 and contain exactly 10 digits",
              },
              {
                max: 10,
                message: "Phone number cannot exceed 10 digits",
              },
              {
                validator: (_, value) => {
                  // If the value exists and contains non-numeric characters
                  if (value && /\D/.test(value)) {
                    return Promise.reject(
                      new Error("Phone number can only contain numbers")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter Phone Number"
              maxLength={10}
              onInput={(e) => {
                let value = e.target.value;

                // Remove non-digit characters
                value = value.replace(/\D/g, "");

                // Update the input with the cleaned value
                e.target.value = value;
              }}
            />
          </Form.Item>

          <Form.Item name="dob" label="Date of Birth">
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              placeholder="Select Date of Birth"
              disabledDate={(current) => {
                return current && current >= moment().startOf("day");
              }}
            />
          </Form.Item>

          <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
            <div className="FileChange">
              <div className="image-section mb-2">
                {categoryImage ? (
                  <img
                    src={categoryImage}
                    alt="Customer"
                    className="mx-auto w-20 h-20"
                    // style={{ maxWidth: "100%", height: "auto" }}
                  />
                ) : (
                  <p></p>
                )}
              </div>
              <label
                htmlFor="fileInput"
                className="primary-text cursor-pointer text-blue-600"
              >
                Change Image
              </label>
              <div className="document-attach">
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          <Form.Item>
            <div className="flex justify-between">
              <button
                type="button"
                className="cancel-button text-[#009A00] border border-[#009A00] rounded-md p-2"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="submit-button bg-[#009A00] text-white py-2 rounded-md font-medium px-4"
              >
                Submit
              </button>
            </div>
          </Form.Item>
        </Form>
      </div>

      {/* Success Modal */}
      <Modal
        open={successModal}
        onCancel={() => setSuccessModal(false)}
        centered
        footer={null}
        className="custom-modal"
        destroyOnClose
      >
        <div className="Employ-success-container">
          <div className="Employ-success-content">
            <div>
              <img src="Assests/Images/success_image.png" alt="" />
            </div>
            <h5>
              Customer Details Updated <br /> Successfully
            </h5>
          </div>
        </div>
      </Modal>
    </Spin>
  );
};

export default EditCustomer;
