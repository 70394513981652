import React, { useState } from "react";
import { Avatar, Pagination, Switch, message } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import AxiosComponent from "../../../util/axiosInstance";

const SellerCategoryList = ({
  categories,
  setCategories,
  setUpdateCategory,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const toggleStatus = async (category) => {
    const Endpoint = "toggleCategory";
    const currentStatus = category?.status;

    try {
      setLoading(true);
      const requestData = {
        categoryId: category?.categoryId,
      };
      const response = await axiosInstance.put(
        `${baseURL}/${Endpoint}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
          },
        }
      );

      if (response.data.responseCode === 200) {
        message.success(response.data.message);

        const updatedCategories = categories.map((c) => {
          if (c.categoryId === category.categoryId) {
            return {
              ...c,
              status: response.data.category.status,
            };
          }
          return c;
        });

        setCategories(updatedCategories);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(
        error?.response?.data?.message || "Failed to toggle status."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = categories?.slice(startIndex, endIndex);

  return (
    <div className="p-1 bg-white !overflow-auto">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr className="border-b">
            <th className=" p-2">S.No</th>
            <th className=" p-2 ">Category</th>
            <th className=" p-2">Description</th>
            <th className=" p-2">No of Sub Categories</th>
            <th className=" p-2">Status</th>
            <th className=" p-2">Edit</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((item, index) => (
            <tr key={item._id} className="border-b">
              <td className=" p-2 items-center">{startIndex + index + 1}</td>
              <td className=" p-2 flex items-center justify-start w-40">
                <Avatar
                  size={35}
                  shape="circle"
                  src={item.image}
                  className="mr-2 bg-gray-200 text-black capitalize"
                >
                  {item.categoryName?.[0] || ""}
                </Avatar>
                {item.categoryName}
              </td>
              <td className=" p-2 w-96">{item.description || "--"}</td>
              <td className=" p-2">{item.productCount || "--"}</td>
              <td className=" p-2">
                <Switch
                  checked={item.status === "active"}
                  onChange={() => toggleStatus(item)}
                  className={`${
                    item.status === "active" ? "!bg-[#009A00]" : "!bg-gray-300"
                  }`}
                />
              </td>
              <td className=" p-2 text-center items-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    onClick={() => setUpdateCategory(item)}
                    className="text-[#009A00] cursor-pointer flex justify-center "
                    size={18}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {paginatedData?.length === 0 && (
        <div className="no-data-message mt-3">
          <p>No data found</p>
        </div>
      )}
      <div className="Pagination-container mt-3 justify-end flex">
        {categories?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={categories?.length}
            onChange={handleChangePage}
            showSizeChanger
            onShowSizeChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default SellerCategoryList;
