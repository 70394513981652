// EditRole.jsx
import React, { useState, useEffect } from "react";
import { Form, Input, Checkbox, Col, Row, message, Button } from "antd";
import AxiosComponent from "../../../util/axiosInstance";

const EditRole = ({ close, roleData, featuresData, updateRolesData ,fetchRoles }) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const [form] = Form.useForm();
  const [roleName, setRoleName] = useState("");
  const { axiosInstance } = AxiosComponent();
  const [checkedActivities, setCheckedActivities] = useState([]);

  useEffect(() => {
    if (roleData) {
      setRoleName(roleData.roleName);
      // Assuming roleData.features is an array of feature objects with featureId or _id
      const featureIds = roleData.features.map((feature) => feature.featureId || feature._id);
      setCheckedActivities(featureIds);
      form.setFieldsValue({
        role: roleData.roleName,
      });
    }
  }, [roleData, form]);

  const onChange = (checkedValues) => {
    setCheckedActivities(checkedValues);
  };

  const onFinish = async (values) => {
    // if (!roleData) {
    //   message.error("No role data available");
    //   return;
    // }

    // Validate that at least one feature is selected
    if (checkedActivities.length === 0) {
      message.error("Please select at least one feature.");
      return;
    }

    const rolePayload = {
      roleName: values.role,
      roleId: roleData._id, 
      features: checkedActivities.map((featureId) => ({
        featureId: featureId,
      })),
      source: "Mr_Sasta",
    };

    try {
      const response = await axiosInstance.put(
        `${baseURL}/editSellerRoles`,
        rolePayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.responseCode === 200) {
        message.success(response.data.message);
        form.resetFields();
        updateRolesData({
          ...roleData,
          roleName: values.role,
          features: featuresData.filter((feature) => checkedActivities.includes(feature._id)),
        });
        fetchRoles();
        close();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className="Edit-role-container">
      <div className="Edit-role-content">
        <Form
          form={form}
          name="edit_role"
          className="edit-role-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="role"
            label="Role Name"
            initialValue={roleName}
            rules={[
              {
                required: true,
                message: "Please enter role name",
              },
            ]}
          >
            <Input
              type="text"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Features"
            required
            // You can add validation here if needed
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedActivities}
              onChange={onChange}
            >
              <Row style={{ marginBottom: "10px" }}>
                {featuresData?.length > 0
                  ? featuresData.map((item) => (
                      <Col
                        span={8}
                        style={{ marginBottom: "12px" }}
                        key={item._id}
                      >
                        <Checkbox value={item._id}>{item?.featureName}</Checkbox>
                      </Col>
                    ))
                  : <p>No features available</p>}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <div className="edit-role-submitn flex justify-between">
              <Button
                type="default"
                className="edit-role-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2 cursor-pointer"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={checkedActivities.length === 0}
                className={`edit-role-submit-button !bg-[#009A00] !hover:bg-[#008000] text-white py-2 rounded-md font-medium p-4 ${
                  checkedActivities.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditRole;
