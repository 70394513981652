import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Typography,
  Spin,
  Modal,
} from "antd";
import { EditOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { handleProfileImage } from "../../containers/fileupload/fileupload";
import AxiosComponent from "../../util/axiosInstance";
import moment from "moment";
import Changepassword from "../../containers/Change_password/changepassword";
import useLogout from "../../util/authUtils";
import LocationSearch from "../../util/Location";

const { Title, Text } = Typography;

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [form] = Form.useForm();
  const logout = useLogout();
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");

  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [area, setArea] = useState(null);

  const { axiosInstance } = AxiosComponent();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const fetchProfile = async () => {
    try {
      
      const response = await axiosInstance.get(`${baseURL}/getEmployeeProfile`);
      if (response.status === 200) {
        setProfile(response.data.employee);
        setProfileImage(response.data.employee.image || "");
        setAddress(response.data.employee.address || "");
        form.setFieldsValue(response.data.employee);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }
    if (file.size > 500 * 1024) {
      message.error("Image is greater than 500KB. The maximum limit is 500KB.");
      return;
    }

    setLoading(true);
    handleProfileImage(event)
      .then((imageUrl) => {
        setProfileImage(imageUrl);
        setLoading(false);
        message.success("Image uploaded successfully.");
      })
      .catch((error) => {
        message.error("Image upload failed. Please try again.");
        setLoading(false);
      });
  };


  const handleSave = async () => {
    try {
      const values = form.getFieldsValue();
      const payload = {
        employeId: profile._id,
        username: values.username,
        email: values.email,
        role_Id: profile.role_Id,
        phoneNumber: values.phoneNumber,
        address: values.address,
        DOB: moment(values.DOB, "DD-MM-YYYY").format("YYYY-MM-DD"),
        image: profileImage,
      };

      const response = await axiosInstance.put(
        `${baseURL}/editEmployee`,
        payload
      );

      if (response.data.responseCode === 200) {
        setProfile(response.data.data);
        setEditMode(false);
        message.success("Profile updated successfully.");
        fetchProfile();
      } else if (response.data.responseCode === 400) {
        message.error(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const openPasswordModal = () => {
    setPasswordModalVisible(true);
  };

  const closePasswordModal = () => {
    setPasswordModalVisible(false);
  };

  return (
    <Spin spinning={loading}>
      <div className="p-4 bg-white">
        {profile ? (
          <>
            <Title level={3} className="text-left pb-6 pt-0">
              My Profile
            </Title>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <Avatar
                  size={80}
                  src={profileImage || profile.username[0]}
                  className="mx-auto"
                >
                  {profile.username[0]}
                </Avatar>
                <div className="ml-6">
                  <Title level={3}>{profile.username}</Title>
                  <Text type="secondary" className="text-left flex">
                    {profile.roleName}
                  </Text>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                {!editMode && (
                  <button
                    icon={<EditOutlined />}
                    onClick={handleEditClick}
                    className="bg-green-600 border-green-600 p-2 rounded-md text-white font-medium"
                  >
                    Edit Profile
                  </button>
                )}
                <Button
                  type="default"
                  icon={<LockOutlined />}
                  className="bg-gray-600 hover:bg-gray-700 border-gray-600 text-white"
                  onClick={openPasswordModal}
                >
                  Change Password
                </Button>
              </div>
            </div>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSave}
              className="mt-6"
            >
              <div className="grid grid-cols-2 gap-4">
                <Form.Item
                  name="username"
                  label="First Name"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input
                    disabled={!editMode}
                    maxLength={30}
                    onInput={(e) => {
                      let value = e.target.value;
                      value = value.replace(/^\s+/, "");
                      value = value.slice(0, 30);
                      value = value.replace(/[^A-Za-z]/g, "");
                      e.target.value = value;
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input disabled={!editMode} />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label="Phone"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input
                    disabled={!editMode}
                    maxLength={10}
                    onInput={(e) => {
                      let value = e.target.value;
                      value = value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your address",
                    },
                  ]}
                >
                  <LocationSearch
                    value={address}
                    onChange={(value) => {
                      setAddress(value); // Update the address state
                      form.setFieldsValue({ address: value }); // Update the form field
                    }}
                    initialValue={address}
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                    editMode={editMode}
                    setArea={setArea}
                  />
                </Form.Item>
              </div>
              {editMode && (
                <div className="flex w-full justify-center">
                  <div className="border-2 border-dotted border-gray-300 p-2 rounded-lg text-center mb-4 flex w-96 justify-center">
                    {profileImage ? (
                      <div>
                        <label
                          htmlFor="fileInput"
                          className="text-[#009A00] cursor-pointer font-bold"
                        >
                          Upload Profile Image
                        </label>
                        <input
                          type="file"
                          id="fileInput"
                          onChange={handleAadharImage}
                          style={{ display: "none" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={profileImage}
                          alt="Profile"
                          className="w-20 h-20 mx-auto"
                        />
                        <label
                          htmlFor="fileInput"
                          className="text-[#009A00] font-bold cursor-pointer"
                        >
                          Change Image
                        </label>
                        <input
                          type="file"
                          id="fileInput"
                          onChange={handleAadharImage} // Use updated handler
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {editMode && (
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="w-[20%] flex justify-center text-white font-medium bg-green-600 hover:bg-green-700 border-green-600"
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </Form>
          </>
        ) : (
          <p>Profile not available.</p>
        )}
      </div>
      <Modal
        title="Change Password"
        open={passwordModalVisible}
        onCancel={closePasswordModal}
        footer={null}
        centered
      >
        <Changepassword email={profile?.email} onClose={closePasswordModal} />
      </Modal>
    </Spin>
  );
};

export default ProfilePage;
