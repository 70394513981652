import React, { useState  ,useEffect} from "react";
import { Avatar, Switch, Pagination, Modal, message } from "antd";
import { MdOutlineEdit } from "react-icons/md";
import EditEmployee from "./EditEmploye";

const EmployeeList = ({ employeeData, fetchEmployeeData, rolesData  ,filter ,searchInput ,reportsDate}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditEmployeeModal, setshowEditEmployeeModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const pageSize = 10;

  

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = employeeData?.slice(startIndex, endIndex);

  const editEmployee = (employee) => {
    setSelectedEmployee(employee);
    setshowEditEmployeeModal(true);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [filter, searchInput, reportsDate]);

  const toggleStatus = async (employee) => {
    try {
      const response = await fetch(`${baseURL}/toggleEmployeeStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          employeeId: employee._id,
          status: employee.status === "active" ? "inactive" : "active",
        }),
      });

      const data = await response.json();

      if (data.responseCode === 200) {
        message.success(data.message);
        fetchEmployeeData();
      } else if (data.responseCode === 400) {
        message.error(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.toString());
    }
  };

  const close = () => {
    setshowEditEmployeeModal(false);
  };

  return (
    <div className="p-2 bg-white">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr>
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-left min-w-[150px] max-w-[200px]">
              Name of the Employee
            </th>
            <th className="border-b p-4 text-center">Designation</th>
            <th className="border-b p-4 text-center">Contact</th>
            <th className="border-b p-4 text-center">E-mail</th>
            <th className="border-b p-4 text-center min-w-[120px] max-w-[290px]">
               Date
            </th>
            {/* <th className="border-b p-4 text-center min-w-[120px] max-w-[290px]">
              Last Date
            </th> */}
            <th className="border-b p-4 text-center">Action</th>
            <th className="border-b p-4 text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((employee, index) => (
            <tr key={employee._id}>
              <td className="border-b p-4 text-center">
                {startIndex + index + 1}
              </td>
              <td className="border-b p-4 flex items-center gap-3">
                {employee.image ? (
                  <Avatar
                    src={employee.image || employee.username[0]}
                    size={35}
                    shape="circle"
                  />
                ) : (
                  <Avatar
                    size={35}
                    shape="circle"
                    className="mr-2 bg-gray-200 text-black capitalize"
                  >
                    {employee.username ? employee.username[0] : ""}
                  </Avatar>
                )}
                {employee.username || "--"}
              </td>
              <td className="border-b p-4 text-center">
                {employee.roleName || "--"}
              </td>
              <td className="border-b p-4 text-center">
                {employee.phoneNumber || "--"}
              </td>
              <td className="border-b p-4 text-center">
                {employee.email || "--"}
              </td>
              <td className="border-b p-4 text-center">
                {employee.date || "--"}
              </td>
              {/* <td className="border-b p-4 text-center">
                {employee.endDate || "--"}
              </td> */}
              <td className="border-b p-4 text-center">
                <div className="flex justify-center">
                  <MdOutlineEdit
                    onClick={() => editEmployee(employee)}
                    className="text-[#009A00] cursor-pointer"
                    size={18}
                  />
                </div>
              </td>
              <td className="border-b p-4 text-center">
                <Switch
                  checked={employee.status === "active"}
                  onChange={() => toggleStatus(employee)}
                  className="EmployeelistBody_antSwitch"
                  style={{
                    backgroundColor:
                      employee.status === "active" ? "#009A00" : "#d9d9d9",
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {paginatedData?.length === 0 && (
        <div className="no-data-message mt-3">
          <p>No data found</p>
        </div>
      )}

      <div className="Pagination-container mt-3 justify-end flex">
        {employeeData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={employeeData?.length}
            showSizeChanger
            onShowSizeChange={handleChangePage}
            onChange={handleChangePage}
          />
        )}
      </div>
      <Modal
        visible={showEditEmployeeModal}
        fixed
        centered
        onCancel={() => setshowEditEmployeeModal(false)}
        title="Edit Employee"
        footer={null}
      >
        {selectedEmployee && (
          <EditEmployee
            close={close}
            employee={selectedEmployee}
            fetchData={fetchEmployeeData}
            employeeData={employeeData}
            rolesData={rolesData}
          />
        )}
      </Modal>
    </div>
  );
};

export default EmployeeList;
