// SellerCard.jsx
import React, { useState, useEffect } from "react";
import { Input, Button, Modal } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import AddSeller from "./AddSeller";
// import AddSeller from "./AddSeller";

const SellerCard = ({
  filter,
  setFilter,
  activeCount,
  inactiveCount,
  totalCount,
  searchInput,
  setSearchInput,
  fetchSellerData,
}) => {
  const [showAddSellerModal, setShowAddSellerModal] = useState(false);

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSellerUpdate = () => {
    fetchSellerData();
    setShowAddSellerModal(false);
  };

  const closeAddSellerModal = () => {
    setShowAddSellerModal(false);
  };

  return (
    <div className="bg-white p-4 flex justify-between items-center border-b">
      <div className="flex items-center space-x-4">
        {/* Search Input */}
        <div className="relative w-64">
          <Input
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search Seller"
            className="w-64 pl-2 pr-2 py-2 border-gray-300 focus:ring-[#009A00]"
            suffix={<SearchOutlined className="text-gray-400" />}
          />
        </div>
        {/* Filter Buttons */}
        <div className="flex space-x-2">
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "All"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-gray-500 border-gray-300"
            }`}
            onClick={() => handleFilterClick("All")}
          >
            ALL ({totalCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Active"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-gray-500 border-gray-300"
            }`}
            onClick={() => handleFilterClick("Active")}
          >
            ACTIVE ({activeCount})
          </button>
          <button
            className={`px-6 py-2 rounded-full border transition-all ${
              filter === "Inactive"
                ? "bg-[#0ACF83] text-white border-[#0ACF83]"
                : "bg-white text-gray-500 border-gray-300"
            }`}
            onClick={() => handleFilterClick("Inactive")}
          >
            INACTIVE ({inactiveCount})
          </button>
        </div>
      </div>

      {/* Add Seller Button */}
      <div className="flex justify-end">
        <Button
          className="bg-[#009A00] text-white flex items-center px-6 py-5 rounded hover:bg-[#008000] transition-all"
          onClick={() => setShowAddSellerModal(true)}
        >
          <PlusOutlined className="mr-2" />
          Add Seller
        </Button>
      </div>
      <Modal
        visible={showAddSellerModal}
        centered
        onCancel={closeAddSellerModal}
        footer={null}
        title="Add Seller"
        className="custom-modal h-[80%] overflow-auto rounded"
      >
        <AddSeller
          close={closeAddSellerModal}
          fetchSellerData={fetchSellerData}
        />
      </Modal>
    </div>
  );
};

export default SellerCard;
