// AddRider.jsx
import { Form, Input, Spin, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { handleProfileImage } from "../../containers/fileupload/fileupload";
import AxiosComponent from "../../util/axiosInstance";
import useLogout from "../../util/authUtils";

const AddRider = ({ close, fetchRiders, fetchData }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [riderImage, setRiderImage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { axiosInstance } = AxiosComponent();
  useEffect(() => {
    form.resetFields();
    setRiderImage("");
  }, [close, form]);

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");
  const logout = useLogout();
  const handleAadharImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }
    if (file.size > 500 * 1024) {
      message.error("Image is greater than 500KB. The maximum limit is 500KB.");
      return;
    }

    setLoading(true);
    try {
      const imageUrl = await handleProfileImage(event);
      setRiderImage(imageUrl);
      message.success("Image uploaded successfully.");
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to upload image.");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    setError("");
    try {
      const payload = {
        riderName: values.username,
        email: values.email,
        phone: values.phoneNumber,
        drivingLicense: values.drivingLicense,
        aadhaarNumber: values.aadhaarNumber,
        address: values.address,
        profilePic: riderImage,
      };
  
      const response = await axiosInstance.post(`${baseURL}/addRider`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        message.success(response.data.message);
        fetchData();
        setSuccessModal(true);
        close();
        setTimeout(() => {
          form.resetFields();  
          setRiderImage("");  
          close();             
        }, 0);
      } else if (response.responseCode === 400) {
        setError(response.data.message);
        message.error(response.data.message);
      } else if (response.responseCode === 401) {
        message.error(response.data.message);
        logout();
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  

  const handlePhoneNumberKeyPress = (event) => {
    const { key } = event;
    if (!/^\d$/.test(key)) {
      event.preventDefault();
      message.error("Only numbers are allowed in Phone Number.");
    }
  };

  const handlePhoneNumberPaste = (event) => {
    const pastedData = event.clipboardData.getData("Text");
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
      message.error("Only numbers are allowed in Phone Number.");
    }
  };
  // const handleSubmit = () => {
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       onFinish(values); 
  //     })
  //     .catch((errorInfo) => {
  //       console.error("Validation Failed:", errorInfo);
  //     });
  // };
  
  return (
    <Spin spinning={loading}>
      <div className="AddRider-container">
        <div className="AddRider-content">
          <Form
            form={form}
            name="add_rider"
            className="edit-profile-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="Rider Name"
              className="mt-0 mb-4"
              rules={[
                {
                  required: true,
                  message: "Please enter Rider name",
                },
                {
                  max: 30,
                  message: "Rider name cannot exceed 30 characters.",
                },
                {
                  pattern: /^[A-Za-z ]+$/,
                  message: "Rider name can only contain letters and spaces",
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim().startsWith(" ")) {
                      return Promise.reject(
                        new Error("Rider name cannot start with a space")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Rider Name"
                maxLength={30}
                onInput={(e) => {
                  let value = e.target.value;
                  value = value.replace(/^\s+/, "");
                  value = value.slice(0, 30);
                  value = value.replace(/[^A-Za-z ]/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              className="mt-0 mb-4"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              className="mt-0 mb-4"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  pattern: /^[6-9]\d{9}$/,
                  message: "please enter a valid phone number",
                },
                {
                  max: 10,
                  message: "Phone number cannot exceed 10 digits",
                },
                {
                  validator: (_, value) => {
                    if (value && /\D/.test(value)) {
                      return Promise.reject(
                        new Error("Phone number can only contain numbers")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Phone number"
                maxLength={10}
                onKeyPress={handlePhoneNumberKeyPress}
                onPaste={handlePhoneNumberPaste}
                onInput={(e) => {
                  let value = e.target.value;
                  value = value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name="drivingLicense"
              label="Driving License"
              className="mt-0 mb-4"
              rules={[
                { required: true, message: "Please enter Driving License" },
                {
                  pattern:
                    /^[A-Z]{2}[-\s]?[0-9]{2}[-\s]?[0-9]{4}[-\s]?[0-9]{7}$/,
                  message: "Driving License format must be SS-RRYYYYNNNNNNN",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Driving License number"
                maxLength={16}
                onInput={(e) => {
                  let value = e.target.value;
                  value = value.replace(/^\s+/, "");
                  e.target.value = value;
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  const formattedValue = value
                    .replace(/[^A-Z0-9-\s]/gi, "")
                    .toUpperCase();
                  e.target.value = formattedValue;
                }}
              />
            </Form.Item>

            <Form.Item
              name="aadhaarNumber"
              label="Aadhaar Number"
              className="mt-0 mb-4"
              rules={[
                { required: true, message: "Please enter Aadhaar Number" },
                {
                  pattern: /^\d{12}$/,
                  message: "Aadhaar Number must be exactly 12 digits",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Aadhaar Number"
                maxLength={12}
                onKeyPress={(event) => {
                  const { key } = event;
                  if (!/^\d$/.test(key)) {
                    event.preventDefault();
                    message.error(
                      "Only numbers are allowed in Aadhaar Number."
                    );
                  }
                }}
                onPaste={(event) => {
                  const pastedData = event.clipboardData.getData("Text");
                  if (!/^\d+$/.test(pastedData)) {
                    event.preventDefault();
                    message.error(
                      "Only numbers are allowed in Aadhaar Number."
                    );
                  }
                }}
                onInput={(e) => {
                  let value = e.target.value;
                  value = value.replace(/^\s+/, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              className="mt-0 mb-4"
              rules={[{ required: false, message: "Please enter address" }]}
            >
              <Input type="text" placeholder="Enter Address" />
            </Form.Item>
            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              {riderImage ? (
                <div className="FileChange">
                  <div className="image-section">
                    <img
                      src={riderImage}
                      alt="Rider"
                      className="image-preview"
                      style={{
                        width: "20%",
                        height: "auto",
                        maxWidth: "150px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <label
                    htmlFor="fileInput"
                    className="primary-text pointer text-[#009A00] font-bold"
                  >
                    Change Image
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleAadharImage}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div className="FileUpload">
                  <div className="flex justify-center">
                    <img
                      src="/Assests/Images/image-gallery.png"
                      alt="Rider"
                      className="file-upload"
                      style={{
                        width: "20%",
                        height: "auto",
                        maxWidth: "150px",
                      }}
                    />
                  </div>
                  <p>
                    {" "}
                    <label
                      htmlFor="fileInput"
                      className="text-[#009A00] font-bold cursor-pointer"
                    >
                      Upload Profile image
                    </label>
                  </p>
                  {/* <small className="secondary-text">Maximum size: 500KB</small> */}
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleAadharImage}
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </div>

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="add-Rider-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2 cursor-pointer"
                  onClick={close}
                >
                  <p>Cancel</p>
                </div>
                <button
                  htmlType="submit"
                  className="add-Rider-submit-button !bg-[#009A00] !hover:bg-[#008000] text-white py-2 rounded-md font-medium p-4"
                >
                  <p>Submit</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className="custom-modal"
        >
          <div className="Rider-success-container flex-row">
            <div className="Rider-success-content !gap-7 !flex-col flex">
              <div className="flex justify-center">
                <img
                  src="Assests/Images/success_image.png"
                  alt="Success"
                  className="w-[15%]"
                />
              </div>
              <div>
                <h5 className="font-medium text-center">
                  Rider Added <br /> Successfully
                </h5>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default AddRider;
