import { Routes, Route } from "react-router-dom";

import CategoryMain from "./Categories";
import CustomerMain from "./Customers";
import ProductMain from "./Products";
import EmployeeMain from "./Employess";
import OrderManagementMain from "./OrderManegagement";
import RoleMain from "./Roles";
import DashboardMain from "./Dashboard";
import RidersMain from "./Riders";
import ReviewSMain from "./Reviews";
import PaymentMain from "./Payment";
import PaymentHistoryMain from "./Paymenthistory.js";
import SellerMain from "./Seller/index.js";
import ProfileMain from "./Profile/index.js";
import SellerCategoryMain from "./SellerUsers/SellerCategories/index.js";
import SellerRoleMain from "./SellerUsers/SellerRoles/index.js";
import SellerDashboardMain from "./SellerUsers/SellerDashboard/index.js";
import SellerReviewSMain from "./SellerUsers/SellerReviews/index.js";
import SellerPaymentMain from "./SellerUsers/SellerPayment/index.js";
import SellerOrderManagementMain from "./SellerUsers/SellerOrderManegagement/index.js";
import SellerEmployeeMain from "./SellerUsers/SellerEmployess/index.js";
import SellerCustomerMain from "./SellerUsers/SellerCustomers/index.js";
import SellerRidersMain from "./SellerUsers/SellerRiders/index.js";
import StockManagementMain from "./StockManagement/index.js";
import SubCategoryMain from "./Sub Categories/index.js";
import HelpCenterMain from "./Help Center/index.js";
import AddProduct from "./Products/Addproduct.js";
import SellerSubCategoryMain from "./SellerUsers/SellerSubCategories/index.js";
import SellerStockManagementMain from "./SellerUsers/SellerStockManagement/index.js";

const MainApp = () => {
  return (
    <div className='App'>
      <Routes>
        <Route path='/profile' element={<ProfileMain />} />
        <Route path='/dashboard' element={<DashboardMain />} />
        <Route path='/categories' element={<CategoryMain />} />
        <Route path='/sub-category' element={<SubCategoryMain />} />
        <Route path='/customer' element={<CustomerMain />} />
        <Route path='/products' element={<ProductMain />} />
        <Route path='/products/add-product' element={<AddProduct />} />
        <Route path='/employee' element={<EmployeeMain />} />
        <Route path='/payment' element={<PaymentMain />} />
        <Route
          path='/payment-history/:sellerId'
          element={<PaymentHistoryMain />}
        />
        <Route path='/order-management' element={<OrderManagementMain />} />
        <Route path='/Stock-management' element={<StockManagementMain />} />
        <Route path='/reviews' element={<ReviewSMain />} />
        <Route path='/sellers' element={<SellerMain />} />
        <Route path='/roles' element={<RoleMain />} />
        <Route path='/riders' element={<RidersMain />} />
        <Route path='/help-center' element={<HelpCenterMain />} />

        <Route path='/Seller-Categories' element={<SellerCategoryMain />} />
        <Route path='/Seller-Roles' element={<SellerRoleMain />} />
        <Route path='/Seller-Dashboard' element={<SellerDashboardMain />} />
        <Route path='/Seller-Reviews' element={<SellerReviewSMain />} />
        <Route path='/Seller-Payment' element={<SellerPaymentMain />} />
        <Route path='/Seller-Orders' element={<SellerOrderManagementMain />} />
        <Route path='/Seller-Employes' element={<SellerEmployeeMain />} />
        <Route path='/Seller-Customers' element={<SellerCustomerMain />} />
        <Route path='/Seller-Rider' element={<SellerRidersMain />} />
        <Route
          path='/Seller-sub-category'
          element={<SellerSubCategoryMain />}
        />
        <Route
          path='/SellerStock-management'
          element={<SellerStockManagementMain />}
        />
      </Routes>
    </div>
  );
};

export default MainApp;
