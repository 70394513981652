import React from "react";

const Stockmanagementhistory = ({ isVisible, onClose, data, loading }) => {
  return (
    <div>
      <table className="w-full border-b border-gray-300">
        <thead>
          <tr className="border-b">
            <th className="p-4 text-left">Product Name</th>
            <th className="p-4 text-left">Quantity Value</th>
            <th className="p-4 text-left">Added Stock</th>
            <th className="p-4 text-left">Date</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((history, index) => (
              <tr key={index} className="border-b">
                <td className="p-4">{history.productName ||"--"}</td>
                <td className="p-4">{history.quantityValue || "--"}</td>
                <td className="p-4 items-center justify-center">{history.addedStock || "--"}</td>
                <td className="p-4">
                  {new Date(history.date).toLocaleDateString()}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="p-4" colSpan={4}>
                No history available for this SKU.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Stockmanagementhistory;
