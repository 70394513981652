import React, { useState } from "react";
import { Form, Input, Button, message, Modal } from "antd";
import { LockOutlined } from "@ant-design/icons";
import AxiosComponent from "../../util/axiosInstance";
import useLogout from "../../util/authUtils";

const Changepassword = ({ email, onClose, onLogout }) => {
  const { axiosInstance } = AxiosComponent();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const logout = useLogout();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      const payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        email: email,
      };

      const response = await axiosInstance.put(`/changePassword`, payload);

      if (response.status === 200 && response.data.responseCode === 200) {
        setIsModalVisible(true); // Show success modal
      } else {
        message.error(response.data.message || "Failed to change password.");
      }
    } catch (error) {
      if (error.response?.status === 400) {
        message.error(
          error.response.data.message || "Invalid password provided."
        );
      } else {
        message.error(
          error.response?.data?.message || "Error changing password."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    logout(); // Logs the user out and redirects to login
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="p-4"
      >
        <Form.Item
          name="oldPassword"
          label="Old Password"
          rules={[
            { required: true, message: "Please enter your old password" },
          ]}
        >
          <Input.Password
            placeholder="Enter old password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[{ required: true, message: "Please enter a new password" }]}
        >
          <Input.Password
            placeholder="Enter new password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match!"));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm new password"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <button
            htmlType="submit"
            loading={loading}
            className="w-full bg-[#009A00] text-white p-2 rounded-md font-medium hover:bg-[#009A00]"
          >
            Submit
          </button>
        </Form.Item>
      </Form>

      <Modal
        title="Success"
        visible={isModalVisible}
        footer={null} 
        onCancel={handleOk} 
        centered
        fixed
      >
        <p>Your password has been changed successfully. Please log in again.</p>
        <button
          onClick={handleOk}
          className="bg-[#009A00] text-white hover:bg-[#009A00]"
        >
          OK
        </button>
      </Modal>
    </>
  );
};

export default Changepassword;
