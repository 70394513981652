import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import DashboardCard from "./DashboardCard";
import Dashboardchart from "./Dashboardchart";
import DashboardList from "./DashboardList";
import AxiosComponent from "../../util/axiosInstance";

const DashboardMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dashboardData, setDashboardData] = useState({});
  const [filterType, setFilterType] = useState("");
  const { axiosInstance } = AxiosComponent();

  const fetchDashboardData = async (type) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/unifiedDashboardApi",
        { filterType: type },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
          },
        }
      );

      if (response.status === 200 && response.data.responseCode === 200) {
        setDashboardData(response.data);
      } else {
        message.error(response.data.message || "Invalid request");
      }
    } catch (err) {
      setError(err.message);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData(filterType);
  }, [filterType]);

  return (
    <Spin spinning={loading}>
      <div>
        {/* Passing the data to child components */}
        <DashboardCard
          dashboardCounts={dashboardData.dashboardCounts}
          setFilterType={setFilterType}
          filterType={filterType}
        />
        <Dashboardchart
          topSellingProducts={dashboardData.topSelling}
          stockAlerts={dashboardData.stockAlert}
        />
        <DashboardList
          orders={dashboardData.recentOrders}
          topSellingProducts={dashboardData.topSelling}
        />
      </div>
    </Spin>
  );
};

export default DashboardMain;
