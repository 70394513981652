import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Avatar, message } from "antd";
import { useNavigate } from "react-router-dom";
import AxiosComponent from "../../util/axiosInstance";

const data = [
  { name: "Jan", fairDeals: 40000, InstantDeals: 24000 },
  { name: "Feb", fairDeals: 30000, InstantDeals: 13980 },
  { name: "Mar", fairDeals: 20000, InstantDeals: 9800 },
  { name: "Apr", fairDeals: 27800, InstantDeals: 39080 },
  { name: "May", fairDeals: 18900, InstantDeals: 4800 },
  { name: "Jun", fairDeals: 23900, InstantDeals: 12800 },
  { name: "Jul", fairDeals: 34900, InstantDeals: 4300 },
  { name: "Aug", fairDeals: 21000, InstantDeals: 29500 },
  { name: "Sep", fairDeals: 22500, InstantDeals: 9000 },
];

const Dashboardchart = ({ topSellingProducts, stockAlerts }) => {
  // const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();
  const navigate = useNavigate();

  const displayedProducts = showAllProducts
    ? topSellingProducts
    : topSellingProducts?.slice(0, 6); // Limiting products

  const handleViewAllClick = () => {
    navigate("/Stock-management");
  };

  return (
    <div className="flex flex-col lg:flex-row gap-6 p-2">
      <div className="lg:w-2/3 bg-white rounded-lg p-4 shadow-md">
        <h2 className="text-lg font-bold mb-4 text-left">Real Time Sale</h2>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            {/* <div className="mr-2">
              <span className="inline-block h-3 w-3 rounded-full bg-black mr-1"></span>
              Fair Deals
            </div>
            <div className="mr-2">
              <span className="inline-block h-3 w-3 rounded-full bg-purple-400 mr-1"></span>
              Instant Deals
            </div> */}
          </div>
          <select className="border rounded-md p-1">
            <option>Monthly</option>
            <option>Weekly</option>
          </select>
        </div>
        <ResponsiveContainer width="100%" height={280}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="fairDeals" fill="#333" />
            <Bar dataKey="InstantDeals" fill="#a78bfa" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Stock Alerts Section */}
      <div className="lg:w-1/3 bg-white rounded-lg p-4 shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Stock Alerts</h2>
          <button
            className="bg-green-100 text-[#009A00] rounded-full px-3 py-1 text-sm"
            onClick={handleViewAllClick}
          >
            {showAllProducts ? "Show Less" : "View All"}
          </button>
        </div>
        <p className="text-gray-500 text-sm mb-4">
          {topSellingProducts?.length > 0
            ? `${topSellingProducts.length} Products are out of stock`
            : "No stock alerts available"}
        </p>
        <ul
          className={`max-h-60 ${
            showAllProducts ? "h-60 overflow-y-auto" : "h-auto"
          }`}
        >
          {" "}
          {/* Modified: Scroll only when showAllProducts is true */}
          {displayedProducts?.map((product, index) => (
            <li key={index} className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Avatar
                  src={product.imageUrl}
                  size="large"
                  className="mr-3"
                />
                <span>{product.productName}</span>
              </div>
              <span className="text-red-500 text-sm">
                {product.totalOrders}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dashboardchart;
