import { Form, Input, Modal, Select, Spin, DatePicker, message } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";

const EditEmployee = ({ employee, close, rolesData, fetchData }) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState(employee.image || "");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleRoleChange = (value) => {
    console.log("Selected Role:", value);
  };

  useEffect(() => {
    if (employee) {
      form.setFieldsValue({
        name: employee.name, // Corrected field name from `username` to `name`
        email: employee.email,
        role: employee.role_Id, // Ensure role_Id corresponds to the correct field in the API
        address: employee.address,
        gst: employee.gst,
        pan: employee.pan,
        dob: employee.dob ? moment(employee.dob) : null,
      });
      setCategoryImage(employee.image);
    }
  }, [employee, form]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 500 * 1024) {
      const reader = new FileReader();
      reader.onloadend = () => setCategoryImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      message.error("Image is greater than 500KB. The maximum limit is 500KB.");
    }
  };

  const onFinish = async (values) => {
    const payload = {
      subSellerId: employee._id,
      name: values.name,
      email: values.email,
      role_Id: values.role,
      address: values.address,
      gst: values.gst,
      pan: values.pan,
      dob: values.dob ? values.dob.format("YYYY-MM-DD") : null,
      image: categoryImage,
      location: {
        latitude: "17.3850",
        longitude: "78.4867",
      },
    };

    try {
      setLoading(true);
      const response = await fetch(`${baseURL}/editSubSeller`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        message.success(responseData.message);
        setSuccessModal(true);
        fetchData();
        close();
        form.resetFields();
      } else {
        const errorData = await response.json();
        message.error(errorData.message || "Error updating employee");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="AddEmploy-container">
        <div className="AddEmploy-content">
          <Form
            form={form}
            name="edit_employee"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Employee Name" // Changed `username` to `name` here
              rules={[
                { required: true, message: "Please enter employee name" },
              ]}
            >
              <Input placeholder="Enter Employee Name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please enter email" }]}
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>

            <Form.Item
              name="role"
              label="Select Employee Role"
              rules={[
                { required: true, message: "Please select employee role" },
              ]}
            >
              <Select
                placeholder="Select Employee Role"
                allowClear
                value={form.getFieldValue("role")} 
                onChange={handleRoleChange}
              >
                {rolesData?.map(({ _id, roleName }) => (
                  <Select.Option key={_id} value={_id}>
                    {roleName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="gst"
              label="GST Number"
              rules={[{ required: true, message: "Please enter GST number" }]}
            >
              <Input placeholder="Enter GST Number" />
            </Form.Item>

            <Form.Item
              name="pan"
              label="PAN Number"
              rules={[{ required: true, message: "Please enter PAN number" }]}
            >
              <Input placeholder="Enter PAN Number" />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please enter address" }]}
            >
              <Input.TextArea placeholder="Enter Address" />
            </Form.Item>

            <Form.Item name="dob" label="Date of Birth">
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>

            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              <div className="FileChange">
                <div className="image-section">
                  {categoryImage && <img src={categoryImage} alt="employee" />}
                </div>
                <label
                  htmlFor="fileInput"
                  className="primary-text cursor-pointer"
                >
                  Change Image
                </label>
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="add-Employ-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2"
                  onClick={close}
                >
                  Cancel
                </div>
                <button
                  htmlType="submit"
                  className="add-Employ-submit-button !bg-[#009A00] text-white py-2 rounded-md font-medium p-4"
                >
                  Submit
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
        >
          <div className="Employ-success-container">
            <div className="Employ-success-content">
              <img src="Assests/Images/success_image.png" alt="" />
              <h5>
                Employee Details Updated <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default EditEmployee;
