import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Select } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import { handleProfileImage } from "../../../containers/fileupload/fileupload";
import { useDropzone } from "react-dropzone";
const { TextArea } = Input;

const SellerSubAddCategory = ({
  fetchCategoriesSeller,
  fetchSubCategoriesSeller,
  handleCategorySellerChange,
  setSelectedCategoryId,
  updateCategory,
  setUpdateCategory,
  setLoading,
  setError,
  categories,
}) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState("");
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  useEffect(() => {
    if (updateCategory) {
      form.setFieldsValue({
        name: updateCategory.name,
        description: updateCategory.description,
        
      });
      setCategoryImage(updateCategory.image || "");
    }
  }, [updateCategory, form]);

  const onFinish = async (values) => {
    const payload = {
      categoryId: values.category,
      name: values.name,
      description: values.description,
      banner: "Vegies.jpg",
      image: categoryImage,
    };
    if (updateCategory) {
      payload.subCatgoryId = updateCategory.subCategoryId;
    }

    try {
      const endpoint = updateCategory ? "/editSubCategory" : "/addSubCatgory";
      const method = updateCategory ? "put" : "post";

      const response = await axiosInstance({
        method,
        url: endpoint,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      });

      const data = response.data;

      if (data.responseCode === 200) {
        message.success(data.message);
        form.resetFields();
        setCategoryImage("");
        fetchSubCategoriesSeller(values.category);
        setUpdateCategory(null);
      } else if (data.responseCode === 400) {
        message.error(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An unexpected error occurred.");
    }
  };

  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }
    if (file.size > 500 * 1024) {
      message.error("Image is greater than 500KB. The maximum limit is 500KB.");
      return;
    }

    setLoading(true);
    handleProfileImage(event)
      .then((imageUrl) => {
        setCategoryImage(imageUrl);
        setLoading(false);
        message.success("Image uploaded successfully.");
      })
      .catch((error) => {
        message.error("Image upload failed. Please try again.");
        setLoading(false);
      });
  };
  const onCategorySelect = (value) => {
    handleCategorySellerChange(value);
  };

  const clearSelection = () => {
    setSelectedCategoryId(null);
    form.resetFields();
  };
  return (
    <div className="p-2 bg-white">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="space-y-4"
      >
        <Form.Item name="category" label="Select Category">
          <Select
            placeholder="Select a category"
            allowClear
            onChange={onCategorySelect}
            onClear={clearSelection}
            className="w-full text-left"
            dropdownClassName="text-left"
          >
            {categories?.map((category) => (
              <Select.Option
                key={category?.categoryId}
                value={category?.categoryId}
                className="text-left"
              >
                {category?.categoryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label={updateCategory ? "Edit SubCategory" : "Add SubCategory"}
          rules={[
            { required: true, message: "Please enter category name" },
            {
              pattern: /^[A-Za-z ]+$/,
              message:
                "Category name can only contain letters, numbers, and spaces",
            },
            {
              max: 30,
              message: "Category name should not exceed 30 characters",
            },
            {
              validator: (_, value) => {
                if (value && value.trim().startsWith(" ")) {
                  return Promise.reject(
                    new Error("Category name cannot start with a space")
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ textAlign: "left" }}
        >
          <Input
            placeholder="Name of the Sub Category"
            className="border-gray-300 rounded-md w-full"
            maxLength={30}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/^\s+/, "");
              e.target.value = e.target.value.replace(/[0-9]/g, "");
            }}
          />
        </Form.Item>

        <Form.Item name="description" label="Description" className="!mb-2">
          <TextArea
            rows={4}
            placeholder="Add description for Sub Category"
            maxLength={350}
            showCount
            className="border-gray-300 rounded-md w-full"
          />
        </Form.Item>

        <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4 !mt-[29px]">
          <div className="flex justify-center items-center">
            {categoryImage ? (
              <img
                src={categoryImage}
                alt="Category Preview"
                className="w-20 h-18 mb-4"
              />
            ) : (
              <img
                src="/Assests/Images/image-gallery.png"
                alt="Default Category"
                className="w-20 h-18"
              />
            )}
          </div>

          <div className="flex flex-col items-center">
            <p>
              {" "}
              <label
                htmlFor="fileInput"
                className="text-[#009A00] cursor-pointer font-bold"
              >
                {categoryImage ? "Change Image" : " Upload your files here"}
              </label>
            </p>
            {/* <p className="text-sm text-gray-500">Maximum size: 500KB</p> */}
            <input
              type="file"
              id="fileInput"
              onChange={handleAadharImage}
              className="!hidden"
            />
          </div>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          className="w-full !bg-[#009A00] !hover:bg-[#009A00] text-white py-2 rounded-md font-medium"
        >
          {updateCategory ? "Update Category" : "Add Category"}
        </Button>
      </Form>
    </div>
  );
};
// AddCategory
export default SellerSubAddCategory;
