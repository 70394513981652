import React, { useState } from "react";
import { Pagination, Button } from "antd";

const Paymenthistorylist = ({ paymentHistoryData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = paymentHistoryData.slice(startIndex, endIndex);

  const handleInvoiceClick = (invoiceURL) => {
    if (invoiceURL) {
      window.open(invoiceURL, "_blank");
    } else {
      console.log("No invoice URL available for this transaction.");
    }
  };

  return (
    <div className="p-2 bg-white">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr>
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-center">Transaction ID</th>
            <th className="border-b p-4 text-center">Order ID</th>
            <th className="border-b p-4 text-center">Product Rating</th>
            <th className="border-b p-4 text-center">Discount</th>
            <th className="border-b p-4 text-center">GST</th>
            <th className="border-b p-4 text-center">Date</th>
            <th className="border-b p-4 text-center">Invoice</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((history, index) => (
              <tr key={history._id}>
                <td className="border-b p-4 text-center">
                  {startIndex + index + 1}
                </td>
                <td className="border-b p-4 text-center">
                  {history.transactionId || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {history.orderId || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {history.productRating || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {history.discount !== null ? `${history.discount}%` : "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {history.gst !== null ? `${history.gst}%` : "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {history.date
                    ? new Date(history.date).toLocaleDateString()
                    : "--"}
                </td>
                <td className="border-b p-4 text-center">
                  <Button
                    className="p-2 text-[#009A00] border-none"
                    onClick={() => handleInvoiceClick(history.invoiceURL)}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              {/* <td colSpan="7" className="border-b p-4 text-center">
                No data found
              </td> */}
            </tr>
          )}
        </tbody>
      </table>

      {paginatedData.length === 0 && (
        <div className="no-data-message mt-3">
          <p>No data found</p>
        </div>
      )}

      <div className="Pagination-container mt-3 justify-end flex">
        {paymentHistoryData.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={paymentHistoryData.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default Paymenthistorylist;
