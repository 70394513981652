import { Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { handleProfileImage } from "../../containers/fileupload/fileupload";

const AddEmployee = ({ close, rolesData, fetchEmployeeData, employeeData }) => {
  const [form] = Form.useForm();
  const [EmployeeImage, setEmployeeImage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    form.resetFields();
    setEmployeeImage("");
  }, [close]);

  const onFinish = async (values) => {
    const Endpoint = `${baseURL}/addEmploye`;
    setLoading(true);

    const userData = {
      username: values.username,
      email: values.email,
      phoneNumber: values.phoneNumber,
      password: values.password,
      role_Id: values.role,
      address: values.address,
      DOB: values.DOB,
      image: EmployeeImage,
    };

    try {
      const response = await fetch(Endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        message.success(data.message);
        setSuccessModal(true);

        setEmployeeImage("");
        close();
        fetchEmployeeData();
        form.resetFields();
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      console.error(error);
      // message.error("Failed to add employee. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = async (value) => {
    console.log(value);
  };

  const validatePhoneNumber = (_, value) => {
    if (value && value.replace(/\D/g, "").length !== 10) {
      return Promise.reject("Phone number must be 10 digits");
    }
    return Promise.resolve();
  };

  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }

    // Change the size limit to 500KB (500 * 1024 bytes)
    if (file.size > 500 * 1024) {
      message.error("Image is greater than 500KB. The maximum limit is 500KB.");
      return;
    }

    handleProfileImage(event)
      .then((imageUrl) => {
        console.log(imageUrl);
        setEmployeeImage(imageUrl);
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error(error);
      });
  };

  return (
    <Spin spinning={loading}>
      <div className="AddEmploy-container">
        <div className="AddEmploy-content">
          <Form
            form={form}
            name="normal_login"
            className="edit-profile-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="role"
              label="Select Employee Role"
              rules={[
                {
                  required: true,
                  message: "Please enter employee role",
                },
              ]}
            >
              <Select
                placeholder="Select Employee Role"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleRoleChange}
              >
                {rolesData
                  ?.filter((item) => item?.status === "active")
                  ?.map(({ _id, roleName }) => (
                    <Select.Option key={_id} value={_id}>
                      {roleName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="username"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter employee name",
                },
                {
                  max: 30,
                  message: "employee name cannot exceed 30 characters.",
                },
                {
                  pattern: /^[A-Za-z0-9 ]+$/,
                  message:
                    "Employee name can only contain letters, numbers, and spaces",
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim().startsWith(" ")) {
                      return Promise.reject(
                        new Error("employee name cannot start with a space")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Employee Name"
                maxLength={30}
                onInput={(e) => {
                  let value = e.target.value;

                  // Prevent leading spaces
                  value = value.replace(/^\s+/, "");

                  // Limit length to 30 characters
                  value = value.slice(0, 30);

                  // Remove special characters
                  value = value.replace(/[^A-Za-z0-9 ]/g, "");

                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
              ]}
            >
              <Input.Password type="password" placeholder="Enter Password" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  pattern: /^[6-9]\d{9}$/,
                  message: "please enter a valid phone number",
                },
                {
                  max: 10,
                  message: "Phone number cannot exceed 10 digits",
                },
                {
                  validator: (_, value) => {
                    // If the value exists and contains non-numeric characters
                    if (value && /\D/.test(value)) {
                      return Promise.reject(
                        new Error("Phone number can only contain numbers")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Phone number"
                maxLength={10}
                onInput={(e) => {
                  let value = e.target.value;

                  // Remove non-digit characters
                  value = value.replace(/\D/g, "");

                  // Update the input with the cleaned value
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>

            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              {EmployeeImage ? (
                <div className="FileChange">
                  <div className="image-section">
                    <img src={EmployeeImage} alt="category" />
                  </div>
                  <label htmlFor="fileInput" className="primary-text pointer">
                    Change Image
                  </label>
                  <div className="document-attach">
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleAadharImage}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              ) : (
                <div className="FileUpload ">
                  <div className="flex justify-center">
                    <img
                      src="/Assests/Images/image-gallery.png"
                      alt="category"
                      className="file-upload"
                    />
                  </div>
                  <div>
                    <p>
                      Upload Profile{" "}
                      <label
                        htmlFor="fileInput"
                        className="text-[#009A00] font-medium cursor-pointer"
                      >
                        image
                      </label>
                      <div className="document-attach">
                        <input
                          type="file"
                          id="fileInput"
                          onChange={handleAadharImage}
                          style={{ display: "none" }}
                        />
                      </div>
                    </p>
                    <small className="secondary-text">
                      Maximum size: 500KB
                    </small>
                  </div>
                </div>
              )}
            </div>
            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="add-Employ-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2"
                  onClick={close}
                >
                  <p>Cancel</p>
                </div>
                <button
                  htmlType="submit"
                  className="add-Employ-submit-button !bg-[#009A00] !hover:bg-[#009A00] text-white py-2 rounded-md font-medium p-4"
                >
                  <p>Submit</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className="custom-modal"
        >
          <div className="Employ-success-container">
            <div className="Employ-success-content">
              <div className="flex justify-center">
                <img src="Assests/Images/success_image.png" alt="" />
              </div>
              <h5 className="font-medium justify-center">
                Employee Added <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default AddEmployee;
