import React from "react";
import { Avatar, Button, Select } from "antd";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const DashboardList = ({ orders, topSellingProducts }) => {
  const [selectedStatusType, setSelectedStatusType] = React.useState({});
  const navigate = useNavigate();

  const handleStatusTypeChange = (orderId, value) => {
    setSelectedStatusType((prev) => ({
      ...prev,
      [orderId]: value,
    }));
  };

  const handleViewAllClick = () => {
    navigate("/order-management");
  };

  const handleViewClick = () => {
    navigate("/Stock-management");
  };

  return (
    <div className="flex justify-between p-2 bg-white rounded-lg shadow-md gap-6">
      {/* Recent Orders Section */}
      <div className="lg:w-2/3 bg-white rounded-lg p-4 shadow-lg">
        <div className="flex justify-between mb-4">
          <h2 className="text-lg font-semibold">Recent Orders</h2>
          <Button className="border rounded-lg" onClick={handleViewAllClick}>
            View All
          </Button>
        </div>
        <div className="max-h-80 overflow-y-auto">
          <table className="w-full text-left">
            <thead>
              <tr>
                <th className="py-2 text-center">Order ID</th>
                <th className="py-2 text-center">Order Quantity</th>
                <th className="py-2 text-center">Date</th>
                <th className="py-2 text-center">Order Status Type</th>
                <th className="py-2 text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order, index) => {
                const selectedStatus = selectedStatusType[order.orderId] || "fair";
                return (
                  <tr key={index} className="border-b">
                    <td className="py-2 text-center">{order.orderId}</td>
                    <td className="py-2 text-center">{order.totalQuantity}</td>
                    <td className="py-2 text-center">{order.date}</td>
                    <td className="py-2 text-center">
                      <Select
                        value={selectedStatus}
                        onChange={(value) => handleStatusTypeChange(order.orderId, value)}
                        style={{ color: "black" }}
                        className="w-40"
                      >
                        <Option value="fair">Fair</Option>
                        <Option value="instant">Instant</Option>
                      </Select>
                    </td>
                    <td className="py-2 text-center">
                      <span
                        className={`px-3 py-1 rounded-lg ${
                          order.orderStatus[selectedStatus] === "Inprogress"
                            ? "bg-blue-100 text-blue-600"
                            : order.orderStatus[selectedStatus] === "completed"
                            ? "bg-green-100 text-green-600"
                            : "bg-red-100 text-red-600"
                        }`}
                      >
                        {order.orderStatus[selectedStatus]}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Trending/Top Selling Products Section */}
      <div className="lg:w-1/3 bg-white rounded-lg p-4 shadow-md">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold">Trending/Top-selling Products</h2>
          <Button className="border rounded-lg" onClick={handleViewClick}>
            View All
          </Button>
        </div>
        <div>
          {topSellingProducts?.length > 0 ? (
            topSellingProducts.map((product) => (
              <div key={product.productId} className="flex items-center mb-4">
                <Avatar src={product.imageUrl} size={40} className="mr-4" />
                <div>
                  <div>{product.productName}</div>
                  <div className="text-gray-700 text-sm">
                    {product.totalOrders} Orders
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No top-selling products found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardList;
