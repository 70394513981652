import React, { useState, useEffect } from "react";
import { Spin ,  message  } from "antd";
import Addcategory from "./Addcategory";
import CategoryList from "./categorylist";
import AxiosComponent from "../../util/axiosInstance";
import useLogout from "../../util/authUtils";

const CategoryMain = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateCategory, setUpdateCategory] = useState(null);
  const token = localStorage.getItem("Mr_Sasta");
  const logout = useLogout();
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const fetchCategories = async () => {
    const endpoint = "getAlladminCategories";
    try {
      setLoading(true);
      const response = await axiosInstance.get(endpoint);
      const data = response.data;
      if (data.responseCode === 200) {
        setCategories(data.categories);
      } else if (data.responseCode === 400) {
        setError(data.message);
        message.error(data.message);
      } else if (data.responseCode === 401) {
        message.error(data.message);
        logout();
      }
    } catch (err) {
      // setError(err.message);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [token]);

  return (
    <Spin spinning={loading}>
      <div className="flex bg-white m-0">
        <div className="w-1/3">
          <Addcategory
            categories={categories}
            setCategories={setCategories}
            fetchCategories={fetchCategories}
            setLoading={setLoading}
            setError={setError}
            updateCategory={updateCategory}
            setUpdateCategory={setUpdateCategory}
          />
        </div>
        <div className="w-[2px] h-[590px] bg-gray-300 mx-4"></div>
        <div className="w-[80%]">
          <CategoryList
            categories={categories}
            setCategories={setCategories}
            setLoading={setLoading}
            setError={setError}
            setUpdateCategory={setUpdateCategory}
          />
        </div>
      </div>
    </Spin>
  );
};

export default CategoryMain;
