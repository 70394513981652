import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const AxiosComponent = () => {
  const token = useSelector((state) => state.token.token);
  // console.log(token);

  // Function to create axiosInstance with token from Redux
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // Non-authenticated axios instance
  const noauthAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return {
    axiosInstance,
    noauthAxiosInstance,
  };
};

export default AxiosComponent;
