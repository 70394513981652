import React, { useEffect, useState } from "react";
import { Avatar, Button, message, Select } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const { Option } = Select;

const SellerDashboardList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showAllOrders, setShowAllOrders] = useState(false);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [selectedStatusType, setSelectedStatusType] = useState({});

  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const rolesEndpoint = "/recentOrdersApiSeller";
  const stockSellingEndpoint = "/stockAlertAndTopSellingApiSeller";

  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const fetchRecentOrders = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(rolesEndpoint);
        if (response.data.responseCode === 200) {
          setOrders(response.data.orders);
        } else if (response.data.responseCode === 400) {
          message.error(response.data.message);
        } else {
          message.error(error);
        }
      } catch (err) {
        setError(error);
        message.error(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchTopSelling = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(stockSellingEndpoint);
        if (response.data.responseCode === 200) {
          setTopSellingProducts(response.data.topSelling);
        } else if (response.data.responseCode === 400) {
          message.error(response.data.message);
        } else {
          message.error(error);
        }
      } catch (err) {
        setError(error);
        message.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentOrders();
    fetchTopSelling();
  }, []);

  const handleStatusTypeChange = (orderId, value) => {
    setSelectedStatusType((prev) => ({
      ...prev,
      [orderId]: value,
    }));
  };

  const handleViewAllClick = () => {
    navigate("/Seller-Orders"); 
  };

  const handleViewClick = () => {
    navigate("/Seller-Orders"); 
  };

  const displayedOrders = showAllOrders ? orders : orders.slice(0, 4);

  return (
    <div className='flex justify-between p-2 bg-white rounded-lg shadow-md gap-6'>
      {/* Recent Orders Section */}
      <div className="lg:w-2/3 bg-white rounded-lg p-4 shadow-lg">
        <div className="flex justify-between mb-4">
          <h2 className="text-lg font-semibold">Recent Orders</h2>
          <Button className="border rounded-lg" onClick={handleViewAllClick}>
            View All
          </Button>
        </div>
        <div className='max-h-80 overflow-y-auto'>
          <table className='w-full text-left'>
            <thead>
              <tr>
                <th className='py-2 justify-center text-center'>Order ID</th>
                <th className='py-2 justify-center text-center'>
                  Order Quantity
                </th>
                <th className='py-2 justify-center text-center'>Date</th>
                <th className='py-2 justify-center text-center'>
                  Order Status Type
                </th>
                <th className='py-2 justify-center text-center'>Status</th>
              </tr>
            </thead>
            <tbody>
              {displayedOrders.map((order, index) => {
                const selectedStatus =
                  selectedStatusType[order.orderId] || "fair";
                return (
                  <tr key={index} className='border-b'>
                    <td className='py-2 justify-center text-center'>
                      {order.orderId}
                    </td>
                    <td className='py-2 text-center justify-center'>
                      {order.totalQuantity}
                    </td>
                    <td className='py-2 justify-center text-center'>
                      {order.date}
                    </td>
                    <td className='py-2 justify-center text-center'>
                      <Select
                        value={selectedStatus}
                        onChange={(value) =>
                          handleStatusTypeChange(order.orderId, value)
                        }
                        className='w-40'>
                        <Option value='fair'>Fair</Option>
                        <Option value='instant'>Instant</Option>
                      </Select>
                    </td>
                    <td className='py-2 justify-center text-center'>
                      <span
                        className={`px-3 py-1 rounded-lg justify-center text-center ${
                          order.orderStatus[selectedStatus] === "inProgress"
                            ? "bg-blue-100 text-blue-600"
                            : order.orderStatus[selectedStatus] === "delivered"
                            ? "bg-green-100 text-green-600"
                            : "bg-red-100 text-red-600"
                        }`}>
                        {order.orderStatus[selectedStatus]}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Trending/Top Selling Products Section */}
      <div className='lg:w-1/3 bg-white rounded-lg p-4 shadow-md overflow-auto h-[30%]'>
        <h2 className='text-lg font-semibold mb-4'>
          Trending/Top-selling Products
        </h2>
        <div>
          {topSellingProducts.length > 0 ? (
            topSellingProducts.map((product) => (
              <div key={product.productId} className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <Avatar src={product.imageUrl} size={40} className="mr-4" />
                  <div>
                    <div>{product.productName}</div>
                    <div className='text-gray-700 text-sm text-left'>
                      {product.totalOrders} Orders
                    </div>
                  </div>
                </div>
                <Button className="border rounded-lg" onClick={handleViewClick}>
                  View
                </Button>
              </div>
            ))
          ) : (
            <p>No top-selling products found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerDashboardList;
