import React from "react";
import App from "./App";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./containers/Login/Login";
import ForgotPassword from "./containers/forgot_password/forgotPassword";
import Newpassword from "./containers/New_password/Newpassword";

const NextApp = () => {
  const token = useSelector((state) => state.token.token);
  console.log(token);
  return (
    <div>
      {token === null ? (
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/NewPassword' element={<Newpassword />} />
          {/* <Route path='/forgotpassword' element={<ForgotPassword />} /> */}
          {/* <Route path='/newpassword' element={<NewPassword />} /> */}
        </Routes>
      ) : (
        <App />
      )}
    </div>
  );
};

export default NextApp;
