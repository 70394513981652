import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Input } from "antd"; 
const { RangePicker } = DatePicker; 

const Paymenthistorycard = ({ searchInput, setSearchInput, onDateChange, setReportsDate }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (date, dateString) => {
    setFromDate(date);
  };

  const handleToDateChange = (date, dateString) => {
    setToDate(date);
  };

  const onRangeChange = (dates, dateStrings) => {
    console.log("Selected dates: ", dates);
    console.log("Formatted dates: ", dateStrings);
    setReportsDate(dateStrings);
    onDateChange && onDateChange(dateStrings); 
  };

  return (
    <div className="bg-white p-2 flex justify-between items-center pb-3 border-b mt-4">
      <div className="flex items-center space-x-4">
        <Input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search..."
          className="w-52 pl-2 pr-2 py-2 border-gray-300 focus:ring-[#009A00]"
          suffix={<SearchOutlined className="text-gray-400" />}
        />
        <RangePicker
          className="w-56 py-2 border-gray-300 focus:ring-[#009A00]"
          format="YYYY-MM-DD"
          onChange={onRangeChange}
        />
      </div>
    </div>
  );
};

export default Paymenthistorycard;
