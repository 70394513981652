// EditSeller.jsx
import React, { useEffect, useState } from "react";
import { Form, Input, Spin, message, Button } from "antd";
import AxiosComponent from "../../util/axiosInstance";
import { handleProfileImage } from "../../containers/fileupload/fileupload";
import useLogout from "../../util/authUtils";

const EditSeller = ({ Seller, close, fetchSellerData }) => {
  const [form] = Form.useForm();
  const [sellerImage, setSellerImage] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance } = AxiosComponent();
  const logout = useLogout();
  useEffect(() => {
    if (Seller) {
      // Map sellerName to name to match the API payload
      form.setFieldsValue({
        name: Seller.sellerName || "",
        email: Seller.email || "",
        phone: Seller.phone || "",
        gst: Seller.gst || "",
        pan: Seller.pan || "",
        shopName: Seller.shopName || "",
      });
      setSellerImage(Seller.profilePic || "");
    }
  }, [Seller, form]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!["image/png", "image/jpeg"].includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      message.error("Image size should be less than 5MB.");
      return;
    }

    setLoading(true);
    try {
      const imageUrl = await handleProfileImage(event);
      setSellerImage(imageUrl);
      message.success("Image uploaded successfully.");
    } catch (error) {
      message.error("Failed to upload image.");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    const payload = {
      sellerId: Seller._id || Seller.sellerId,
      name: values.name,
      phone: values.phone,
      email: values.email,
      gst: values.gst,
      pan: values.pan,
      shopName: values.shopName,
      profilePic: sellerImage,
    };
  
    setLoading(true);
    try {
      const response = await axiosInstance.put("/editSeller", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.data?.responseCode === 200) {
        message.success(response.data?.message);
        fetchSellerData(); 
        close();
      } else if (response.data?.responseCode === 400) {
        message.error(response.data?.message);
      } else if (response.data?.responseCode === 401) {
        message.error(response.data?.message);
        logout();
      } else {
        message.error(response.data?.message);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Spin spinning={loading}>
      <div className="EditSeller-container">
        <div className="EditSeller-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="edit-profile-form"
          >
            <Form.Item
              name="name"
              label="Seller Name"
              rules={[
                {
                  required: true,
                  message: "Please enter seller name",
                },
                {
                  max: 30,
                  message: "seller name cannot exceed 30 characters.",
                },
                {
                  pattern: /^[A-Za-z0-9 ]+$/,
                  message:
                    "Seller name can only contain letters, numbers, and spaces",
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim().startsWith(" ")) {
                      return Promise.reject(
                        new Error("Seller name cannot start with a space")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              className="mt-0 mb-4"
            >
              <Input
                type="text"
                placeholder="Enter Seller Name"
                maxLength={30}
                onInput={(e) => {
                  let value = e.target.value;
                  value = value.replace(/^\s+/, "");
                  value = value.slice(0, 30);
                  value = value.replace(/[^A-Za-z0-9 ]/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter the email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
              className="mt-0 mb-4"
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  pattern: /^[6-9]\d{9}$/,
                  message: "please enter a valid phone number",
                },
                {
                  max: 10,
                  message: "Phone number cannot exceed 10 digits",
                },
                {
                  validator: (_, value) => {
                    if (value && /\D/.test(value)) {
                      return Promise.reject(
                        new Error("Phone number can only contain numbers")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              className="mt-0 mb-4"
            >
              <Input
                type="text"
                placeholder="Enter Phone number"
                maxLength={10}
                onKeyPress={(event) => {
                  const { key } = event;
                  if (!/^\d$/.test(key)) {
                    event.preventDefault();
                    message.error("Only numbers are allowed in Phone Number.");
                  }
                }}
                onPaste={(event) => {
                  const pastedData = event.clipboardData.getData("Text");
                  if (!/^\d+$/.test(pastedData)) {
                    event.preventDefault();
                    message.error("Only numbers are allowed in Phone Number.");
                  }
                }}
                onInput={(e) => {
                  let value = e.target.value;

                  // Remove non-digit characters
                  value = value.replace(/\D/g, "");

                  // Update the input with the cleaned value
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name="gst"
              label="GST Number"
              rules={[
                {
                  required: false,
                  pattern: /^[0-9]{2}[A-Z0-9]{10}[A-Z0-9]{3}$/,
                  message: "GST Number must follow the correct format",
                },
              ]}
              className="mt-0 mb-4"
            >
              <Input type="text" placeholder="Enter GST Number"  maxLength={15} />
            </Form.Item>
            <Form.Item
              name="pan"
              label="PAN Number"
              rules={[
                {
                  required: false,
                  pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                  message: "PAN Number must follow the correct format",
                },
              ]}
              className="mt-0 mb-4"
            >
              <Input type="text" placeholder="Enter PAN Number" maxLength={10} />
            </Form.Item>
            <Form.Item
              name="shopName"
              label="Shop Name"
              rules={[
                { required: true, message: "Please enter the shop name" },
              ]}
              className="mt-0 mb-4"
            >
              <Input type="text" placeholder="Enter Shop Name" />
            </Form.Item>
            {/* 
            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              {sellerImage ? (
                <div className="FileChange">
                  <div className="image-section">
                    <img
                      src={sellerImage}
                      alt="Seller"
                      className="image-preview"
                      style={{
                        width: "20%",
                        height: "auto",
                        maxWidth: "150px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <label htmlFor="fileInput" className="primary-text pointer">
                    Change Image
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div className="FileUpload">
                  <div className="flex justify-center">
                    <img
                      src="/Assests/Images/image-gallery.png"
                      alt="Seller"
                      className="file-upload"
                      style={{
                        width: "20%",
                        height: "auto",
                        maxWidth: "150px",
                      }}
                    />
                  </div>
                  <p>
                    Add Profile image or{" "}
                    <label
                      htmlFor="fileInput"
                      className="text-[#009A00] font-medium cursor-pointer"
                    >
                      browse
                    </label>
                  </p>
                  <small className="secondary-text">Maximum size: 5MB</small>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </div>
              )}
            </div> */}

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="edit-Seller-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2 cursor-pointer"
                  onClick={close}
                >
                  <p>Cancel</p>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="edit-Seller-submit-button !bg-[#009A00] !hover:bg-[#008000] text-white py-2 rounded-md font-medium p-4"
                >
                  Update Seller
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default EditSeller;
