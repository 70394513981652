// ProductList.jsx
import { Avatar, Switch, Pagination, message, Modal, Spin, Input } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import AxiosComponent from "../../util/axiosInstance";
import SKUContents from "./SKUContents.js";
import { useNavigate } from "react-router-dom";

const ProductList = ({
  products,
  fetchProducts,
  setLoading,
  loading,
  setError,
  handleSkuEdit,
  setAddSkuData,
  setSkuProductId,
  skuProductId,
  setProductId,
}) => {
  const [SKUModalVisible, setSKUModalVisible] = useState(false);
  const [SkuPacketModalVisible, setSkuPacketModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [skuLoading, setSkuLoading] = useState(false);
  const [skuData, setSkuData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const navigate = useNavigate();

  const pageSize = 10;
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const toggleStatus = async (product) => {
    console.log(product);
    const ToggleStatusEndpoint = "toggleAdminProductStatus";
    setLoading(true);
    try {
      setLoading(true);
      const requestData = {
        productId: product?.productId,
      };
      const response = await axiosInstance.put(
        ToggleStatusEndpoint,
        requestData
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        if (data.responseCode === 200) {
          message.success(data.message);
          fetchProducts();
        } else if (data.responseCode === 400) {
          message.error(data.message);
        }
      } else {
        const errorData = await response.json();
        console.error("Error posting data:", errorData);
        throw { error: errorData, loading: false };
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to toggle status");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const fetchProductBySkuId = async () => {
    console.log("Product ID:", skuProductId);
    const SkuEndpoint = "listOfAdminSKUsByProductId";
    try {
      const response = await axiosInstance.post(SkuEndpoint, {
        productId: skuProductId,
      });

      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        if (data.responseCode === 200) {
          setSkuData(data.skus);
        } else if (data.responseCode === 400) {
          message.error(data.message);
        }
      } else {
        const errorData = await response.json();
        console.error("Error posting data:", errorData);
        throw { error: errorData, loading: false };
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch SKUs");
    } finally {
      setSkuLoading(false);
    }
  };

  // const closeModal = () => {
  //   setshowSkuModal(false);
  //   setSkuData([]);
  // };

  const handleEditSkuModal = (productId) => {
    console.log(productId?.productId);
    setSkuProductId(productId?.productId);
    setSelectedProductId(productId?.productId); // Set the selected product ID
    setSKUModalVisible(true);
  };

  useEffect(() => {
    if (skuProductId) {
      fetchProductBySkuId();
    }
  }, [skuProductId, setSkuProductId]);

  const filteredProducts = products.filter((product) =>
    product.productName.toLowerCase().includes(searchInput.toLowerCase())
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredProducts.slice(startIndex, endIndex);

  const handleAddProduct = () => {
    navigate("/products/add-product", {
      state: {
        productId: "",
        skuEditData: {},
        addSkuData: [],
      },
    });
  };

  return (
    <div className='p-0 bg-white !overflow-auto'>
      <div className='flex flex-row justify-between items-center'>
        <div className='Header-section flex justify-between '>
          <p className='text-lg font-medium'>Product List</p>
        </div>
        <div className='flex gap-4 items-center'>
          <Input
            type='text'
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setCurrentPage(1);
            }}
            suffix={<SearchOutlined className='text-gray-400' />}
            placeholder='Search Product Name '
            className='pl-4 pr-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#009A00]'
          />
          <button
            className='bg-[#009A00] w-full p-2 text-white flex gap-2 items-center justify-center rounded-md cursor-pointer'
            onClick={() => {
              handleAddProduct();
            }}>
            <PlusOutlined />
            <span>Add Product</span>
          </button>
        </div>
      </div>
      <table className='min-w-full mt-3 border border-[#d7d9da]'>
        <thead className='border-t p-2 mt-3'>
          <tr className='border-b'>
            <th className='p-2'>S.No</th>
            <th className='p-2 text-left'>Product Name</th>
            <th className='p-2'>Description</th>
            <th className='p-2'>SKU</th>
            <th className='p-2'>Stock (Kgs)</th>
            <th className='p-2'>Status</th>
            <th className='p-2'>Edit</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((item, index) => (
            <tr key={index} className='border-b'>
              <td className=' p-2 items-center'>{startIndex + index + 1}</td>
              <td className=' p-2 flex items-center justify-start'>
                {item?.productImage ? (
                  <Avatar
                    size={35}
                    shape='circle'
                    src={item?.productImage}
                    className='mr-2'
                  />
                ) : (
                  <Avatar
                    size={35}
                    shape='circle'
                    className='mr-2 bg-gray-200 text-black capitalize'>
                    {item?.productName?.charAt(0)?.toUpperCase()}
                  </Avatar>
                )}
                {item?.productName}
              </td>
              <td className=' p-2'>{item?.description}</td>
              <td
                className=' p-2 text-blue-600 cursor-pointer'
                onClick={() => handleEditSkuModal(item)}>
                {item?.skus?.length}
              </td>
              <td className=' p-2 cursor-pointer'>
                {item?.totalQuntity || "--"}
              </td>
              <td className=' p-2'>
                <Switch
                  checked={item?.status === "active"}
                  onChange={() => toggleStatus(item)}
                  style={{
                    backgroundColor:
                      item?.status === "active" ? "#009A00" : "#d9d9d9",
                  }}
                />
              </td>
              <td className=' text-center items-center'>
                <div className='flex justify-center'>
                  <MdOutlineEdit
                    className='text-[#009A00] cursor-pointer'
                    size={20}
                    onClick={() => {
                      setProductId(item?.productId);
                      navigate("/products/add-product", {
                        state: {
                          productId: item?.productId,
                          skuEditData: {},
                          addSkuData: [],
                        },
                      });
                    }}
                    // Add edit functionality here
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {paginatedData?.length === 0 && (
        <div className='no-data-message mt-3'>
          <p>No data found</p>
        </div>
      )}
      <div className='Pagination-container mt-3 justify-end flex p-2 items-center'>
        {paginatedData?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={products?.length}
            onChange={handleChangePage}
            // showSizeChanger
            // onShowSizeChange={handleChangePage}
          />
        )}
      </div>
      {/* <Modal
        visible={showSkuModal}
        open={showSkuModal}
        centered
        onCancel={closeModal}
        footer={null}
        width={850}>
        <SKUContent
          skuData={skuData}
          setSkuData={setSkuData}
          handleSkuEdit={handleSkuEdit}
        />
      </Modal> */}
      <Modal
        visible={SKUModalVisible && selectedProductId === skuProductId}
        centered
        footer={null}
        onCancel={() => setSKUModalVisible(false)}
        className='custom-modal'
        width={900}
        closeIcon={null}>
        <SKUContents
          skuProductId={skuProductId}
          handleSkuEdit={handleSkuEdit}
          setSKUModalVisible={setSKUModalVisible}
          setSkuProductId={setSkuProductId}
          // api={fetchProductBySkuId}
          // data={skuData}
          setAddSkuData={setAddSkuData}
          skuData={skuData}
          setSkuData={setSkuData}
          fetchProductBySkuId={fetchProductBySkuId}
          loading={loading}
          setLoading={setLoading}
        />
      </Modal>
      {/* <Modal
        visible={SkuPacketModalVisible && selectedProductId === skuProductId}
        centered
        footer={null}
        onCancel={() => setSkuPacketModalVisible(false)}
        className='custom-modal'
        closeIcon={null}>
        <SKUPacketContent
          skuProductId={skuProductId}
          setSkuPacketModal={setSkuPacketModalVisible}
          fetchProductList={fetchProductList}
          setSkuProductId={setSkuProductId}
          skuData={skuData}
          setSkuData={setSkuData}
          fetchProductBySkuId={fetchProductBySkuId}
          loading={loading}
          setLoading={setLoading}
        />
      </Modal> */}
    </div>
  );
};

export default ProductList;
