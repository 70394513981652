import React, { useState } from 'react';
import { Input, Button, message, Form } from 'antd';
import AxiosComponent from "../../../util/axiosInstance";

const AddStock = ({ productName, quantity, productId, skuId, onClose  , fetchStockProductDetails}) => {
  const { axiosInstance } = AxiosComponent();
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const [totalQuantity, setTotalQuantity] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}/restocSellerkProductSku`,
        {
          productId,
          skus: [
            {
              skuId,
              totalQuantity: parseInt(totalQuantity, 10),
            },
          ],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.responseCode === 200) {
        message.success(response.data.message );
        onClose();
        fetchStockProductDetails();
      } else {
        message.error(response.data.message );
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className="p-6 max-w-md mx-auto  rounded-2xl " name="Add Stock">
      <Form layout="vertical" className="space-y-4">
        <Form.Item label="Product Name">
          <Input
            value={productName}
            disabled
            className="border-gray-300 focus:border-[#009A00] focus:ring-[#009A00] rounded-md"
          />
        </Form.Item>
        <Form.Item label="Current Sku">
          <Input
            value={quantity}
            disabled
            className="border-gray-300 focus:border-[#009A00] focus:ring-[#009A00] rounded-md"
          />
        </Form.Item>
        <Form.Item label="Total Quantity to Add">
          <Input
            type="number"
            placeholder="Enter quantity"
            value={totalQuantity}
            onChange={(e) => setTotalQuantity(e.target.value)}
            className="border-gray-300 focus:border-[#009A00] focus:ring-[#009A00] rounded-md"
          />
        </Form.Item>
        <button
          onClick={handleSubmit}
          className="w-full bg-[#009A00] hover:bg-[#007d00] text-white font-semibold py-2 rounded-md"
        >
          Submit
        </button>
      </Form>
    </div>
  );
};

export default AddStock;
