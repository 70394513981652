import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import SellerSubAddCategory from "./Addcategory";
import SellerSubCategoryList from "./categorylist";

const SellerSubCategoryMain = () => {
  const [categories, setCategories] = useState([]); 
  const [subCategories, setSubCategories] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [updateCategory, setUpdateCategory] = useState(null);
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();


  const fetchSubCategoriesSeller = async (categoryId) => {
    if (!categoryId) return;
    try {
      setLoading(true);
      const response = await axiosInstance.post("listSubCategories", { categoryId });
      const data = response.data;
  
      if (data.responseCode === 200) {
        setSubCategories(data.subcategories);
      } else if (data.responseCode === 400) {
        setError(data.message);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchCategoriesSeller = async () => {
    const endpoint = "getAllSellersCategories";
    try {
      setLoading(true);
      const response = await axiosInstance.get(endpoint, {});

      const data = response.data;

      if (data.responseCode === 200) {
        const activeCategories = data.categories.filter(
          (category) => category.status === "active"
        );
        setCategories(activeCategories);
        console.log(categories)
      } else if (data.responseCode === 400) {
        setError(data.message);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCategorySellerChange = (categoryId) => {
    setSubCategories([]); 
    setSelectedCategoryId(categoryId); 
  };

  useEffect(() => {
    fetchCategoriesSeller();
  }, [token]);
  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategoriesSeller(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  
  return (
    <Spin spinning={loading}>
      <div className="flex bg-white m-0">
        <div className="w-1/3">
          <SellerSubAddCategory
            categories={categories}
            setCategories={setCategories}
            handleCategorySellerChange={handleCategorySellerChange}
            setSelectedCategoryId={setSelectedCategoryId}
            fetchSubCategoriesSeller={fetchSubCategoriesSeller}
            fetchCategoriesSeller={fetchCategoriesSeller}
            setLoading={setLoading}
            setError={setError}
            updateCategory={updateCategory}
            setUpdateCategory={setUpdateCategory}
          />
        </div>
        <div className="w-[2px] h-[590px] bg-gray-300 mx-4"></div>
        <div className="w-[80%]">
          <SellerSubCategoryList
            categories={subCategories} 
            setCategories={setSubCategories}
            setLoading={setLoading}
            setError={setError}
            setUpdateCategory={setUpdateCategory}
          />
        </div>
      </div>
    </Spin>
  );
};

export default SellerSubCategoryMain;
