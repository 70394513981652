import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import Paymenthistorycard from "./Paymenthistorycard";
import Paymenthistorylist from "./Paymenthistorylist";
import PaymentHprofile from "./paymentHprofile";
import AxiosComponent from "../../util/axiosInstance";
import { useParams } from "react-router-dom";


const PaymentHistoryMain = () => {
  const { sellerId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sellerPaymentData, setSellerPaymentData] = useState([]);
  const [error, setError] = useState("");
  const { axiosInstance } = AxiosComponent();
  


  const fetchSellerPaymentListData = async () => {
    const sellerpaymentListEndpoint = "/SellerPaymentInfoList";
    try {
      setLoading(true);
      const response = await axiosInstance.post(sellerpaymentListEndpoint, {
        sellerId,
      });
  
      const data = response.data;
  
      if (response.status === 200 && data.responseCode === 200) {
        setSellerPaymentData(data?.data);
      } else if (response.status === 400) {
        message.error(data.message );
      } else {
        message.error(data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.message);
      } else {
        message.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (sellerId) {
      fetchSellerPaymentListData();
    }
  }, [sellerId]);

  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <PaymentHprofile paymentHistoryData={sellerPaymentData} />
        </div>
        <div>
          <Paymenthistorycard />
        </div>
        <div>
          <Paymenthistorylist paymentHistoryData={sellerPaymentData} />
        </div>
      </div>
    </Spin>
  );
};

export default PaymentHistoryMain;
