import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import HelpCenterList from "./HelpCenterList";
import AxiosComponent from "../../util/axiosInstance";

const HelpCenterMain = () => {
  const [loading, setLoading] = useState(true);
  const [helpRequests, setHelpRequests] = useState([]);
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance } = AxiosComponent();

  const fetchHelpRequests = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/getHelpRequests", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.responseCode === 200) {
        setHelpRequests(response.data.helpRequests);
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || "An error occurred";
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

 
  useEffect(() => {
    fetchHelpRequests();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="p-4">
        <HelpCenterList
          helpRequests={helpRequests}
          fetchHelpRequests={fetchHelpRequests}
        />
      </div>
    </Spin>
  );
};

export default HelpCenterMain;
