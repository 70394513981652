import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["selectedPujas", "userProfile", "token"],
};
const pujaInitialState = "Dashboard";

const selectedPujasReducer = (state = pujaInitialState, action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_PUJAS":
      return action.payload;
    case "LOGOUT_USER":
      return pujaInitialState;
    default:
      return state;
  }
};

const initialState = {};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_PROFILE":
      return action.payload;
    case "LOGOUT_USER":
      return initialState;
    default:
      return state;
  }
};

const tokenInitialState = {
  token: null,
};
const tokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "UPDATE_TOKEN":
      return { ...state, token: action.payload };
    case "LOGOUT_USER":
      return { token: null };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  userProfile: userProfileReducer,
  token: tokenReducer,
  selectedPujas: selectedPujasReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
