import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import AxiosComponent from "../../util/axiosInstance";
import AddProduct from "./Addproduct";
import ProductList from "./productlist";
import "./Products.css";

const ProductMain = () => {
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const apiUrl = `${baseURL}/getListOfAdminProducts`;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skuProductId, setSkuProductId] = useState("");
  const [error, setError] = useState(null);
  const [skuEditData, setSkuEditData] = useState({});
  const [addSkuData, setAddSkuData] = useState([]);
  const [productId, setProductId] = useState("");
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      if (data.responseCode === 200) {
        setProducts(data.data);
      } else if (data.responseCode === 400) {
        setError(data.message);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSkuEdit = (data) => {
    console.log(data);
    setSkuEditData(data);
  };

  return (
    <Spin spinning={loading}>
      <div className='flex bg-white m-0 overflow-y-auto scrollbar-hide'>
        {/* <div className='w-[30%] overflow-y-auto scrollbar-hide'>
          <AddProduct
            fetchProducts={fetchProducts}
            setLoading={setLoading}
            setError={setError}
            skuEditData={skuEditData}
            setSkuEditData={setSkuEditData}
            addSkuData={addSkuData}
            setAddSkuData={setAddSkuData}
            productId={productId}
            setProductId={setProductId}
          />
        </div> */}
        {/* <div className='w-[2px] h-[850px] bg-gray-300 mx-4'></div> */}
        <div className='w-[96%] mx-auto'>
          <ProductList
            products={products}
            fetchProducts={fetchProducts}
            setLoading={setLoading}
            loading={loading}
            setError={setError}
            handleSkuEdit={handleSkuEdit}
            setAddSkuData={setAddSkuData}
            setSkuProductId={setSkuProductId}
            skuProductId={skuProductId}
            productId={productId}
            setProductId={setProductId}
          />
        </div>
      </div>
    </Spin>
  );
};

export default ProductMain;
