import React, { useState, useEffect } from "react";
import { Button, Pagination, Modal, Spin, Select, message } from "antd";
import AxiosComponent from "../../util/axiosInstance";

const { Option } = Select;

const OrderMListBody = ({ ordersList, fetchOrders }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedStatusPerOrder, setSelectedStatusPerOrder] = useState({});
  const [statusChange, setStatusChange] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [currentStatusType, setCurrentStatusType] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const { axiosInstance } = AxiosComponent();
  const [loading, setLoading] = useState(false);

  const pageSize = 10;

  const handleInvoice = (url) => {
    setInvoiceUrl(url);
    setInvoiceModal(true);
  };

  const toggleRowExpand = (orderId) => {
    setExpandedRows((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const getBackgroundColorClass = (status) => {
    switch (status) {
      case "delivered":
        return "!bg-green-600";
      case "Inprogress":
        return "!bg-blue-400";
      case "returned":
        return "bg-red-500";
      case "failed":
        return "bg-red-500";
      case "damaged":
        return "bg-red-500";
      case "completed":
        return "bg-green-500";
      case "outForDelivery":
        return "bg-green-500";
      case "cancelled":
        return "bg-red-500";
      case "confirmed":
        return "bg-green-500";
      default:
        return "bg-gray-200";
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [ordersList]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleStatusChange = (value, orderBId, statusType) => {
    setCurrentOrderId(orderBId);
    setCurrentStatusType(statusType);
    setNewStatus(value);
    setStatusChange(true);
  };

  const statusUpdate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.put("/UpdateOrderStatus", {
        orderBookingId: currentOrderId,
        newStatus,
        statusType: currentStatusType,
      });

      if (response.status === 200 && response.data.responseCode === 200) {
        message.success(response.data.message);
        setSelectedStatusPerOrder((prev) => ({
          ...prev,
          [currentOrderId]: newStatus,
        }));
        setStatusChange(false);
        fetchOrders();
      } else if (response.status === 400) {
        message.error(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = ordersList?.slice(startIndex, endIndex);

  return (
    <div className="w-full bg-white">
      <table className="w-full border-b border-gray-300">
        <thead className="min-w-full">
          <tr className="border-b">
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-center">Order ID</th>
            <th className="border-b p-4 text-center">Customer</th>
            {/* Modified Headers: Replace Total with Fair and Instant */}
            <th className="border-b p-4 text-center">Price</th>{" "}
            {/* Updated Line */}
            <th className="border-b p-4 text-center">Products</th>{" "}
            {/* Updated Line */}
            <th className="border-b p-4 text-center">Order Type</th>
            <th className="border-b p-4 text-center">Status</th>
            <th className="border-b p-4 text-center">Invoice</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((order, index) => {
            const deliveryTypes = order.InfoData.deliveryType;
            const hasFair = deliveryTypes.includes("Fair");
            const hasInstant = deliveryTypes.includes("Instant");
            const fairStatus = order.InfoData.nextFairStatus;
            const instantStatus = order.InfoData.nextInstantStatus;

            return (
              <React.Fragment key={order.orderBId}>
                <tr>
                  <td className="border-b p-4">{startIndex + index + 1}</td>
                  <td className="border-b p-4">
                    {hasFair && hasInstant ? (
                      <Button
                        type="link"
                        onClick={() => toggleRowExpand(order.orderId)}
                      >
                        {order.orderId}
                      </Button>
                    ) : (
                      <span>{order.orderId}</span>
                    )}
                  </td>
                  <td className="border-b p-4">{order.customerName || "--"}</td>

                  {/* Modified Price Column */}
                  <td className="border-b p-4">
                    {hasFair
                      ? order.InfoData.fairPrice || "--"
                      : hasInstant
                      ? order.InfoData.instantPrice || "--"
                      : "--"}
                  </td>
                  <td className="border-b p-4">
                    {hasFair
                      ? order.InfoData.fairProductsCount || "--"
                      : hasInstant
                      ? order.InfoData.instantProductsCount || "--"
                      : "--"}
                  </td>

                  <td className="border-b p-4">
                    <span>
                      {hasFair ? "Fair" : hasInstant ? "Instant" : ""}
                    </span>
                  </td>
                  <td className="border-b p-4">
                    <Select
                      placeholder="Select Status"
                      size="medium"
                      value={
                        selectedStatusPerOrder[order.orderId] ||
                        (hasFair ? fairStatus : instantStatus)
                      }
                      onSelect={(value) =>
                        handleStatusChange(
                          value,
                          order.orderBId,
                          hasFair ? "fair" : "instant"
                        )
                      }
                      popupMatchSelectWidth={160}
                      className={`${getBackgroundColorClass(
                        hasFair && hasInstant
                          ? fairStatus
                          : hasFair
                          ? fairStatus
                          : instantStatus
                      )} !text-white rounded font-medium order-management`}
                      style={{ width: 120 }}
                    >
                      <Option value="Inprogress">In Progress</Option>
                      <Option value="confirmed">Confirmed</Option>
                      <Option value="cancelled">Cancelled</Option>
                      <Option value="outForDelivery">Out for Delivery</Option>
                      <Option value="delivered">Delivered</Option>
                      <Option value="failed">Failed</Option>
                      <Option value="returned">Return</Option>
                      <Option value="completed">Completed</Option>
                    </Select>
                  </td>
                  <td className="border-b p-4">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInvoice(
                          order.invoice.invoiceURLInstant ||
                            order.invoice.invoiceURLFair ||
                            ""
                        );
                      }}
                      className="!text-white font-medium border-none !bg-[#009A00]"
                    >
                      View
                    </Button>
                  </td>
                </tr>
                {/* Expanded row for additional details */}
                {expandedRows.includes(order.orderId) &&
                  hasFair &&
                  hasInstant && (
                    <tr>
                      <td colSpan="8" className="border-b p-2 w-full">
                        <table className="w-full">
                          <tbody>
                            <tr>
                              <td className="p-4">{order.custom || " "}</td>
                              <td className="p-4 w-[6%]">
                                {order.InfoData.instantPrice}
                              </td>
                              <td className="p-4 w-[13%]">
                                {order.InfoData.instantProductsCount}
                              </td>
                              <td className="p-4 w-[13%]">
                                {order.InfoData.deliveryType[1] || "--"}
                              </td>
                              <td className="p-4 w-[21%]">
                                <Select
                                  placeholder="Select Status"
                                  size="medium"
                                  value={
                                    selectedStatusPerOrder[order.orderId] ||
                                    instantStatus
                                  }
                                  onSelect={(value) =>
                                    handleStatusChange(
                                      value,
                                      order.orderBId,
                                      "instant"
                                    )
                                  }
                                  popupMatchSelectWidth={160}
                                  className={`${getBackgroundColorClass(
                                    instantStatus
                                  )} text-white rounded order-management`} // Modified: Using className with Tailwind classes
                                  style={{
                                    width: 120, // Width kept in style as it’s specific and doesn't affect Tailwind classes
                                  }}
                                >
                                  <Option value="Inprogress">
                                    In Progress
                                  </Option>
                                  <Option value="confirmed">Confirmed</Option>
                                  <Option value="cancelled">Cancelled</Option>
                                  <Option value="outForDelivery">
                                    Out for Delivery
                                  </Option>
                                  <Option value="delivered">Delivered</Option>
                                  <Option value="failed">Failed</Option>
                                  <Option value="returned">Return</Option>
                                  <Option value="completed">Completed</Option>
                                </Select>
                              </td>
                              <td className="p-4 w-[10%]">
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleInvoice(
                                      order.invoice.invoiceURLInstant || ""
                                    );
                                  }}
                                  className="!text-white font-medium border-none !bg-[#009A00]"
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      <div className="flex justify-end mt-2">
        {ordersList?.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={ordersList.length}
            showSizeChanger
            onShowSizeChange={handleChangePage}
            onChange={handleChangePage}
          />
        )}
      </div>

      <Modal
        open={invoiceModal}
        onCancel={() => setInvoiceModal(false)}
        footer={null}
        centered
      >
        <iframe src={invoiceUrl} width="100%" height="550px" />
        <button
          onClick={() => {
            setInvoiceModal(false);
            setInvoiceUrl("");
          }}
          className="mt-2 bg-red-500 text-white py-1 px-3 rounded"
        >
          Close
        </button>
      </Modal>

      <Modal
        visible={statusChange}
        onCancel={() => setStatusChange(false)}
        centered
        footer={null}
        className="custom-modal"
      >
        <Spin spinning={loading}>
          <div className="flex flex-col items-center p-6 bg-white rounded-lg ">
            <h5 className="text-lg font-semibold text-center mb-4">
              Are you sure you want to change the status of this order to "
              {newStatus}"?
            </h5>
            <div className="flex justify-between w-full mt-4">
              <button
                className="flex-1 mx-2 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-200"
                onClick={() => setStatusChange(false)}
              >
                <p>No</p>
              </button>
              <button
                className="flex-1 mx-2 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                onClick={statusUpdate}
              >
                <p>Yes</p>
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default OrderMListBody;
