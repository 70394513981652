import React from 'react'
import ProfilePage from './ProfilePage'

const ProfileMain = () => {
  return (
    <div>
      <ProfilePage />
    </div>
  )
}

export default ProfileMain
