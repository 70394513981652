import React from "react";
import { Spin, Switch, message } from "antd";
import AxiosComponent from "../../util/axiosInstance";
import { MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const SKUContents = ({
  skuProductId,
  handleSkuEdit,
  setSKUModalVisible,
  setSkuProductId,
  setAddSkuData,
  skuData,
  setSkuData,
  fetchProductBySkuId,
  loading,
  setLoading,
}) => {
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();
  const navigate = useNavigate();

  const toggleSkuStatus = async (sku) => {
    console.log(sku);
    setLoading(true);
    try {
      const Endpoint = "toggleAdminSKUStatus";
      const response = await axiosInstance.put(
        Endpoint,
        { skuId: sku._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
          },
        }
      );

      if (response.data.responseCode === 200) {
        message.success(response.data.message);
        fetchProductBySkuId();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      message.error(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(skuData);

  return (
    <Spin spinning={loading}>
      <div className='SKU-modal-container bg-white p-2'>
        <div className='SKU-modal-header flex justify-between items-center mb-4'>
          <h6 className='text-lg font-semibold'>SKU's</h6>
          <div
            className='flex items-center bg-[#009A00] text-white px-3 py-1 rounded cursor-pointer'
            onClick={() => {
              setAddSkuData(skuData);
              navigate("/products/add-product", {
                state: {
                  productId: "",
                  skuEditData: {},
                  addSkuData: skuData,
                },
              });
              setSKUModalVisible(false);
            }}>
            <p className='mr-2'>+</p>
            <p>Add SKU</p>
          </div>
        </div>
        <div className='SKU-modal-table'>
          <table className='w-full text-left border-collapse border-2 border-gray-200'>
            <thead>
              <tr className='border-b-2 border-gray-200'>
                <th className='py-2 text-center'>S.No</th>
                {/* <th className='py-2 text-center'>Product Name</th> */}
                <th className='py-2 text-center'>Quantity</th>
                <th className='py-2 text-center'>Fair Price</th>
                <th className='py-2 text-center'>Instant Price</th>
                <th className='py-2 text-center'>F.Discount(%)</th>
                <th className='py-2 text-center'>I.Discount(%)</th>
                <th className='py-2 text-center'>GST(%)</th>
                <th className='py-2 text-center'>Total Quantity</th>
                <th className='py-2 text-center'>Max Limit Purchase</th>
                <th className='py-2 text-center'>Edit</th>
                <th className='py-2 text-center'>Status</th>
              </tr>
            </thead>
            <tbody>
              {skuData?.length > 0 ? (
                skuData.map((item, index) => (
                  <tr key={item._id} className='border-b'>
                    <td className='py-2 text-center'>{index + 1}</td>
                    {/* <td className='py-2 text-center'>{item.productName}</td> */}
                    <td className='py-2 text-center'>
                      {item?.quantityValue && item?.unit
                        ? `${item.quantityValue} ${item.unit}`
                        : "--"}
                    </td>
                    <td className='py-2 text-center'>
                      {item?.fairPrice || "--"}
                    </td>
                    <td className='py-2 text-center'>
                      {item.instantPrice || "--"}
                    </td>
                    <td className='py-2 text-center'>
                      {item?.discount?.fairPriceDiscount?.percentage || 0}
                    </td>
                    <td className='py-2 text-center'>
                      {item?.discount?.instantPriceDiscount?.percentage || 0}
                    </td>
                    <td className='py-2 text-center'>
                      {item?.gstpercentage?.fairPriceGST || 0}
                    </td>
                    <td className='py-2 text-center'>
                      {item?.totalQuantity || "--"}
                    </td>
                    <td className='py-2 text-center'>
                      {item?.maxLimitPurchase || "--"}
                    </td>
                    <td className='py-2 text-center'>
                      <MdOutlineEdit
                        onClick={() => {
                          handleSkuEdit(item);
                          navigate("/products/add-product", {
                            state: {
                              productId: "",
                              skuEditData: item,
                              addSkuData: [],
                              skuData: skuData,
                            },
                          });
                          setSKUModalVisible(false);
                          setSkuProductId("");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                    <td className='py-2 text-center'>
                      <Switch
                        checked={item.status === "active"}
                        onChange={() => toggleSkuStatus(item)}
                        style={{
                          backgroundColor:
                            item.status === "active" ? "#009A00" : "#d9d9d9",
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='8' className='py-4 text-center text-gray-500'>
                    No SKUs found for this product.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Spin>
  );
};

export default SKUContents;
