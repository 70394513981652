import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import Customercard from "./Customercard";
import CustomerListBody from "./CustomersList";

const SellerCustomerMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [customers, setCustomers] = useState([]);
  const [filter, setFilter] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [activeCount, setActiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const { axiosInstance } = AxiosComponent();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const fetchCustomerData = async () => {
    try {
      const token = localStorage.getItem("Mr_Sasta");
      const SellerId = (localStorage.getItem("_id"));

      console.log("Seller ID:", SellerId);
      const sellerId = user?._id;

      if (!sellerId) {
        message.error("Seller ID is missing");
        return;
      }

      setLoading(true);

      const response = await axiosInstance.post(
        `${baseURL}/getUsersBySellerId`,
        {
          sellerId, 
        }
      );

      if (response.status === 200) {
        const data = response.data?.data || [];

        setCustomers(data);
        setTotalCount(data.length);

        const activeCustomers = data.filter(
          (customer) => customer.status === "active"
        );
        const inactiveCustomers = data.filter(
          (customer) => customer.status === "inactive"
        );
        setActiveCount(activeCustomers.length);
        setInactiveCount(inactiveCustomers.length);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const filterCustomersBySearchInput = (customers) => {
    if (!searchInput) return customers;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return customers.filter((customer) => {
      const { userName, email, phone, address } = customer;

      return (
        (userName && userName.toLowerCase().includes(lowerCaseSearchInput)) ||
        (email && email.toLowerCase().includes(lowerCaseSearchInput)) ||
        (phone && phone.includes(searchInput)) ||
        (address && address.toLowerCase().includes(lowerCaseSearchInput))
      );
    });
  };

  const filteredCustomers = customers.filter((customer) => {
    if (filter === "All") {
      return true;
    } else if (filter === "Active") {
      return customer.status === "active";
    } else if (filter === "Inactive") {
      return customer.status === "inactive";
    }
    return false;
  });

  const searchedAndFilteredCustomers =
    filterCustomersBySearchInput(filteredCustomers);

  return (
    <Spin spinning={loading}>
      <div>
        <Customercard
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          fetchCustomerData={fetchCustomerData}
          filter={filter}
          setFilter={setFilter}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          totalCount={totalCount}
          customers={searchedAndFilteredCustomers}
        />
        <CustomerListBody
          customers={searchedAndFilteredCustomers}
          fetchCustomerData={fetchCustomerData}
          setCustomers={setCustomers}
        />
      </div>
    </Spin>
  );
};

export default SellerCustomerMain;
