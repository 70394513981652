import React from "react";
import MainApp from "../src/routes/index";
import { Layout } from "antd";
import "./App.css";
import { useSelector } from "react-redux";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";
import SidebarSeller from "./componentsSeller/SidebarSeller/SideBarSel";
import { ConfigProvider } from "antd";

const { Content } = Layout;

const App = () => {
  const token = useSelector((state) => state.token.token);
  console.log(token);
  const source = localStorage.getItem("Source");
  console.log(source);
  const renderSidebar = () => {
    if (source === "AdminPortal") {
      return <Sidebar />;
    } else if (source === "Seller") {
      return <SidebarSeller />;
    } else {
      return null;
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#009A00",
          // colorBorder: "#e8e8e8",
          // Alias Token
          // colorBgContainer: "#fff",
        },
      }}>
      <Layout style={{ minHeight: "100vh" }}>
        {renderSidebar()}
        <Layout>
          <Header />
          <Layout>
            <Content style={{ margin: "16px", overflowY: "auto" }}>
              <div
                className='layout-content-section'
                style={{
                  height: "calc(100vh - 64px - 53px)",
                  overflowY: "auto",
                }}>
                <MainApp />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
// App
export default App;
