import React, { useState } from "react";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker, Input, Modal, Button } from "antd";
import AddEmployee from "./AddEmployee";

const { RangePicker } = DatePicker;

const EmployeeCard = ({
  fetchEmployeeData,
  rolesData,
  activeCount,
  totalCount,
  employData,
  filter,
  setFilter,
  inactiveCount,
  searchInput,
  setSearchInput,
  setReportsDate,
  reportsDate,
}) => {
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const handleFromDateChange = (date, dateString) => {
    setFromDate(date);
  };

  const handleToDateChange = (date, dateString) => {
    setToDate(date);
  };

  const onDateChange = (dates, dateStrings) => {
    console.log("Selected dates: ", dates);
    console.log("Formatted dates: ", dateStrings);
    fetchEmployeeData(dateStrings);
    setReportsDate(dateStrings);
  };
  const close = () => {
    setShowAddEmployeeModal(false);
  };

  return (
    <div className="bg-white p-2 flex justify-between items-center pb-3 border-b">
      <div className="flex items-center space-x-4">
        <Input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search Employee"
          className="w-52 pl-2 pr-2 py-2 border-gray-300 focus:ring-[#009A00]"
          suffix={<SearchOutlined className="text-gray-400" />}
        />
        <RangePicker
          className="w-56 py-2 border-gray-300 focus:ring-[#009A00]"
          format="YYYY-MM-DD"
          onChange={onDateChange}
          value={
            reportsDate.length === 2 ? [reportsDate[0], reportsDate[1]] : []
          }
          allowClear
        />
      </div>
      <div className="flex space-x-2 w-full ml-3">
        <button
          className={`px-6 py-2 rounded-full border transition-all ${
            filter === "All"
              ? "bg-[#0ACF83] text-white border-[#0ACF83]"
              : "bg-white text-gray-500 border-gray-300"
          }`}
          onClick={() => handleFilterClick("All")}
        >
          ALL ({totalCount})
        </button>
        <button
          className={`px-6 py-2 rounded-full border transition-all ${
            filter === "Active"
              ? "bg-[#0ACF83] text-white border-[#0ACF83]"
              : "bg-white text-gray-500 border-gray-300"
          }`}
          onClick={() => handleFilterClick("Active")}
        >
          ACTIVE ({activeCount})
        </button>
        <button
          className={`px-6 py-2 rounded-full border transition-all ${
            filter === "Inactive"
              ? "bg-[#0ACF83] text-white border-[#0ACF83]"
              : "bg-white text-gray-500 border-gray-300"
          }`}
          onClick={() => handleFilterClick("Inactive")}
        >
          INACTIVE ({inactiveCount})
        </button>
      </div>
      <div className="flex justify-end">
        <Button
          className="bg-[#009A00] text-white flex items-center px-6 py-5 rounded hover:bg-[#008000] transition-all"
          onClick={() => setShowAddEmployeeModal(true)}
        >
          <PlusOutlined className="mr-2" />
          Add Employee
        </Button>
      </div>
      <Modal
        visible={showAddEmployeeModal}
        centered
        onCancel={() => setShowAddEmployeeModal(false)}
        title="Add Employee"
        footer={null}
        className="custom-modal h-[80%] overflow-auto rounded"
      >
        <AddEmployee
          fetchEmployeeData={fetchEmployeeData}
          rolesData={rolesData}
          close={close}
        />
      </Modal>
    </div>
  );
};

export default EmployeeCard;
