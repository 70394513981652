import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import * as XLSX from "xlsx";

const OrderManagementBody = ({
  filter,
  setFilter,
  searchInput,
  setSearchInput,
  totalCount,
  inProgressCount,
  completedCount,
  outForDeliveryCount,
  failedCount,
  deliveredCount,
  returnsCount,
  canceledCount,
  ordersList,
}) => {

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const onExportLocal = () => {
    const formattedData = ordersList.map((order, index) => ({
      "S.No": index + 1,
      "Order ID": order.orderId,
      Customer: order.customerName || "--",
      Price: order.InfoData.fairPrice || order.InfoData.instantPrice || "--",
      Products: order.InfoData.fairProductsCount || order.InfoData.instantProductsCount || "--",
      "Order Type": order.InfoData.deliveryType.join(", ") || "--",
      Status: order.InfoData.nextFairStatus || order.InfoData.nextInstantStatus || "--",
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
  
    // Define the width of each column (measured in characters)
    worksheet['!cols'] = [
      { wch: 5 },  // Width for "S.No"
      { wch: 25 }, // Width for "Order ID"
      { wch: 35 }, // Width for "Customer"
      { wch: 10 }, // Width for "Price"
      { wch: 30 }, // Width for "Products"
      { wch: 25 }, // Width for "Order Type"
      { wch: 25 }, // Width for "Status"
    ];
  
    XLSX.writeFile(workbook, "OrdersData.csv");
  };
  

  return (
    <div className="py-5 px-2">
      <div className="flex justify-between border-b border-gray-300 py-5">
        <div className="flex items-center gap-2 w-4/5">
          <div className="flex items-center mr-2 w-1/3">
            <Input
              type="search"
              placeholder="Search"
              className="py-1 px-2 border border-gray-300 rounded w-full"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              suffix={<SearchOutlined className="text-gray-500" />}
            />
          </div>
          <div className="w-full overflow-auto h-auto scrollbar-hide">
          <div className="flex space-x-2 w-[200%] ml-3  h-auto">
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "All" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("All")}
            >
              ALL ({totalCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "inProgress" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("inProgress")}
            >
              InProgress ({inProgressCount})
            </button>

            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "delivered" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("delivered")}
            >
              Delivered ({deliveredCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "completed" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("completed")}
            >
              completed ({completedCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "outForDelivery" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("outForDelivery")}
            >
              Out For Delivery ({outForDeliveryCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "failed" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("failed")}
            >
              Failed ({failedCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "returned" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("returned")}
            >
              Returns ({returnsCount})
            </button>
            <button
              className={`px-6 py-2 rounded-full border transition-all ${
                filter === "cancelled" ? "bg-[#0ACF83] text-white border-[#0ACF83]" : "bg-white text-gray-500 border-gray-300"
              }`}
              onClick={() => handleFilterClick("cancelled")}
            >
              cancled ({canceledCount})
            </button>
            
            
          </div>
          </div>
        </div>
        <div className="flex items-center justify-end w-1/5 gap-4">
          <button
            className="bg-white text-gray-500 border border-gray-300 py-2 px-5 flex items-center gap-1 text-sm rounded"
            onClick={onExportLocal}
          >
            <MdOutlinePictureAsPdf size={15} className="text-gray-500" />
            Export to CSV
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderManagementBody;
