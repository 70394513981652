import { Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { handleProfileImage } from "../../../containers/fileupload/fileupload";

const AddEmployee = ({ close, rolesData, fetchEmployeeData, employeeData }) => {
  const [form] = Form.useForm();
  const [EmployeeImage, setEmployeeImage] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    form.resetFields();
    setEmployeeImage("");
  }, [close]);

  const onFinish = async (values) => {
    const Endpoint = `${baseURL}/addSubSeller`;
    setLoading(true);

    const userData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      role_Id: values.role,
      address: values.address,
      dob: values.DOB,
      gst: values.gst,
      pan: values.pan,
      location: {
        type: "Point",
        coordinates: [78.4867, 17.385],
      },
      image: EmployeeImage,
    };

    try {
      const response = await fetch(Endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        message.success(data.message);
        setSuccessModal(true);

        setEmployeeImage("");
        close();
        fetchEmployeeData();
        form.resetFields();
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  const validatePhoneNumber = (_, value) => {
    if (value && value.replace(/\D/g, "").length !== 10) {
      return Promise.reject("Phone number must be 10 digits");
    }
    return Promise.resolve();
  };

  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }

    if (file.size > 500 * 1024) {
      message.error("Image is greater than 500KB. The maximum limit is 500KB.");
      return;
    }

    handleProfileImage(event)
      .then((imageUrl) => {
        setEmployeeImage(imageUrl);
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Error uploading image.");
      });
  };

  return (
    <Spin spinning={loading}>
      <div className="AddEmploy-container">
        <div className="AddEmploy-content">
          <Form
            form={form}
            name="add_employee_form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="role"
              label="Select Employee Role"
              rules={[
                {
                  required: true,
                  message: "Please enter employee role",
                },
              ]}
            >
              <Select
                placeholder="Select Employee Role"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {rolesData
                  ?.filter((item) => item?.status === "active")
                  ?.map(({ _id, roleName }) => (
                    <Select.Option key={_id} value={_id}>
                      {roleName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="name"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter employee name",
                },
              ]}
            >
              <Input placeholder="Enter Employee Name" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
              ]}
            >
              <Input.Password placeholder="Enter Password" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                {
                  type: "text",
                  message: "The input is not valid!",
                },
                { validator: validatePhoneNumber },
                {
                  required: true,
                  message: "Please enter Phone number!",
                },
              ]}
            >
              <Input placeholder="Enter Phone number" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              name="gst"
              label="GST Number"
              rules={[
                {
                  required: true,
                  message: "Please enter GST number",
                },
              ]}
            >
              <Input placeholder="Enter GST Number" />
            </Form.Item>
            <Form.Item
              name="pan"
              label="PAN Number"
              rules={[
                {
                  required: true,
                  message: "Please enter PAN number",
                },
              ]}
            >
              <Input placeholder="Enter PAN Number" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please enter address",
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Enter Address" />
            </Form.Item>

            {/* Profile Image Upload */}
            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              {EmployeeImage ? (
                <div className="FileChange">
                  <div className="image-section">
                    <img src={EmployeeImage} alt="Employee" />
                  </div>
                  <label htmlFor="fileInput" className="primary-text pointer">
                    Change Image
                  </label>
                  <div className="document-attach">
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleAadharImage}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              ) : (
                <div className="FileUpload">
                  <div className="flex justify-center">
                    <img
                      src="/Assests/Images/image-gallery.png"
                      alt="Employee"
                      className="file-upload"
                    />
                  </div>
                  <p>
                    Add Profile image or{" "}
                    <label
                      htmlFor="fileInput"
                      className="text-[#009A00] font-medium cursor-pointer"
                    >
                      browse
                    </label>
                    <div className="document-attach">
                      <input
                        type="file"
                        id="fileInput"
                        onChange={handleAadharImage}
                        style={{ display: "none" }}
                      />
                    </div>
                  </p>
                  <small className="secondary-text">
                    Maximum size: 500KB
                  </small>
                </div>
              )}
            </div>

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="add-Employ-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2"
                  onClick={close}
                >
                  Cancel
                </div>
                <button
                  htmlType="submit"
                  className="add-Employ-submit-button !bg-[#009A00] !hover:bg-[#009A00] text-white py-2 rounded-md font-medium p-4"
                >
                  Submit
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className="custom-modal"
        >
          <div className="Employ-success-container">
            <div className="Employ-success-content">
              <div className="flex justify-center">
                <img src="Assests/Images/success_image.png" alt="" />
              </div>
              <h5 className="font-medium justify-center">
                Employee Added <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default AddEmployee;
