import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../util/axiosInstance";
import { useSelector } from "react-redux";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import AxiosComponent from "../../util/axiosInstance";

const DashboardCard = ({ dashboardCounts, setFilterType , filterType }) => {
  const [dashboardData, setDashboardData] = useState({
    earnings: 0,
    totalOrders: 0,
    inProgressOrders: 0,
    deliveredOrders: 0,
    totalCustomers: 0,
    returnOrders: 0,
  });
  const userProfile = useSelector((state) => state.userProfile);
  const username = userProfile.username || "User";

  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  const menu = (
    <Menu
      onClick={({ key }) => {
        setFilterType(key); 
      }}
    >
      <Menu.Item key="weekly">Weekly</Menu.Item>
      <Menu.Item key="monthly">Monthly</Menu.Item>
      <Menu.Item key="yearly">Yearly</Menu.Item>
      <Menu.Item key="">View All Time</Menu.Item>
    </Menu>
  );
  return (
    <div className="p-2 bg-white">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-xl font-semibold text-left">
            {" "}
            Welcome, {" "}{username}
          </h1>
          <p className="text-gray-500">
            Here's what's happening with your store today
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <Dropdown overlay={menu}>
            <button className="bg-white text-black border border-gray-300 px-3 py-2 rounded-md flex items-center">
            {filterType || "View All Time"} <DownOutlined className="ml-2" />
            </button>
          </Dropdown>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 mt-4">
        <div className="bg-[#FFE7EA] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)] shadow-lg">
          <h2 className="text-pink-600 text-left font-semibold text-xl tracking-widest">Earnings</h2>
          <p className="text-xl font-semibold mt-2 text-left mb-2 pb-3">
            ₹{dashboardCounts?.earnings}
          </p>
          <div className="flex justify-between items-center mt-2">
            {/* <span className="text-sm bg-white px-2 py-1 rounded-full shadow flex items-center">
              <FaArrowTrendUp className="mr-1 text-green-500" /> 14.9%   
            </span> */}
            {/* <span className="text-sm text-gray-500">(+43.21%)</span> */}
          </div>
        </div>
        <div className="bg-[#D1FFD1] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)] shadow-lg">
          <h2 className="text-green-600 text-left font-semibold text-xl tracking-widest">New Orders</h2>
          <p className="text-xl font-semibold mt-2 text-left mb-2 pb-3">
            {dashboardCounts?.totalOrders}
          </p>
          <div className="flex justify-between items-center mt-2">
            {/* <span className="text-sm bg-white px-2 py-1 rounded-full shadow flex items-center">
              <FaArrowTrendUp className="mr-1 text-green-500" /> 14.9%
            </span> */}
          </div>
        </div>
        <div className="bg-[#CDE1FF] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)] shadow-lg">
          <h2 className="text-blue-600 text-left font-semibold text-xl tracking-widest">Inprogress</h2>
          <p className="text-xl font-semibold mt-2 text-left mb-2 pb-3">
            {dashboardCounts?.inProgressOrders}
          </p>
          <div className="flex justify-between items-center mt-2">
            {/* <span className="text-sm bg-white px-2 py-1 rounded-full shadow flex items-center">
              <FaArrowTrendUp className="mr-1 text-green-500" /> 14.9%
            </span> */}
          </div>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg flex-1 basis-[calc(20%-1rem)] shadow-lg">
          <h2 className="text-yellow-600 text-left font-semibold text-xl tracking-widest">Delivered</h2>
          <p className="text-xl font-semibold mt-2 text-left mb-2 pb-3">
            {dashboardCounts?.deliveredOrders}
          </p>
          <div className="flex justify-between items-center mt-2">
            {/* <span className="text-sm bg-white px-2 py-1 rounded-full shadow flex items-center">
              <FaArrowTrendDown className="mr-1 text-red-500" /> 14.9%
            </span> */}
          </div>
        </div>
        <div className="bg-[#FFDDD3] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)] shadow-lg">
          <h2 className="text-red-600 text-left font-semibold text-xl tracking-widest">Total Customers</h2>
          <p className="text-xl font-semibold mt-2 text-left mb-2 pb-3">
            {dashboardCounts?.totalCustomers}
          </p>
          <div className="flex justify-between items-center mt-2">
            {/* <span className="text-sm bg-white px-2 py-1 rounded-full shadow flex items-center">
              <FaArrowTrendUp className="mr-1 text-green-500" /> 14.9%
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
