import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import ReviewsList from "./ReviewsList";
import AxiosComponent from "../../../util/axiosInstance";

const SellerReviewSMain = () => {
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { axiosInstance } = AxiosComponent();

  const fetchSellerReviewData = async () => {
    const reviewEndpoint = "/getSingleSellerReviews";
    try {
      setLoading(true);
      const response = await axiosInstance.get(reviewEndpoint);
      const data = response.data;
  
      if (response.status === 200 && data.responseCode === 200) {
        setReviewData([data?.data]);
      } else if (response.status === 400) {
        message.error(data.message );
      } else {
        message.error(data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.message);
      } else {
        message.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchSellerReviewData();
  }, []);

  return (
    <Spin spinning={loading}>
      <div>
        <ReviewsList reviewData={reviewData} />
      </div>
    </Spin>
  );
};

export default SellerReviewSMain;
