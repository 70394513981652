import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import { handleProfileImage } from "../../../containers/fileupload/fileupload";
const { TextArea } = Input;


const SellerAddCategory = ({
  fetchCategories,
  updateCategory,
  setUpdateCategory,
  setLoading,
  setError,
}) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState("");
  const token = localStorage.getItem("Mr_Sasta");
  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  useEffect(() => {
    if (updateCategory) {
      form.setFieldsValue({
        categoryName: updateCategory.categoryName,
        description: updateCategory.description,
      });
      setCategoryImage(updateCategory.image || "");
    }
  }, [updateCategory, form]);

  const onFinish = async (values) => {
    const payload = {
      name: values.categoryName,
      description: values.description,
      banner: "Vegies.jpg",
      image: categoryImage,
    };
    if (updateCategory) {
      payload.categoryId = updateCategory.categoryId;
    }

    try {
      const endpoint = updateCategory
        ? "/editCategory"
        : "/addCategory";
      const method = updateCategory ? "put" : "post";

      const response = await axiosInstance({
        method,
        url: endpoint,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      });

      if (response.status === 200) {
        message.success(response.data.message);
        form.resetFields();
        setCategoryImage("");
        fetchCategories();
        setUpdateCategory(null);
      } else if (response.status === 400) {
        message.error(response.data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleAadharImage = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg"];

    if (!allowedTypes.includes(file.type)) {
      message.error("Only PNG and JPG images are allowed.");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      message.error("Image is greater than 5MB. The maximum limit is 5MB.");
      return;
    }

    setLoading(true);
    handleProfileImage(event)
      .then((imageUrl) => {
        setCategoryImage(imageUrl);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Image upload failed. Please try again.");
        setLoading(false);
      });
  };

  return (
    <div className="p-2 bg-white">
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="space-y-4"
    >
      <Form.Item
        name="categoryName"
        label={updateCategory ? "Edit Category" : "Add Category"}
        rules={[
          { required: true, message: "Please enter category name" },
          {
            pattern: /^[A-Za-z0-9 ]+$/,
            message:
              "Category name can only contain letters, numbers, and spaces",
          },
          {
            max: 30,
            message: "Category name should not exceed 30 characters",
          },
          {
            validator: (_, value) => {
              if (value && value.trim().startsWith(" ")) {
                return Promise.reject(
                  new Error("Category name cannot start with a space")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
        style={{ textAlign: "left" }}
      >
        <Input
          placeholder="Name of the Category"
          className="border-gray-300 rounded-md w-full"
          maxLength={30}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/^\s+/, "");
            e.target.value = e.target.value.replace(/[0-9]/g, "");
          }}
        />
      </Form.Item>

      <Form.Item name="description" label="Description" className="!mb-2">
        <TextArea
          rows={4}
          placeholder="Add description for category"
          maxLength={350}
          showCount
          className="border-gray-300 rounded-md w-full"
        />
      </Form.Item>

      <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4 !mt-[29px]">
        <div className="flex justify-center items-center">
          {categoryImage ? (
            <img
              src={categoryImage}
              alt="Category Preview"
              className="w-20 h-18 mb-4"
            />
          ) : (
            <img
              src="/Assests/Images/image-gallery.png"
              alt="Default Category"
              className="w-20 h-18"
            />
          )}
        </div>

        <div className="flex flex-col items-center">
          <p>
            {" "}
            <label
              htmlFor="fileInput"
              className="text-[#009A00] cursor-pointer font-bold"
            >
              {categoryImage ? "Change Image" : "Upload your files here"}
            </label>
          </p>
          {/* <p className="text-sm text-gray-500">Maximum size: 500KB</p> */}
          <input
            type="file"
            id="fileInput"
            onChange={handleAadharImage}
            className="!hidden"
          />
        </div>
      </div>
      <Button
        type="primary"
        htmlType="submit"
        className="w-full !bg-[#009A00] !hover:bg-[#009A00] text-white py-2 rounded-md font-medium"
      >
        {updateCategory ? "Update Category" : "Add Category"}
      </Button>
    </Form>
  </div>
);
};

export default SellerAddCategory;
