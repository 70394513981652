import { Form, Input, Modal, Select, Spin, DatePicker, message } from "antd";
import React, { useState, useEffect } from "react";

const EditEmployee = ({
  employee,
  close,
  rolesData,
  employeeData,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState(employee.image || "");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleRoleChange = (value) => {
    console.log(value);
  };

  useEffect(() => {
    if (employee) {
      form.setFieldsValue({
        role: employee.role_Id,
        username: employee.username,
        email: employee.email,
        address: employee.address,
      });
      setCategoryImage(employee.image);
    }
  }, [employee, form]);
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the file size exceeds 500KB (500 * 1024 bytes)
      if (file.size > 500 * 1024) {
        message.error(
          "Image is greater than 500KB. The maximum limit is 500KB."
        );
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setCategoryImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onFinish = async (values) => {
    const payload = {
      employeId: employee._id,
      username: values.username,
      email: values.email,
      role_Id: values.role,
      address: values.address,
      image: categoryImage,
    };

    try {
      setLoading(true);

      const response = await fetch(`${baseURL}/editEmployee`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        console.log(responseData);
        message.success(responseData.message);
        setSuccessModal(true);
        fetchData();
        close();
        form.resetFields();
      } else if (response.status === 400) {
        const errorData = await response.json();
        message.error(errorData.message);
      } else {
        message.error();
      }
    } catch (error) {
      message.error();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="AddEmploy-container">
        <div className="AddEmploy-content">
          <Form
            form={form}
            name="edit_employee"
            className="edit-profile-form"
            layout="vertical"
            initialValues={{
              username: employee.username,
              email: employee.email,
              role: employee.role_Id,
              address: employee.address,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter employee name",
                },
                {
                  max: 30,
                  message: "employee name cannot exceed 30 characters.",
                },
                {
                  pattern: /^[A-Za-z0-9 ]+$/,
                  message:
                    "Employee name can only contain letters, numbers, and spaces",
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim().startsWith(" ")) {
                      return Promise.reject(
                        new Error("employee name cannot start with a space")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Employee Name"
                maxLength={30}
                onInput={(e) => {
                  let value = e.target.value;

                  // Prevent leading spaces
                  value = value.replace(/^\s+/, "");

                  // Limit length to 30 characters
                  value = value.slice(0, 30);

                  // Remove special characters
                  value = value.replace(/[^A-Za-z0-9 ]/g, "");

                  e.target.value = value;
                }}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please enter email" }]}
            >
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>

            <Form.Item
              name="role"
              label="Select Employee Role"
              rules={[
                { required: true, message: "Please select employee role" },
              ]}
            >
              <Select
                placeholder="Select Employee Role"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleRoleChange}
              >
                {rolesData
                  // ?.filter((item) => item?.status === "active")
                  ?.map(({ _id, roleName }) => (
                    <Select.Option key={_id} value={_id}>
                      {roleName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            {/* <Form.Item
              name="address"
              label="Address"
              rules={[
                { required: true, message: "Please enter employee address" },
              ]}
            >
              <Input type="text" placeholder="Enter Address" />
            </Form.Item> */}
            {/* 
            <Form.Item
              name="DOB"
              label="Date of Birth"
              rules={[{ required: true, message: "Please select date of birth" }]}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item> */}

            <div className="border-2 border-dashed border-gray-300 p-4 rounded-md text-center mb-4">
              <div className="FileChange">
                <div className="image-section">
                  {categoryImage && <img src={categoryImage} alt="employee" />}
                </div>
                <label
                  htmlFor="fileInput"
                  className="primary-text cursor-pointer"
                >
                  Change Image
                </label>
                <div className="document-attach">
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>

            <Form.Item>
              <div className="flex justify-between">
                <div
                  className="add-Employ-cancel-button text-[#009A00] border border-[#009A00] rounded-md p-4 py-2"
                  onClick={close}
                >
                  <p>Cancel</p>
                </div>
                <button
                  htmlType="submit"
                  className="add-Employ-submit-button !bg-[#009A00] !hover:bg-[#009A00] text-white py-2 rounded-md font-medium p-4"
                >
                  <p>Submit</p>
                </button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          visible={successModal}
          onCancel={() => setSuccessModal(false)}
          centered
          footer={null}
          className="custom-modal"
        >
          <div className="Employ-success-container">
            <div className="Employ-success-content">
              <div>
                <img src="Assests/Images/success_image.png" alt="" />
              </div>
              <h5>
                Employee Details Updated <br /> Successfully
              </h5>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default EditEmployee;
