import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import EmployeeCard from "./EmployeeCard";
import EmployeeList from "./EmployeeList";
import AxiosComponent from "../../../util/axiosInstance";

const SellerEmployeeMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [rolesData, setRolesData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [reportsDate, setReportsDate] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const { axiosInstance } = AxiosComponent();

  useEffect(() => {
    fetchAdminRolesData();
    fetchEmployeeData();
  }, []);

  const fetchAdminRolesData = async () => {
    const rolesEndpoint = "/listOfSellerRoles";
    try {
      setLoading(true);
      const response = await axiosInstance.get(rolesEndpoint);
      const data = response.data;

      if (response.status === 200 && data.responseCode === 200) {
        setRolesData(data.data);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeData = async (dateStrings = null) => {
    setLoading(true);
    const employeesEndPoint = "/getListOfSubSellers";
    try {
      const response = await axiosInstance.post(employeesEndPoint, {
        startDate: dateStrings ? dateStrings[0] : "",
        endDate: dateStrings ? dateStrings[1] : "",
      });
      
      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.responseCode === 200) {
          const { subSellers } = responseData;
          setEmployeeData(subSellers);

          const activeEmployees = subSellers.filter(employee => employee.status === "active");
          const inactiveEmployees = subSellers.filter(employee => employee.status === "inactive");
          
          setTotalCount(subSellers.length);
          setActiveCount(activeEmployees.length);
          setInactiveCount(inactiveEmployees.length);
        } else {
          message.error(responseData.message);
          setError(responseData.message);
        }
      } else {
        const errorData = await response.json();
        message.error("Error fetching employee data");
        setError(errorData.message);
      }
    } catch (error) {
      message.error(error.message || "Error fetching employee data");
      setError(error.message || "Error fetching employee data");
    } finally {
      setLoading(false);
    }
  };

  const filterEmployeesBySearchInput = (employees, searchInput) => {
    if (!searchInput) return employees;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return employees.filter((employee) => {
      const lowerCaseAddress = String(employee.address || "").toLowerCase();
      return (
        (employee.username || "").toLowerCase().includes(lowerCaseSearchInput) ||
        (employee.email || "").toLowerCase().includes(lowerCaseSearchInput) ||
        String(employee.phoneNumber || "").includes(searchInput) ||
        lowerCaseAddress.includes(lowerCaseSearchInput) ||
        (employee.roleName || "").toLowerCase().includes(lowerCaseSearchInput)
      );
    });
  };

  const filteredEmployee = filterEmployeesBySearchInput(
    employeeData.filter((employee) => {
      if (filter === "All") return true;
      if (filter === "Active") return employee.status === "active";
      if (filter === "Inactive") return employee.status === "inactive";
      return false;
    }),
    searchInput
  );

  return (
    <Spin spinning={loading}>
      <div>
        <EmployeeCard
          employeeData={employeeData}
          fetchEmployeeData={fetchEmployeeData}
          rolesData={rolesData}
          filter={filter}
          setFilter={setFilter}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          totalCount={totalCount}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setReportsDate={setReportsDate}
          reportsDate={reportsDate}
        />
        <EmployeeList
          employeeData={filteredEmployee}
          loading={loading}
          error={error}
          fetchEmployeeData={fetchEmployeeData}
          rolesData={rolesData}
        />
      </div>
    </Spin>
  );
};

export default SellerEmployeeMain;
