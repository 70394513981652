import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import AxiosComponent from "../../util/axiosInstance";
import OrderManagementCards from "./orderManagementcard";
import OrderManagementBody from "./orderManagementBody";
import OrderMListBody from "./ordermanagementlist";

const OrderManagementMain = () => {
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [ordersList, setOrdersList] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filterDate, setFilterDate] = useState([]);
  const { axiosInstance } = AxiosComponent();
  const [counts, setCounts] = useState({
    totalCount: 0,
    inProgressCount: 0,
    completedCount: 0,
    outForDeliveryCount: 0,
    failedCount: 0,
    deliveredCount: 0,
    returnsCount: 0,
    canceledCount: 0,
  });

  const token = localStorage.getItem("Mr_Sasta");

  const fetchOrders = async () => {
    setLoading(true);
    setError("");
    try {
      setLoading(true);
      

      const response = await axiosInstance.post("/listOfOrderManagement");
      const { data } = response;
      if (response.status === 200 && data.responseCode === 200) {
        setOrdersList(data.data);
      } else if (response.status === 400) {
        setError(data.message);
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const filterAndSearchOrders = () => {
      const lowerCaseSearchInput = searchInput.toLowerCase();
      const filtered = ordersList.filter((order) => {
        const matchesFilter =
          filter === "All" || filter === order.overallOrderStatus;

        const matchesSearch =
          order.customerName?.toLowerCase()?.includes(lowerCaseSearchInput) ||
          order.orderId?.toLowerCase()?.includes(lowerCaseSearchInput);

        return matchesFilter && matchesSearch;
      });
      setFilteredOrders(filtered);
      setCounts({
        totalCount: ordersList.length,
        inProgressCount: ordersList.filter(
          (order) => order.overallOrderStatus === "Inprogress"
        ).length,
        completedCount: ordersList.filter(
          (order) => order.overallOrderStatus === "completed"
        ).length,
        outForDeliveryCount: ordersList.filter(
          (order) => order.overallOrderStatus === "outForDelivery"
        ).length,
        failedCount: ordersList.filter(
          (order) => order.overallOrderStatus === "failed"
        ).length,
        deliveredCount: ordersList.filter(
          (order) => order.overallOrderStatus === "delivered"
        ).length,
        returnsCount: ordersList.filter(
          (order) => order.overallOrderStatus === "returned"
        ).length,
        canceledCount: ordersList.filter(
          (order) => order.overallOrderStatus === "cancelled"
        ).length,
      });
    };

    filterAndSearchOrders();
  }, [filter, searchInput, ordersList]);

  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <OrderManagementCards
            ordersList={filteredOrders}
            fetchOrders={fetchOrders}
          />
        </div>
        <div>
          <OrderManagementBody
            setSearchInput={setSearchInput}
            filter={filter}
            ordersList={filteredOrders}
            setFilter={setFilter}
            totalCount={counts.totalCount}
            inProgressCount={counts.inProgressCount}
            completedCount={counts.completedCount}
            outForDeliveryCount={counts.outForDeliveryCount}
            failedCount={counts.failedCount}
            deliveredCount={counts.deliveredCount}
            returnsCount={counts.returnsCount}
            canceledCount={counts.canceledCount}
          />
        </div>
        <div>
          <OrderMListBody
            ordersList={filteredOrders}
            fetchOrders={fetchOrders}
          />
        </div>
      </div>
    </Spin>
  );
};

export default OrderManagementMain;
