import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux"; 
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import AxiosComponent from "../../../util/axiosInstance";

const SellerDashboardCard = () => {
  const [dashboardData, setDashboardData] = useState({
    earnings: 0,
    totalOrders: 0,
    inProgressOrders: 0,
    deliveredOrders: 0,
    totalCustomers: 0,
    returnOrders: 0,
  });
  const userProfile = useSelector((state) => state.userProfile);
  const username = userProfile.name || "User"; 

  const DashboardDataEndpoint = "/dashboardApiSeller";

  const { axiosInstance, noauthAxiosInstance } = AxiosComponent();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axiosInstance.post(
          DashboardDataEndpoint,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("Mr_Sasta")}`,
            },
          }
        );

        if (response.status === 200 && response.data.response) {
          setDashboardData(response.data.response);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchDashboardData();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key='1'>Weekly</Menu.Item>
      <Menu.Item key='2'>Monthly</Menu.Item>
      <Menu.Item key='3'>Yearly</Menu.Item>
    </Menu>
  );

  return (
    <div className='p-2 bg-white'>
      <div className='flex justify-between items-center'>
        <div>
          <h1 className="text-xl font-semibold text-left"> Welcome Back, {username}</h1>
          <p className="text-gray-500">Here's what's happening with your store today</p>
        </div>
        <div className='flex items-center space-x-4'>
          <Dropdown overlay={menu}>
            <button className='bg-white text-black border border-gray-300 px-3 py-2 rounded-md flex items-center'>
              Weekly <DownOutlined className='ml-2' />
            </button>
          </Dropdown>
          <button className='bg-[#009A00] text-white font-medium px-4 py-2 rounded-md'>
            VIEW ALL TIME
          </button>
        </div>
      </div>
      <div className='flex flex-wrap gap-4 mt-4'>
        <div className='bg-[#FFE7EA] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)]'>
          <h2 className='text-gray-600 text-left'>Earnings</h2>
          <p className='text-xl font-semibold mt-2 text-left mb-2 pb-3'>
            ₹{dashboardData.earnings}
          </p>
          <div className='flex justify-between items-center mt-2'>
            <span className='text-sm bg-white px-2 py-1 rounded-full shadow flex items-center'>
              <FaArrowTrendUp className='mr-1 text-green-500' /> 14.9%
            </span>
            <span className='text-sm text-gray-500'>(+43.21%)</span>
          </div>
        </div>
        <div className='bg-[#D1FFD1] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)]'>
          <h2 className='text-gray-600 text-left'>New Orders</h2>
          <p className='text-xl font-semibold mt-2 text-left mb-2 pb-3'>
            {dashboardData.totalOrders}
          </p>
          <div className='flex justify-between items-center mt-2'>
            <span className='text-sm bg-white px-2 py-1 rounded-full shadow flex items-center'>
              <FaArrowTrendUp className='mr-1 text-green-500' /> 14.9%
            </span>
          </div>
        </div>
        <div className='bg-[#CDE1FF] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)]'>
          <h2 className='text-gray-600 text-left'>Inprogress</h2>
          <p className='text-xl font-semibold mt-2 text-left mb-2 pb-3'>
            {dashboardData.inProgressOrders}
          </p>
          <div className='flex justify-between items-center mt-2'>
            <span className='text-sm bg-white px-2 py-1 rounded-full shadow flex items-center'>
              <FaArrowTrendUp className='mr-1 text-green-500' /> 14.9%
            </span>
          </div>
        </div>
        <div className='bg-yellow-100 p-4 rounded-lg flex-1 basis-[calc(20%-1rem)]'>
          <h2 className='text-gray-600 text-left'>Delivered</h2>
          <p className='text-xl font-semibold mt-2 text-left mb-2 pb-3'>
            {dashboardData.deliveredOrders}
          </p>
          <div className='flex justify-between items-center mt-2'>
            <span className='text-sm bg-white px-2 py-1 rounded-full shadow flex items-center'>
              <FaArrowTrendDown className='mr-1 text-red-500' /> 14.9%
            </span>
          </div>
        </div>
        <div className='bg-[#FFDDD3] p-4 rounded-lg flex-1 basis-[calc(20%-1rem)]'>
          <h2 className='text-gray-600 text-left'>Total Customers</h2>
          <p className='text-xl font-semibold mt-2 text-left mb-2 pb-3'>
            {dashboardData.totalCustomers}
          </p>
          <div className='flex justify-between items-center mt-2'>
            <span className='text-sm bg-white px-2 py-1 rounded-full shadow flex items-center'>
              <FaArrowTrendUp className='mr-1 text-green-500' /> 14.9%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerDashboardCard;
