// RidersMain.jsx
import React, { useState, useEffect,} from "react";
import { Spin, message } from "antd";
import AxiosComponent from "../../../util/axiosInstance";
import useLogout from "../../../util/authUtils";
import RidersCard from "./RidersCard";
import RidersList from "./RidersList";

const SellerRidersMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [ridersData, setRidersData] = useState([]);
  const { axiosInstance } = AxiosComponent();
  const logout = useLogout();

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("Mr_Sasta");

  // Derived counts using useMemo
  const getCounts = (data) => {
    const total = data?.length;
    const active = data?.filter((rider) => rider.status.toLowerCase() === "active").length;
    const inactive = data?.filter((rider) => rider.status.toLowerCase() === "inactive").length;
    return { total, active, inactive };
  };

  const counts = getCounts(ridersData);

  const fetchRiders = async () => {
    setLoading(true);
    setError("");
  
    try {
      const response = await axiosInstance.get(`${baseURL}/getListOfRidersInfo`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (response.status === 200) {
        setRidersData(response.data.riders);
        // message.success(response.data.message);
      } else if (response.status === 400) {
        const errorMessage = response.data?.message;
        setError(errorMessage);
        message.error(errorMessage);
      } else if (response.status === 401) {
        const errorMessage = response.data?.message;
        message.error(errorMessage);
        logout();
      } 
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  const toggleRiderStatus = async (rider) => {
    const newStatus = rider.status.toLowerCase() === "active" ? "inactive" : "active";
    try {
      const response = await axiosInstance.put(
        `${baseURL}/toggleRider`,
        { riderId: rider._id, status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.status === 200) {
        const updatedRiders = ridersData.map((r) =>
          r._id === rider._id ? { ...r, status: newStatus } : r
        );
        setRidersData(updatedRiders);
        message.success(response.data?.message);
      } else if (response.responseCode === 400) {
        message.error(response.data?.message);
      } else if (response.responseCode === 401) {
        message.error(response.data?.message);
        logout(); 
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRiders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterRidersBySearchInput = (riders, searchInput) => {
    if (!searchInput) return riders;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return riders.filter((rider) => {
      const lowerCaseAddress = String(rider.address).toLowerCase();
      return (
        rider.riderName.toLowerCase().includes(lowerCaseSearchInput) ||
        rider.email.toLowerCase().includes(lowerCaseSearchInput) ||
        String(rider.phone).includes(searchInput) ||
        lowerCaseAddress.includes(lowerCaseSearchInput)
      );
    });
  };

  const filteredRiders = filterRidersBySearchInput(
    ridersData?.filter((rider) => {
      const currentFilter = filter.toLowerCase();
      if (currentFilter === "all") return true;
      if (currentFilter === "active")
        return rider.status.toLowerCase() === "active";
      if (currentFilter === "inactive")
        return rider.status.toLowerCase() === "inactive";
      return false;
    }),
    searchInput
  );

  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <RidersCard
            riders={ridersData}
            filter={filter}
            setFilter={setFilter}
            activeCount={counts.active}
            inactiveCount={counts.inactive}
            totalCount={counts.total}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            fetchRiders={fetchRiders}
          />
        </div>
        <div>
          <RidersList
            riders={filteredRiders}
            toggleRiderStatus={toggleRiderStatus}
            fetchRiders={fetchRiders}
          />
        </div>
      </div>
    </Spin>
  );
};

export default SellerRidersMain;
