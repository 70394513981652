import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import EmployeeCard from "./EmployeeCard";
import EmployeeList from "./EmployeeList";
import AxiosComponent from "../../util/axiosInstance";

const EmployeeMain = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("All");
  const [rolesData, setRolesData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [reportsDate, setReportsDate] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const { axiosInstance } = AxiosComponent();

  useEffect(() => {
    fetchAdminRolesData();
    fetchEmployeeData();
  }, []);

  //   try {
  //     const token = localStorage.getItem("Mr_Sasta");
  //     if (!token) {
  //       message.error("Authentication token is missing");
  //       setLoading(false);
  //       return;
  //     }

  //     const response = await fetch(`${baseURL}/listOfEmployes`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         startDate: dateStrings ? dateStrings[0] : null,
  //         endDate: dateStrings ? dateStrings[1] : null,
  //       }),
  //     });

  //     const responseData = await response.json();

  //     if (response.status === 200) {
  //       // Success case
  //       const { data } = responseData;
  //       setEmployeeData(data);

  //       const activeEmployee = data.filter(
  //         (employee) => employee.status === "active"
  //       );
  //       const inactiveEmployee = data.filter(
  //         (employee) => employee.status === "inactive"
  //       );

  //       setTotalCount(data.length);
  //       setActiveCount(activeEmployee.length);
  //       setInactiveCount(inactiveEmployee.length);
  //     } else if (response.status === 400) {
  //       message.error(responseData.message);
  //     } else {
  //       message.error(responseData.message);
  //     }
  //   } catch (error) {
  //     message.error("Something went wrong.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchAdminRolesData = async () => {
    const rolesEndpoint = "/listOfRoles";
    try {
      setLoading(true);
      const response = await axiosInstance.get(rolesEndpoint);
      const data = response.data;
      console.log(response);
  
      if (response.status === 200 && data.responseCode === 200) {
        setRolesData(data?.data);
      } else if (response.status === 400) {
        message.error(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchEmployeeData = async (dateStrings = []) => {
    setLoading(true);
    const employeesEndPoint = "/listOfEmployes";
    try {
      
      const requestBody = {
        startDate: dateStrings[0] || null, 
        endDate: dateStrings[1] || null,  
      };
  
      const response = await axiosInstance.post(employeesEndPoint, requestBody);
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData?.responseCode === 200) {
          const { data } = responseData;
  
          // Update employee data and counts
          setEmployeeData(data);
          const activeEmployee = data.filter(
            (employee) => employee.status === "active"
          );
          const inactiveEmployee = data.filter(
            (employee) => employee.status === "inactive"
          );
          setTotalCount(data?.length);
          setActiveCount(activeEmployee?.length);
          setInactiveCount(inactiveEmployee?.length);
        } else if (responseData?.responseCode === 400) {
          message.error(responseData?.message);
        }
      } else {
        const errorData = await response.json();
        throw { error: errorData, loading: false };
      }
    } catch (error) {
      message.error(error?.message || "Failed to fetch employee data.");
    } finally {
      setLoading(false);
    }
  };
  
  const filterEmployeesBySearchInput = (employees, searchInput) => {
    if (!searchInput) return employees;
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return employees.filter((employee) => {
      const lowerCaseAddress = String(employee.address).toLowerCase();
      return (
        employee.username.toLowerCase().includes(lowerCaseSearchInput) ||
        employee.email.toLowerCase().includes(lowerCaseSearchInput) ||
        String(employee.phoneNumber).includes(searchInput) ||
        lowerCaseAddress.includes(lowerCaseSearchInput) ||
        employee.roleName.toLowerCase().includes(lowerCaseSearchInput)
      );
    });
  };

  const filteredEmployee = filterEmployeesBySearchInput(
    employeeData.filter((Employee) => {
      if (filter === "All") return true;
      if (filter === "Active") return Employee.status === "active";
      if (filter === "Inactive") return Employee.status === "inactive";
      return false;
    }),
    searchInput
  );

  return (
    <Spin spinning={loading}>
      <div>
        <EmployeeCard
          employeeData={employeeData}
          fetchEmployeeData={fetchEmployeeData}
          rolesData={rolesData}
          filter={filter}
          setFilter={setFilter}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          totalCount={totalCount}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setReportsDate={setReportsDate}
          reportsDate={reportsDate}
        />
        <EmployeeList
          employeeData={filteredEmployee}
          loading={loading}
          error={error}
          fetchEmployeeData={fetchEmployeeData}
          rolesData={rolesData}
          filter={filter}
          setFilter={setFilter}
          activeCount={activeCount}
          inactiveCount={inactiveCount}
          totalCount={totalCount}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setReportsDate={setReportsDate}
          reportsDate={reportsDate}
        />
      </div>
    </Spin>
  );
};

export default EmployeeMain;
