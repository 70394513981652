import React, { useState } from "react";
import { Avatar, Pagination, Button } from "antd";
import { useNavigate } from "react-router-dom";

const PaymentList = ({ paymentData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const navigate = useNavigate();

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = paymentData.slice(startIndex, endIndex);

  const handleViewClick = (sellerId, sellerName) => {
    // Pass sellerId and sellerName in the state while navigating
    navigate(`/payment-history/${sellerId}`, {
      state: { sellerId, sellerName }
    });
  };


  const extractDate = (dateTime) => {
    // Split the date and time, returning only the date (first part)
    return dateTime.split(" ")[0];
  };

  return (
    <div className="p-2 bg-white">
      <table className="min-w-full">
        <thead className="bg-white">
          <tr>
            <th className="border-b p-4 text-center">S.No</th>
            <th className="border-b p-4 text-left min-w-[150px] max-w-[200px]">
              Seller Name
            </th>
            <th className="border-b p-4 text-center">Seller ID</th>
            <th className="border-b p-4 text-center">Number of Bookings</th>
            {/* <th className="border-b p-4 text-center">Date</th> */}
            <th className="border-b p-4 text-center">Total Amount</th>
            <th className="border-b p-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((payment, index) => (
              <tr key={payment.sellerId}>
                <td className="border-b p-4 text-center">
                  {startIndex + index + 1}
                </td>
                <td className="border-b p-4 flex items-center gap-3">
                  <Avatar
                    size={35}
                    shape="circle"
                    className="mr-2 bg-gray-200 text-black capitalize"
                  >
                    {payment.sellerName ? payment.sellerName[0] : ""}
                  </Avatar>
                  {payment.sellerName || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {payment.sellerId || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  {payment.numberOfBookings || "--"}
                </td>
                {/* <td className="border-b p-4 text-center">
                
                  {payment.bookingDetails.length > 0
                    ? extractDate(payment.bookingDetails[0].date)
                    : "--"}
                </td> */}
                <td className="border-b p-4 text-center">
                  {payment.totalAmount || "--"}
                </td>
                <td className="border-b p-4 text-center">
                  <Button
                    className="p-4"
                    onClick={() => handleViewClick(payment.sellerId)}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="border-b p-4 text-center">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="Pagination-container mt-3 justify-end flex">
        {paymentData.length > 0 && (
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={paymentData.length}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentList;
