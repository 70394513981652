import React, { useState } from "react";
import { Input, Button, Dropdown, Menu, Checkbox, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { BsFilter } from "react-icons/bs";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;

const StockManagementCards = ({
  setSearchInput,
  setCheckedFilter,
  setFilterDate,
  stockProductsDetails,
  fetchStockProductDetails,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    setSearchInput(e.target.value);
  };

  const handleFilterChange = (e) => {
    setCheckedFilter(e.target.checked ? e.target.name : "");
    fetchStockProductDetails();
    setFilterVisible(false);
  };

  const onDateChange = (dates, dateStrings) => {
    setFilterDate(dateStrings);
    fetchStockProductDetails();
  };

  const onExportLocal = () => {
    const formattedData = stockProductsDetails.map((item, index) => ({
      "S.No": index + 1,
      "Product Name": item.productName,
      "Stock Status": item.stockStatus ? "In Stock" : "Out of Stock",
      Quantity: item.totalQuantityOfSKU,
      "Last Updated": item.updatedDate || "--",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Details");
    XLSX.writeFile(workbook, "stockProductsDetails.xlsx");
  };

  const filterMenu = (
    <Menu>
      <Menu.Item key="1">
        <Checkbox name="InStock" onChange={handleFilterChange}>
          In Stock
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="2">
        <Checkbox name="OutOfStock" onChange={handleFilterChange}>
          Out of Stock
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="stock-management-cards p-4 bg-white border-b border-t">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Input
            placeholder="Search product"
            className="w-64"
            value={searchTerm}
            onChange={onSearch}
            suffix={<SearchOutlined />}
          />
          {/* <Dropdown overlay={filterMenu} trigger={["click"]}>
            <Button className="filter-button flex items-center space-x-2">
              <BsFilter size={17} />
            </Button>
          </Dropdown> */}
          <RangePicker style={{ width: "220px" }} onChange={onDateChange} />
        </div>
        <Button onClick={onExportLocal}>
          <MdOutlinePictureAsPdf size={15} />
          Export to CSV
        </Button>
      </div>
    </div>
  );
};

export default StockManagementCards;
