// SellerMain.jsx
import React, { useState, useEffect, useMemo } from "react";
import { Spin, message } from "antd";
import AxiosComponent from "../../util/axiosInstance";
import useLogout from "../../util/authUtils";
import SellerCard from "./SellerCard";
import SellerList from "./SellerList";

const SellerMain = () => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [sellerData, setSellerData] = useState([]);
  const { axiosInstance } = AxiosComponent();
  const logout = useLogout();

  const fetchSellerData = async () => {
    const SellerEndpoint = "/SellerBoard";
    try {
      setLoading(true);
      const response = await axiosInstance.get(SellerEndpoint);
      const data = response.data;
      if (response.status === 200) {
        if (data.responseCode === 200) {
          setSellerData(data?.data);
        } else {
          message.error(data?.message );
        }
      } else if (response.responseCode === 400) {
        message.error(data?.message );
      } else if (response.responseCode === 401) {
        message.error(data?.message );
        logout();
      } else {
        message.error(data?.message );
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  

const toggleSellerStatus = async (seller) => {
  const ToggleStatusEndpoint = "/toggleSelerStatus";
  try {
    setLoading(true);
    const sellerId = seller._id || seller.sellerId;

    console.log("Toggling status for sellerId:", sellerId);

    const response = await axiosInstance.post(ToggleStatusEndpoint, {
      sellerId: sellerId,
    });

    const data = response.data;

    if (response.status === 200 && data.responseCode === 200) {
      message.success(data.message);
      fetchSellerData();
    } else if (response.status === 400) {
      message.error(data.message);
    } else if (response.status === 401) {
      message.error(response.data?.message );
      logout();
    } else {
      message.error(data.message);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        message.error(error.response.data.message );
      } else if (error.response.status === 401) {
        message.error(response.data?.message );
        logout(); 
      } else {
        message.error(error.response.data?.message );
      }
    } else {
      message.error(error.message);
    }
  } finally {
    setLoading(false);
  }
};

  const counts = {
    total: sellerData.length,
    active: sellerData.filter(
      (seller) => seller.status.toLowerCase() === "active"
    ).length,
    inactive: sellerData.filter(
      (seller) => seller.status.toLowerCase() === "inactive"
    ).length,
  };

  useEffect(() => {
    fetchSellerData();
  }, []);

  const filteredSellers = () => {
    let filtered = [...sellerData];

    if (filter !== "All") {
      const statusFilter = filter === "Active" ? "active" : "inactive";
      filtered = filtered.filter(
        (seller) => seller.status.toLowerCase() === statusFilter
      );
    }

    if (searchInput.trim() !== "") {
      const lowerSearch = searchInput.toLowerCase();
      filtered = filtered.filter((seller) => {
        return (
          (seller.sellerName &&
            seller.sellerName.toLowerCase().includes(lowerSearch)) ||
          (seller.email && seller.email.toLowerCase().includes(lowerSearch)) ||
          (seller.phone && seller.phone.includes(searchInput)) ||
          (seller.address && seller.address.toLowerCase().includes(lowerSearch))
        );
      });
    }

    return filtered;
  };

  return (
    <Spin spinning={loading}>
      <div>
        <SellerCard
          filter={filter}
          setFilter={setFilter}
          activeCount={counts.active}
          inactiveCount={counts.inactive}
          totalCount={counts.total}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          fetchSellerData={fetchSellerData}
        />
        <SellerList
          sellerData={filteredSellers()}
          toggleSellerStatus={toggleSellerStatus}
          fetchSellerData={fetchSellerData}
          filter={filter}
          setFilter={setFilter}
          activeCount={counts.active}
          inactiveCount={counts.inactive}
          totalCount={counts.total}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      </div>
    </Spin>
  );
};

export default SellerMain;