import axios from "axios";

export const handleProfileImage = async (event) => {
  const file = event.target.files[0];
  if (!file) return;
  try {
    const token = localStorage.getItem("Mr_Sasta");
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/uploadImage`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response);
    const imageUrl = response?.data?.imagePath1;
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    return error;
  }
};
