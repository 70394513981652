import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import AxiosComponent from "../../util/axiosInstance";
import StockManagementChart from "./StockmanagementChart";
import Stockmanagementlist from "./Stockmanagementlist";
import StockManagementCards from "./StockManagementCards";

const StockManagementMain = () => {
  const token = localStorage.getItem("Mr_Sasta");
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const apiUrl = `${baseURL}/GetAdminProductDetails`;
  const stockStatusApi = `${baseURL}/getAdminStockStatus`;
  const topSellingProductApi = `${baseURL}/getAdminTopSellingProductsPiChart`;
  const getStockProductDetailsApi = `${baseURL}/getStockAdminProductDetails`;
  const { axiosInstance } = AxiosComponent();

  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const [stockStatusData, setStockStatusData] = useState(null);
  const [topSellingData, setTopSellingData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalSKUs, setTotalSKUs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [checkedFilter, setCheckedFilter] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const [stockProductsDetails, setStockProductsDetails] = useState([]);
  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.responseCode === 200) {
          setProductData(response.data.data);
        } else if (response.data.responseCode === 400) {
          message.error(data.message);
        } else {
          message.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        message.error(error.message);
      }
    };

    const fetchStockStatus = async () => {
      try {
        const response = await axiosInstance.get(stockStatusApi, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.responseCode === 200) {
          setStockStatusData(response.data.data.stockStatus);
        } else if (response.data.responseCode === 400) {
          message.error(data.message);
        } else {
          message.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching stock status:", error);
        message.error(error.message);
      }
    };

    const fetchTopSellingProducts = async () => {
      try {
        const response = await axiosInstance.get(topSellingProductApi, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.responseCode === 200) {
          const formattedData = response.data.data.map((product) => ({
            categoryName: product.categoryName,
            value: parseFloat(product.percentage.replace("%", "")),
          }));
          setTopSellingData(formattedData);
        } else if (response.data.responseCode === 400) {
          message.error(data.message);
        } else {
          message.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching top-selling products:", error);
        message.error(error.message);
      }
    };

    fetchData();
    fetchStockStatus();
    fetchTopSellingProducts();
  }, []);

  const fetchStockProductDetails = async () => {
    setLoading(true);
    try {
      const [startDate, endDate] = filterDate;
      const response = await axiosInstance.post(
        getStockProductDetailsApi,
        {
          startDate: startDate || "",
          endDate: endDate || "",
          search: searchInput,
          stockStatus: checkedFilter,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.responseCode === 200) {
        const allSKUs = response.data.data.productDetails.flatMap((product) =>
          product.totalSKUs.map((sku) => ({
            ...sku,
            productName: product.productName,
            lastUpdatedDate: product.lastUpdatedDate,
            stockStatus: product.stockStatus,
          }))
        );
  
        const filteredSKUs = searchInput.trim()
          ? allSKUs.filter((sku) =>
              sku.productName.toLowerCase().includes(searchInput.toLowerCase())
            )
          : allSKUs;
  
        setStockProductsDetails(filteredSKUs);
        setTotalSKUs(filteredSKUs.length);
  
        const startIndex = (currentPage - 1) * pageSize;
        setPaginatedData(filteredSKUs.slice(startIndex, startIndex + pageSize));
      } else if (response.data.responseCode === 400) {
        message.error(data.message);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSearchInputChange = (input) => {
    setSearchInput(input);
    setCurrentPage(1);  
    fetchStockProductDetails();
  };
  useEffect(() => {
    fetchStockProductDetails();
  }, [currentPage, searchInput, checkedFilter, filterDate]);

  return (
    <Spin spinning={loading}>
      <div>
        <StockManagementChart
          productData={productData}
          stockStatusData={stockStatusData}
          topSellingData={topSellingData}
        />
        <StockManagementCards
          setSearchInput={handleSearchInputChange}
          setCheckedFilter={setCheckedFilter}
          setFilterDate={setFilterDate}
          fetchStockProductDetails={fetchStockProductDetails}
          stockProductsDetails={stockProductsDetails}
        />
        <Stockmanagementlist
          paginatedData={paginatedData}
          fetchStockProductDetails={fetchStockProductDetails}
          totalSKUs={totalSKUs}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </Spin>
  );
};

export default StockManagementMain;
